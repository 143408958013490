<template>
  <v-container>
    <!--lists loader -->
    <div v-if="loading" class="text-center">
      <v-progress-circular
        indeterminate
        color="primary"
        style="
          height: 50%;
          margin: auto;
          padding-top: 50%;
        "></v-progress-circular>
    </div>
    <v-row v-if="!loading">
      <v-col cols="12" sm="6" md="6">
        <v-row class="mx-3">
          <h1 class="dashtitle">
            <span>Company Information</span>
          </h1>
        </v-row>
        <v-row no-gutters class="mx-3">
          <v-col cols="12">
            <v-col cols="12">
              <v-subheader name="Company Logo" class="pa-0">
                Company Logo
              </v-subheader>
              <v-row class="mb-2 justify-center align-center">
                <v-col
                  cols="6"
                  sm="6"
                  md="4"
                  lg="3"
                  style="
                    border-style: solid;
                    border-color: #e3e3e3;
                    padding: 10px;
                  ">
                  <v-img
                    v-if="convertedLogo"
                    label="Current Logo"
                    :src="convertedLogo"></v-img>
                </v-col>
                <v-col
                  style="
                    border-style: solid;
                    border-color: #e3e3e3;
                    padding: 10px;
                  "
                  cols="6"
                  sm="6"
                  md="4"
                  lg="2"
                  v-if="!convertedLogo"
                  >No logo.</v-col
                >
              </v-row>
              <v-file-input
                chips
                @change="convertLogo"
                v-model="form.logo"
                prepend-icon="mdi-camera"
                label="Click here to upload an image."
                variant="solo"
                outlined
                dense
                type="file"
                accept=".gif, .png, .svg, .tif, .webp">
              </v-file-input>
              <v-subheader class="ma-0 pa-0"> Office Information </v-subheader>
              <v-text-field
                :rules="[rules.alpha_char, rules.max50]"
                label="Address"
                v-model="form.address1"
                outlined
                dense />
              <v-text-field
                :rules="[rules.alpha_char, rules.max50]"
                label="Unit"
                v-model="form.address2"
                outlined
                dense />
              <v-text-field
                :rules="[rules.alpha_char, rules.max50]"
                label="City"
                v-model="form.city"
                outlined
                dense />
              <v-text-field
                :rules="[rules.alpha_char, rules.max2]"
                label="State"
                v-model="form.state"
                outlined
                dense />
              <v-text-field
                :rules="[rules.zip, rules.max25]"
                label="Zip Code"
                v-model="form.zip"
                outlined
                dense />
              <v-text-field
                :rules="[rules.phone]"
                label="Phone"
                v-model="form.phone"
                outlined
                dense />
              <v-text-field
                :rules="[rules.email]"
                label="Email"
                v-model="form.email"
                outlined
                dense />
              <v-text-field
                label="Privacy Contact"
                v-model="form.privacyContact"
                outlined
                dense />
              <v-text-field
                label="Website"
                v-model="form.website"
                outlined
                dense />
              <v-text-field
                label="Address Url"
                v-model="form.addressUrl"
                outlined
              dense />
                <v-text-field
                label="Billing Url"
                v-model="form.billingUrl"
                outlined
                dense />
              <v-text-field
                label="Booking Url"
                v-model="form.bookingUrl"
                outlined
                dense />
              <v-text-field
                label="Facebook Url"
                v-model="form.facebookUrl"
                outlined
                dense />
              <v-text-field
                label="Financing Url"
                v-model="form.financingUrl"
                outlined
                dense />
              <v-text-field
                label="Instagram Url"
                v-model="form.instagramUrl"
                outlined
                dense />
              <v-text-field
                label="Referral Url"
                v-model="form.referralUrl"
                outlined
                dense />
              <v-text-field
                label="Services Url"
                v-model="form.servicesUrl"
                outlined
                dense />
              <v-text-field
                label="Team Url"
                v-model="form.teamUrl"
                outlined
                dense />
              <v-select
                :items="timezones"
                item-text="name"
                item-value="location"
                label="Timezone"
                v-model="form.timezone"
                outlined
                dense />
              <v-text-field
                label="Custom Header Color"
                v-model="form.headerColor"
                outlined
                dense />
              <v-checkbox
                label="Use White Behind Logo"
                v-model="form.whiteBehindLogo" />
            </v-col>

            <v-row class="mx-0 my-1">
              <v-btn
                class="appPrimaryBtnColor buttons mr-3"
                @click="updateClientInfo">
                Update
              </v-btn>
              <v-btn
                class="appSecondaryBtnColor buttons"
                @click="cancelUpdate">
                Cancel
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-col cols="auto">
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="dialogFail">
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="error" dark>ERROR</v-toolbar>
            <v-card-text>
              <div v-if="largeImg" class="text-p1 pa-12">
                Logo image upload too large. Please resize! Open Dental API
                Configurations test failed. Your changes will not be saved.
              </div>
              <div v-else class="text-p1 pa-12">
                Your changes could not be saved.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>

    <v-col cols="auto">
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="dialogSuccess">
        <template>
          <v-card>
            <v-toolbar color="success" dark>SUCCESS</v-toolbar>
            <v-card-text>
              <div class="text-p1 pa-12">Your changes have been saved.</div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="closeAndReload()">Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-container>
</template>
<script>
import Utils from '../../../src/utils.js';
import DataService from '../../../_services/DataService';
import ValidateService from '../../../_services/ValidateService';

export default {
  name: 'CompanyConfig',
  data() {
    return {
      form: {
        timezone: '',
        headerColor: '',
        whiteBehindLogo: false,
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        email: '',
        privacyContact: '',
        addressUrl: '',
        billingUrl: '',
        bookingUrl: '',
        facebookUrl: '',
        financingUrl: '',
        instagramUrl: '',
        referralUrl: '',
        servicesUrl: '',
        teamUrl: '',
        website: '',
      },
      logo: '',
      convertedLogo: '',
      dialogSuccess: false,
      dialogFail: false,
      timezones: this.getTimezoneArr(),
      loading: true,
      rules: ValidateService.validators,
      largeImg: false,
    };
  },
  methods: {
    closeDialog() {
      this.dialogSuccess = false;
      this.dialogFail = false;
    },
    cancelUpdate() {
      location.reload();
    },
    getTimezoneArr() {
      return Utils.getUsTimezones();
    },
    async closeAndReload() {
      await this.getClientInfo();
      this.dialogSuccess = false;
    },
    async getClientInfo() {
      this.loading = true;

      const client = await DataService.getClientDetails(DataService.getClientId());
      this.form = {
        timezone: client.timezone,
        headerColor: client.customHeaderColor,
        whiteBehindLogo: client.whiteBehindLogo,
        address1: client.address1,
        address2: client.address2,
        city: client.city,
        state: client.state,
        zip: client.zip,
        phone: Utils.formatPhone(client.phone),
        email: client.email,
        privacyContact: client.privacyContact,
        addressUrl: client.addressUrl,
        billingUrl: client.billingUrl,
        bookingUrl: client.bookingUrl,
        facebookUrl: client.facebookUrl,
        financingUrl: client.financingUrl,
        instagramUrl: client.instagramUrl,
        referralUrl: client.referralUrl,
        servicesUrl: client.servicesUrl,
        teamUrl: client.teamUrl,
        website: client.website,
      };

      this.convertedLogo = client.clientLogo;

      this.loading = false;
    },
    displayError(msg) {
      let str = 'Something went wrong';

      if (msg !== undefined) str = msg;
      this.$root.$emit('raise_alert', {
        color: 'info',
        text: str,
      });
    },
    async updateClientInfo() {
      const body = {
        timezone: this.form.timezone,
        customHeaderColor: this.form.headerColor,
        whiteBehindLogo: this.form.whiteBehindLogo,
        address1: this.form.address1,
        address2: this.form.address2,
        city: this.form.city,
        state: this.form.state,
        zip: this.form.zip,
        phone: this.form.phone,
        email: this.form.email,
        privacyContact: this.form.privacyContact,
        clientLogo: this.convertedLogo,
        addressUrl: this.form.addressUrl,
        billingUrl: this.form.billingUrl,
        bookingUrl: this.form.bookingUrl,
        facebookUrl: this.form.facebookUrl,
        financingUrl: this.form.financingUrl,
        instagramUrl: this.form.instagramUrl,
        referralUrl: this.form.referralUrl,
        servicesUrl: this.form.servicesUrl,
        teamUrl: this.form.teamUrl,
        website: this.form.website,
      };

      const response = await DataService.updateClientInfo(DataService.getClientId(), body);
      if (response.status === 200) {
        this.dialogSuccess = true;
      } else {
        this.largeImg = response.status === 413 ? true : false;
        this.dialogFail = true;
      }
    },
    validateImage(fileName) {
      let result = false;
      const extFile = fileName.type;

      if (
        extFile === 'image/svg' ||
        extFile === 'image/png' ||
        extFile === 'image/tif' ||
        extFile === 'image/webp'
      ) {
        result = true;
      } else {
        result = false;
      }
      return result;
    },

    async convertLogo(img) {
      const validImg = this.validateImage(img);

      if (validImg) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(img);
          reader.onload = () => {
            resolve(reader.result);
            this.convertedLogo = reader.result;
          };
          reader.onerror = (error) => reject(error);
        });
      } else {
        this.displayError('Only transparent file types are allowed');
        await this.getClientInfo();
      }
    },
  },
  async mounted() {
    await this.getClientInfo();
    await this.getTimezoneArr();
  },
};
</script>
<style>
.buttons {
  color: #414a5b;
  font-family: 'Inter', sans-serif;
  font-size: 12px !important;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: normal;
  padding: 20px;
}
</style>

<template>
  <v-container>
    <v-layout row>
      <v-flex class="mb-6">
        <v-card class="login-card text-center" tile>
          <img
            v-if="!clientColor"
            src="@/../public/static/img/acrew-sec-logo.png"
            alt="Logo"
            width="400px"
          />
          <v-card-title v-if="clientColor" class="client-banner mb-2">
            <img
              cover
              :width="150"
              :src="clientLogo"
              v-if="clientLogo" />
          </v-card-title>
          <v-card-text>
            <v-container class="login-inner-form">
              <v-layout row wrap>
                <v-flex xs12>
                  <v-row class="text-center mb-1">
                    <v-col cols="12">
                      <h3 class="primary--text">Please check your {{ contactMethod }} for a one time use code</h3>
                    </v-col>
                  </v-row>

                  <v-otp-input
                    v-model="otp_input"
                    length="4"
                    @finish="verifyOTP" />

                  <v-col cols="12" class="d-flex justify-center pa-0">
                    <p>
                      Didn't get a code?
                      <button style="text-decoration: underline; cursor: pointer" @click="resendOneTimePassword()">Click to resend</button></p>
                      <span v-if="contactMethod !== 'email'">
                        &nbsp;or
                        <button style="text-decoration: underline; cursor: pointer" @click="resendOneTimePassword(true)">send via email instead</button>
                      </span>
                  </v-col>

                  <v-container class="form-bottom">
                    <v-row no-gutters style="padding-bottom: 15px">
                      <v-col md="12" class="text-center">
                        <v-btn
                          class="appPrimaryBtnColor btns"
                          @click="verifyOTP">
                          Submit
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <div v-if="clientColor">
            <span style="color: #aaa">Powered by</span>
            <br />
            <img
              src="@/../public/static/img/acrew-sec-logo.png"
              alt="Logo"
              width="150px" />
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<style scoped>
@media screen and (max-width: 450px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.center-all {
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-center {
  margin-left: auto;
  margin-right: auto;
}

.theme--light.v-card.login-card {
  max-width: 500px;
  background-color: #ffffff !important;
  margin: 0 auto;
  margin-top: 10vh;
  box-shadow: 0 !important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.login-inner-form {
  background-color: #ffffff !important;
  padding: 1rem 2.5rem 0 2.5rem;
  /* font-family: 'Inter', sans-serif; */
}

.logn-header {
  font-family: 'Inter', sans-serif;
  /* font-weight: normal; */
  margin-bottom: 1rem;
  color: #68b4c9;
}

.v-input--selection-controls {
  margin-top: 0;
}

.form-bottom {
  padding: 0;
}
.btns {
  color: #414a5b;
  /* background-color: #b0d35e; */
  font-family: 'Inter', sans-serif;
  font-size: 12px !important;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: normal;
}
.client-banner {
  background-color: v-bind(clientColor) !important;
}

.client-banner img {
  margin: auto;
}
</style>
<script>
import DataService from '../../_services/DataService';

export default {
  name: 'TwoFactorAuthentication',
  data() {
    return {
      clientLogo: null,
      clientColor: null,
      otp_input: '',
      redirectURI: '',
      contactMethod: '',
    };
  },
  methods: {
    async resendOneTimePassword(forceEmail) {
      const twoFactorToken = localStorage.getItem('twoFactorToken');

      forceEmail = forceEmail || this.contactMethod === 'email';

      const response = await DataService.regenerate2FA(twoFactorToken, forceEmail);
      if (response.status === 200) {
        this.$root.$emit('raise_alert', {
          color: 'success',
          text: 'A new code has been sent',
        });

        localStorage.setItem('twoFactorToken', response.data.token); 
        this.contactMethod = response.data.sentSms === true ? 'text messages' : 'email';
      }
    },
    async verifyOTP() {
      const twoFactorToken = localStorage.getItem('twoFactorToken');
      const twoFactorResponse = await DataService.verifyOTP({ token: twoFactorToken, otp: this.otp_input });
      if (twoFactorResponse.status == 200) {
        const { user, token, selectedClientId, clientIds, role } = twoFactorResponse.data;
        DataService.updateSessionUser(user);
        DataService.updateClientId(selectedClientId);
        DataService.updateSessionRole(role);

        localStorage.removeItem('twoFactorToken');
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('clientIds', JSON.stringify(clientIds));
        localStorage.setItem('role', role);
        localStorage.setItem('token', token);

        this.$root.$emit('raise_alert', {
          color: 'success',
          text: 'Success, logging in..',
        });

        this.$router.push('/dashboard');
      } else {
        this.$root.$emit('raise_alert', {
          color: 'error',
          text: 'Unable to validate code',
        });
      }
    },
    async getClientPreferences() {
      const response = await DataService.getClientPreferences();
      if (response.status === 200) {
        this.clientLogo = response.data.clientLogo;
        this.clientColor = response.data.clientColor;
      }
    },
  },
  created() {
    if (!localStorage.getItem('twoFactorToken')) {
      this.$router.push('/login');
      return;
    }

    // check for redirect
    if (this.$route.query.redirectURI != undefined) {
      // URI is automatically decoded, so re-encode to pass on to dashboard page
      this.redirectURI = this.$route.query.redirectURI;
    } else {
      this.redirectURI = '/dashboard'
    }
  },
  async mounted() {
    // the query doesn't reliably type the sms
    this.contactMethod = `${this.$route.query.sentSms}` === 'true' ? 'text messages' : 'email';
    await this.getClientPreferences();
  },
};
</script>

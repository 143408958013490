<template>
  <v-app :style="appStyle">
    <Alerts :alertArray="alertArray" />
    <NavDrawer v-if="this.$vuetify.breakpoint.mdAndUp" />
    <v-main>
      <v-app-bar app color="cardcolor" dark height="80">
        <div class="logo-container">
          <img
            v-if="clientLogo"
            :src="clientLogo"
            :style="{
              'max-height': '100%',
              background: whiteBehindLogo ? 'white' : '',
            }"
            alt="Logo"
            contain />
          <v-img
            v-else
            src="@/../public/static/img/logo-adh.png"
            alt="Logo"
            contain
            height="60"
            width="60" />
        </div>

        <v-select
          v-if="clients.length > 1"
          class="mt-4"
          :items="clients"
          :item-text="item => item.name"
          :item-value="item => item.id"
          :label="selectedClient"
          @input="selectClient"
          outlined
          dense />
      </v-app-bar>

      <ServiceMessage class="px-5" />

      <transition name="fade">
        <keep-alive include="users">
          <router-view :key="$route.fullPath" />
        </keep-alive>
      </transition>
    </v-main>

    <MobileNavigation v-if="this.$vuetify.breakpoint.smAndDown" />

  </v-app>
</template>
<script>
import DataService from '../../_services/DataService';
import Alerts from '../components/Alerts.vue';
import MobileNavigation from '../components/MobileNavigation.vue';
import NavDrawer from '../components/NavDrawer.vue';
import ServiceMessage from '../components/ServiceMessage.vue';

export default {
  components: {
    Alerts,
    MobileNavigation,
    NavDrawer,
    ServiceMessage,
  },
  data() {
    return {
      clientLogo: '',
      clientColor: '',
      useWhiteBackground: false,
      currentTime: new Date().toLocaleDateString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }),
      clients: [],
      selectedClient: '',
      selectedLocale: 'EN',
      loading2: false,
      items2: [],
      search: null,
      select: null,
      states: [],
      alertArray: [],
      tabNotificationTimer: null,
    };
  },
  computed: {
    appStyle() {
      return {
        // Hide horizontal and vertical scrollbars on mobile screens
        overflowX: (this.$vuetify.breakpoint.smAndDown ||
          this.$vuetify.breakpoint.mdAndUp ) ?
          'hidden' :
           'auto',
        overflowY: ( this.$vuetify.breakpoint.smAndDown ||
          this.$vuetify.breakpoint.mdAndUp )
           ? 'hidden'
           : 'auto'
      };
    },
  },
  methods: {
    async getClientPreferences() {
      const response = await DataService.getClientPreferences();
      if (response.status === 200) {
        this.clientLogo = response.data.clientLogo;
        this.clientColor = response.data.clientColor;
        this.whiteBehindLogo = response.data.whiteBehindLogo;
      }
    },
    async getUserClients() {
      const response = await DataService.getUserClients();
      if (response.status === 200) {
        this.clients = response.data;

        this.selectedClient = this.clients.find((client) => client.id === DataService.getClientId()).name;
      }
    },
    async selectClient(selection) {
      try {
        await DataService.changeClientSelection(selection);
        window.location.reload();
      } catch (ex) {
        this.$root.$emit('raise_alert', {
          color: 'info',
          text: ex.message,
         });
      }
    },
    raiseAlert(alert) {
      this.alertArray = [];
      this.alertArray.push(alert);
      setTimeout(() => (this.alertArray = []), 1000 * 3);
    },
    dismissAlert() {
      this.alert = false;
    },
    async tabNotificationUpdate() {
      const messageCountResponse = await DataService.getUnreadMessageCount();
      document.title = document.title.replace(/ *\([^)]*\) */g, ""); // Removes all ( ) and text therein
      if (messageCountResponse.status === 200 && messageCountResponse.data > 0) {
        document.title += ` (${messageCountResponse.data})`;
      } else if (messageCountResponse.status === 401 || messageCountResponse.status === 403) {
        this.clearTabNotificationTimer();
      }
    },
    clearTabNotificationTimer() {
      if (this.tabNotificationTimer) {
        clearInterval(this.tabNotificationTimer);
        document.title = 'Acrew';
      }
    },
    onLogout() {
      // TODO other cleanup?
      this.clearTabNotificationTimer();
    },
  },
  async mounted() {
    document.title = 'Acrew';

    await Promise.all([
      this.getClientPreferences(),
      this.getUserClients(),
    ]);

    if (DataService.getRole() && DataService.getRole() !== 'patient') {
      /* If logged in and not a patient display # of unread messages on page title */
      this.tabNotificationUpdate();
      this.tabNotificationTimer = setInterval(this.tabNotificationUpdate, 60000);
    }
    this.$root.$on('raise_alert', this.raiseAlert);
    this.$root.$on('dismiss_alert', this.dismissAlert);
    this.$root.$on('logout', this.onLogout);
  },
};
</script>
<style scoped>
h1,
h2,
.v-card__title > span > h3,
.dashtitle > span,
.dashtitle > .v-icon {
  color: #414a5b;
}

/* This will remove the box-shadow/drop-shadow from everything in an app */
body * {
  box-shadow: none !important;
}

.navcolor {
  border-color: #68b4c9;
}
.theme--light.v-pagination.v-pagination__item--active {
  color: #ffffff !important;
}

.v-dialog {
  background-color: #ffffff !important;
}

.v-dialog > .v-card > .v-card__text {
  padding: 0 24px 1px 24px !important;
}

.v-input.dg-page-size {
  font-size: 14px !important;
}

.v-list-item__title {
  align-self: center;
  font-size: 0.9rem !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #202020 !important;
  font-size: 0.85em;
}

html {
  font-size: 14px !important;
  text-rendering: opti mizeLegibility;
}

h1.dashtitle {
  color: #202020 !important;
  font-size: 1.75em;
}

.v-data-table-header tr {
  background-color: #eaf7fa !important;
  color: #202020 !important;
}

.theme--light.v-data-table .v-data-table-header th.sortable,
.theme--light.v-data-table .v-data-table-header th.sortable.active {
  color: #202020 !important;
}

.theme--light.v-data-table .v-data-table-header th.sortable:hover,
.theme--light.v-data-table .v-data-table-header th.sortable.active {
  color: #202020 !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable.active
  .v-data-table-header__icon {
  color: #202020 !important;
}

.v-navigation-drawer
  .v-list-item--active.v-list-item.v-list-item--link.theme--dark {
  color: #ffffff !important;
  caret-color: #ffffff !important;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 24px !important;
}

.v-card__title.headline.primary {
  color: #ffffff !important;
}

.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 64px !important;
}

.v-navigation-drawer__content
  > .v-list-group__items
  > .v-list-item
  > .v-list-item__title {
  color: #e0b993 !important;
}

.v-list-item__icon.v-list-group__header__append-icon {
  color: #181818 !important;
}

.v-list-item.v-list-group__header {
  padding-left: 0;
}

.v-navigation-drawer__content > .v-list-item__title {
  color: #ffffff;
}

.container {
  width: 100%;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;
}

.theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.nobr {
  white-space: nowrap;
}

.v-btn.search-toolbuttons.active {
  background: #39b54a;
  background-color: #39b54a !important;
  border-color: #39b54a;
  color: #ffffff;
}

.v-btn.search-toolbuttons {
  margin-left: 5px;
  background-color: #ffffff;
  border-color: #006fba;
  border-width: 1px;
  color: #006fba;
  margin-bottom: 5px;
}

.v-input {
  color: #ffffff;
}

.v-dialog {
  color: #ffffff;
}

.v-pagination {
  display: inline-flex;
  list-style-type: none;
  justify-content: right !important;
  margin: 0;
  max-width: 100%;
  width: 100%;
}

.nav-sublink .v-list-group__items .v-list-item__title {
  color: #d2d2d2 !important;
}

.theme--light.v-pagination .v-pagination__item--active {
  color: #ffffff !important;
}

@media (min-width: 960px) {
  .container {
    max-width: 1100px !important;
  }
}

@media (min-width: 1264px) {
  .container {
    max-width: none !important;
  }
}

@media (min-width: 1904px) {
  .container {
    max-width: none !important;
  }
}

.topbar-text {
  font-size: 1rem;
  padding: 0;
}

.tiny {
  font-size: 0.7rem;
}

.v-toolbar__content {
  max-width: none !important;
}

.container .nopadd {
  padding: 0 0;
  padding-left: 1rem;
}

footer.theme--light.v-footer {
  background-color: #1d2c39;
  color: #ffffff;
}

div.no-gutters .footerlinks-wrapper {
  padding-top: 0.7rem;
}

.v-application a.footerlinks {
  color: #ffffff;
  text-decoration: underline;
  font-size: 0.75rem;
}

.v-application a.footerlinks:hover {
  color: #ffffff;
}

.v-btn--contained::after {
  box-shadow: 0;
}

@media (max-width: 960px) {
  .topbar-text {
    display: none;
  }

  .topbar-search {
    display: none;
  }
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>

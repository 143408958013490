<template>
  <v-container>
    <v-row class="mt-3">
      <v-col cols="6">
        <v-row class="mx-0">
          <h1 class="dashtitle"><span>Select User</span></h1>
        </v-row>
        <v-row>
          <v-col cols="9">
            <v-text-field label="Search" v-model="search" outlined dense style="color: #414a5b; border-width: 2px" />
          </v-col>
          <v-col cols="1">
            <v-btn color="primary" @click="getUsers()">Search</v-btn>
          </v-col>
        </v-row>
        <v-card class="justify-center" outlined>
          <div class="text-center">
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="primary"
              style="
                height: 50%;
                margin: auto;
                padding-top: 50%;" />
          </div>
          <div v-if="!loading" class="py-2">
            <v-list class="py-0">
              <v-list-item-group active-class="selectedItemClass" id="usersList">
                <v-list-item v-for="(item, i) in users" :key="i" @click="selectUser(item)" class="my-n1">
                  <v-btn class="py-2" width="100%" outlined>
                    <v-avatar class="primary mr-5" size="30">
                      <span class="white--text">{{item.firstName.charAt(0) + item.lastName.charAt(0)}}</span>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{item.firstName + ' ' + item.lastName + (isAdminUser ? ` (${item.role === 2 ? 'Client User' : 'Admin'})` : '') }}</v-list-item-title>
                    </v-list-item-content>
                  </v-btn>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </v-card>
        <v-row class="align-center pt-2">
          <v-col cols="3">
            <v-select
              class="dg-page-size pl-4"
              v-model="pageSize"
              :items="pageSizes"
              label="Items per Page"
              @change="handlePageSizeChange" />
          </v-col>
          <v-col>
            <v-pagination
              v-model="page"
              :length="totalPages"
              total-visible="5"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              active-class="pageNum">
            </v-pagination>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row class="mx-0">
          <h1 class="dashtitle">
            <span v-if="form.id">{{ form.FName }} {{ form.LName }} User Settings</span>
            <span v-else>User Settings</span>
          </h1>
        </v-row>
        <v-row class="mx-0 mt-6">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="!form.userId"
                @click="sendPasswordReset()"
                class="appSecondaryBtnColor buttons mr-5">
                Send Password Reset Email
              </v-btn>
            </template>
            <span>Send this verified user an email with instructions to reset their password.</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="!form.userId"
                @click="showConfirmPasswordDialog = true"
                class="appSecondaryBtnColor buttons">
                Send New Random Password
              </v-btn>
            </template>
            <span>Reset this users password to a new randon password and email it to the user.</span>
          </v-tooltip>
        </v-row>
        <v-card class="mt-6">
          <v-row class="my-1">
            <v-col cols="6">
              <h2>Personal Information</h2>
            </v-col>
          </v-row>
          <v-row class="my-0" no-gutters>
            <v-col cols="6">
              <v-text-field v-model="form.firstName" label="First Name" outlined dense class="mr-3" />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form.lastName" label="Last Name" outlined dense class="mr-3"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form.email" label="Email" :rules="[rules.email, rules.required]" outlined dense class="mr-3" />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form.phone" label="Phone" :rules="[rules.phone]" outlined dense class="mr-3" />
            </v-col>
            <v-col cols="6" v-if="isAdminUser">
              <v-select v-model="form.role" label="Role" outlined dense class="mr-3" :items="roles" item-text="label" />
            </v-col>
          </v-row>
        </v-card>
        <v-row class="mx-0 my-1">
          <v-btn class="appPrimaryBtnColor buttons mr-3" @click="saveUser()">
            Save
          </v-btn>
          <v-btn class="appSecondaryBtnColor buttons mr-3" @click="cancel()">Cancel</v-btn>
          <v-btn class="appPrimaryBtnColor buttons mr-3" @click="addUser()">
            Add new User
          </v-btn>
          <v-btn class="deleteBtnColor buttons" :disabled="!form.userId" @click="showConfirmDeleteDialog = true">
            Delete
          </v-btn>
        </v-row>
      </v-col>
      <v-dialog v-model="showConfirmDeleteDialog" max-width="600px" persistant>
        <v-card>
          <v-card-title>Confirm</v-card-title>
          <v-card-text class="pb-2">Are you sure you want to delete this user?</v-card-text>
          <v-card-actions>
            <v-btn text color="primary" @click="deleteUser()">Continue</v-btn>
            <v-btn text color="secondary" @click="showConfirmDeleteDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showConfirmPasswordDialog" max-width="600px" persistant>
        <v-card>
          <v-card-title>Confirm</v-card-title>
          <v-card-text class="pb-2">Are you sure you want to continue? This will change this user's password.</v-card-text>
          <v-card-actions>
            <v-btn text color="primary" @click="changeAndSendPassword()">Continue</v-btn>
            <v-btn text color="secondary" @click="showConfirmPasswordDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import DataService from '../../../_services/DataService';
import ValidateService from '../../../_services/ValidateService';
export default {
  name: 'AdminManagement',
  props: ['template_props'],
  components: {},
  data() {
    return {
      page: 1,
      totalPages: 0,
      pageSizes: [10, 20, 50, 100, 200],
      pageSize: 10,
      pageName: 'User Settings',
      loading: true,
      search: '',
      isAdminUser: false,
      isVerifiedUser: false,
      showConfirmDeleteDialog: false,
      showConfirmPasswordDialog: false,
      selectedUser: 0,
      tabs: [
        { label: 'Admin Management', section: '' },
        { label: 'Patient Management', section: '' },
        { label: 'Schedule Management', section: '' },
      ],
      roles: [
        { value: 1, label: 'Admin' },
        { value: 2, label: 'Client User' },
      ],
      form: {},
      originalForm: {},
      rules: ValidateService.validators,
    };
  },
  watch: {
    page: {
      async handler() {
        this.getUsers();
      },
    },
  },
  methods: {
    addUser() {
      this.form = {};
      // There might be some way in vue to do this
      // but this removes the 'active' class from the LI
      // elements, when you click on 'Add New'
      const activeRecords = document.querySelectorAll('div.selectedItemClass');
      activeRecords[0].classList.remove('selectedItemClass')
      activeRecords[0].classList.remove('v-list-item--active')
    },
    async handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.getUsers();
    },
    async changeAndSendPassword(alert = true) {
      this.showConfirmPasswordDialog = false;
      const response = await DataService.sendPasswordResetEmail({ username: this.form.email });
      if (response.status == 200 && alert) {
        this.$root.$emit('raise_alert', {
          color: 'success',
          text: response.data,
        });

        return true;
      }

      this.$root.$emit('raise_alert', {
        color: 'info',
        text: response.data || 'Something went wrong',
      });

      return false;
    },
    async sendPasswordReset() {
      try {
        const response = await DataService.sendPasswordResetEmail({ username: this.form.email });
        if (response.status == 200) {
          this.$root.$emit('raise_alert', {
            color: 'success',
            text: response.data,
          });
        }
      } catch (err) {
        this.$root.$emit('raise_alert', {
          color: 'info',
          text: err.response.data || 'Something went wrong',
        });
      }
    },
    async saveUser() {
      if (!this.form.userId) {
        const creationResponse = await DataService.createClientUserAccount(this.form);
        if (creationResponse.status === 201) {
          const passwordSent = await this.changeAndSendPassword(false);
          if (passwordSent.status === 200) {
            this.$root.$emit('raise_alert', {
              color: 'success',
              text: 'Saved!',
            });
          }

          await this.getUsers();
        } else {
          this.$root.$emit('raise_alert', {
            color: 'info',
            text: creationResponse.data || 'Something went wrong',
          });
        }
      } else {
        const updateResponse = await DataService.updateUser(this.form.userId, this.form);
        if (updateResponse.status == 200) {
          this.$root.$emit('raise_alert', {
            color: 'success',
            text: 'Saved!',
          });
          await  this.getUsers();
        } else {
          this.$root.$emit('raise_alert', {
            color: 'info',
            text: updateResponse.data || 'Something went wrong',
          });
        }
      }
    },
    cancel() {
      this.form = Object.assign({}, this.originalForm);
    },
    selectUser(item) {
      this.form = item;
      this.originalForm = Object.assign({}, this.form);
    },
    async getUsers() {
      this.loading = true;
      this.users = [];
      let params = `?page=${this.page - 1}&size=${this.pageSize}`;
      if (this.search) {
        params = `?page=${this.page - 1}&size=${this.pageSize}&searchTerm=${this.search}`;
      }
      const response = await DataService.getClientUserAccounts(params);
      if (response.status === 200) {
        this.totalPages = Math.ceil(response.data.count / this.pageSize) || 0;

        if (response.data.error) {
          this.$root.$emit('raise_alert', {
            color: 'info',
            text: response.data.error,
          });
        }
        const users = response.data.rows;
        this.users = users.map(this.getDisplayData);
        this.loading = false;
      }
    },
    getDisplayData(d) {
      return {
        firstName: d.user.firstName,
        lastName: d.user.lastName,
        email: d.user.email,
        phone: d.user.phone,
        userId: d.user.id,
        role: d.RoleId,
      };
    },
    async deleteUser() {
      const deleted = await DataService.deleteClientUserAccount(this.form.userId);
      if (deleted.status === 200) {
        this.form.userId = null;
        this.$root.$emit('raise_alert', {
          color: 'info',
          text: 'Record deleted!',
        });
        this.getUsers();
        this.showConfirmDeleteDialog = false;
      } else {
        this.$root.$emit('raise_alert', {
          color: 'success',
          text: 'Something went wrong!',
        });
      }
    },
  },
  async mounted() {
    this.isAdminUser = localStorage.getItem('role') === 'clientAdmin';
    await this.getUsers();
    // Simulate a click so that first record is selected automatically
    document.getElementById("usersList").firstChild.click();
  },
};
</script>
<style scoped>
.pageNum {
  color: white;
}

.theme--light.v-sheet--outlined {
  border: thin solid #414a5b;
}

.buttons {
  color: #414a5b;
  /* background-color: #b0d35e; */
  font-family: 'Inter', sans-serif;
  font-size: 12px !important;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: normal;
  padding: 20px;
}

.deleteBtn {
  color: white;
}

.selectedItemClass button {
  background-color: #414a5b;
  color: white;
}

.v-list-item__title {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  text-transform: capitalize;
}
</style>

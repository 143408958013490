<template>
  <v-container>
    <v-layout row>
      <v-flex class="mb-6">
        <v-card class="login-card text-center" tile>
          <img
            v-if="!clientColor"
            src="@/../public/static/img/acrew-sec-logo.png"
            alt="Logo"
            width="400px"
          />
          <v-card-title v-if="clientColor" class="client-banner mb-2">
            <img
              cover
              :width="150"
              :src="clientLogo"
              v-if="clientLogo" />
          </v-card-title>
          <v-card-text>
            <v-container class="login-inner-form">
              <form @submit.prevent="submit">
                <v-layout row wrap>
                  <v-flex xs12>
                    <div v-if="!submitted">
                      <h3 class="logn-header">
                        Enter email address to send password reset link
                      </h3>
                      <v-text-field background-color="white" label="Email" v-model="username"
                        placeholder=" " type="email" required outlined dense></v-text-field>
                      <v-container class="form-bottom">
                        <v-row no-gutters style="padding-bottom: 15px">
                          <v-col md="12" class="text-center">
                            <v-btn class="sign-in success" type="submit" style="width:200px">
                              Submit
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                    <div v-else>
                      <h3 class="logn-header">
                        Your reset link has been sent!
                      </h3>
                      <h3 class="logn-header">
                        Please follow the instructions in your email to reset your password.
                      </h3>
                    </div>
                  </v-flex>
                </v-layout>
              </form>
            </v-container>
          </v-card-text>
          <div v-if="clientColor">
            <span style="color: #aaa">Powered by</span>
            <br />
            <img
              src="@/../public/static/img/acrew-sec-logo.png"
              alt="Logo"
              width="150px" />
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<style scoped>
@media screen and (max-width: 450px) {

  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.center-all {
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-center {
  margin-left: auto;
  margin-right: auto;
}

.theme--light.v-card.login-card {
  max-width: 500px;
  background-color: #ffffff !important;
  margin: 0 auto;
  margin-top: 10vh;
  box-shadow: 0 !important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.login-inner-form {
  background-color: #ffffff !important;
  padding: 1rem 2.5rem 0 2.5rem;
}

.logn-header {
  font-weight: normal;
  margin-bottom: 1rem;
  color: #68B4C9;
}

.v-input--selection-controls {
  margin-top: 0;
}

.form-bottom {
  padding: 0;
}

.client-banner {
  background-color: v-bind(clientColor) !important;
}

.client-banner img {
  margin: auto;
}
</style>
<script>
import DataService from '../../_services/DataService.js';
export default {
  name: 'ForgotPassword',
  data() {
    return {
      clientLogo: null,
      clientColor: null,
      username: '',
      submitted: false,
    };
  },
  methods: {
    async submit() {
      const submitResp = await DataService.sendPasswordResetEmail({ username: this.username });
      if (submitResp) {
        this.submitted = true;
      } else {
        this.$root.$emit('raise_alert', {
          color: 'warning',
          text: submitResp.data || 'Something went wrong',
        });
      }
    },
    async getClientPreferences() {
      const response = await DataService.getClientPreferences();
      if (response.status === 200) {
        this.clientLogo = response.data.clientLogo;
        this.clientColor = response.data.clientColor;
      }
    },
  },
  async mounted() {
    this.username = this.$route.query.username ?? '';
  }
};
</script>
<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="3" class="">
        <v-card class="mt-5 mb-5" elevation="0">
          <!--lists loader -->
          <div v-if="loading" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
              style="
                height: 50%;
                margin: auto;
                padding-top: 50%;
              " />
          </div>
          <div v-if="!loading">
            <v-row class="px-0 pb-1 ml-lg-1">
              <h2 class="dashtitle">
                <span>Select Email Template</span>
              </h2>
            </v-row>
            <v-list class="px-0 py-0" id="emailTemplateList">
              <v-list-item-group active-class="highlighted">
                <!-- email card -->
                <v-card class="mb-4 mt-3">
                  <v-list-item
                    v-for="(item, i) in templatesEmail"
                    :key="i"
                    :id="item.id"
                    @click="selectTemplate(item)"
                    class="px-0 mx-0">
                    <v-btn outlined width="100%" class="d-inline-block text-truncate" :id="item.id">
                      <v-list-item-icon class="mt-1">
                        <v-chip style="background: #6bb2f4" class="px-5" small>
                          <v-icon style="color: #ffffff">mdi mdi-email</v-icon>
                        </v-chip>
                      </v-list-item-icon>

                      <v-list-item-content class="mt-0 pt-0">
                        <v-list-item-title class="text-start">
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-btn>
                  </v-list-item>
                </v-card>
              </v-list-item-group>
            </v-list>

            <v-row class="mb-1 mt-3 ml-lg-1">
              <h2 class="dashtitle">
                <span>Select SMS Template</span>
              </h2>
            </v-row>

            <v-list class="px-0 py-0" id="smsTemplateList">
              <v-list-item-group active-class="highlighted">
                <v-card class="mb-4">
                  <v-list-item
                    v-for="(item, i) in templatesSMS"
                    :key="i"
                    :id="item.id"
                    @click="selectTemplate(item)"
                    class="px-0 mx-0"
                    ref="listItemSms">
                    <v-btn outlined width="100%" class="d-inline-block text-truncate">
                      <v-list-item-icon class="mt-1">
                        <v-chip style="background: #a95cf7" class="px-5" small>
                          <v-icon style="color: #ffffff">mdi-message-text</v-icon>
                        </v-chip>
                      </v-list-item-icon>
                      <v-list-item-content class="mt-0 pt-0">
                        <v-list-item-title class="text-start">
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-btn>
                  </v-list-item>
                </v-card>
              </v-list-item-group>
            </v-list>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="9" class="mt-0">
        <v-card class="mx-0 mt-1">
          <v-form ref="form" @submit.prevent="submit" lazy-validation>
            <v-sheet class="py-2 px-1">
              <v-row class="d-flex justify-end">
                <v-btn @click="createNewTemplate" color="#6bb2f4" class="white--text mr-3" dense>
                  Create New Template
                </v-btn>
                <v-btn @click="saveTemplate()"  color="#6bb2f4" class="white--text mr-3" dense>
                  Save
                </v-btn>
                <v-btn @click="showDeleteDialog = true" style="color: #ffffff; font-weight: 700" class="deleteBtnColor mr-3" dense>
                  Delete
                </v-btn>
                <v-btn v-if="Number(form.id) > 0" @click="showOneTimeSendDialog = true" color="#6bb2f4" class="white--text mr-0" dense>
                  One-Time Send
                </v-btn>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    label="Title"
                    hide-details="auto"
                    class="my-4"
                    placeholder="New Template"
                    v-model="form.name"
                    validate-on-blur
                    :rules="[rules.required, rules.max50]" />
                </v-col>

                <v-col>
                  <v-switch inset label="Enabled" v-model="form.enabled" />
                </v-col>
              </v-row>

              <v-row class="mt-0 px-4">
                <PageTabs v-bind:tabs="tabs" />
              </v-row>

              <!-- response dialogue -->
              <v-dialog v-model="isResponseDialogOpen" persistent width="290" max-height="290">
                <v-card class="pt-4">
                  <v-card-text>{{ responseDialogText }}</v-card-text>
                  <v-card-actions>
                    <v-btn outlined color="green darken-1" text @click="closeDialog()">
                      Okay
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="showOneTimeSendDialog" persistent width="290" max-height="290">
                <v-card>
                  <v-card-title>Send One Time</v-card-title>
                  <v-card-text>Are you sure you want to send this template immediately? All patients for the selected query will receive email/sms notifications. Unsaved changes will be ignored when sending.</v-card-text>
                  <v-card-actions>
                    <v-btn outlined color="green darken-1" text @click="sendTemplateImmediate()">Send</v-btn>
                    <v-btn outlined text @click="showOneTimeSendDialog = false">Cancel</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="showDeleteDialog" persistent width="290" max-height="290">
                <v-card>
                  <v-card-title>Delete</v-card-title>
                  <v-card-text>Are you sure you delete this template?</v-card-text>
                  <v-card-actions>
                    <v-btn outlined color="danger" text @click="deleteTemplate()">Delete</v-btn>
                    <v-btn outlined text @click="showDeleteDialog = false">Cancel</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-sheet>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DataService from '../../../_services/DataService';
import Utils from '../../utils';
import ValidateService from '../../../_services/ValidateService';
import PageTabs from '../../components/PageTabs';
import Content from './editing/Content';
import Query from './editing/Query';
import Scheduling from './editing/Scheduling';

export default {
  name: 'CreateNewMessage',
  props: [],
  components: {
    PageTabs,
    Content,
    Scheduling,
  },
  data() {
    return {
      pageName: 'Create/Edit Message Templates',
      loading: true,
      form: {
        name: '',
        enabled: true,
      },
      newId: 'new',
      queries: [],
      templates: [],
      templatesEmail: [],
      templatesSMS: [],
      minHeight: '450px',
      appearance: {
        theme: 'light',
      },
      responseDialogText: '',
      validationMsgs: {
        line1: 'Form could not be submitted',
        line2: 'Please enter a valid - ',
      },
      isResponseDialogOpen: false,
      setAlert: Utils.setAlert,
      rules: ValidateService.validators,
      showOneTimeSendDialog: false,
      showDeleteDialog: false,
      selectedTemplate: null,
      tabs: [
        {
          label: 'Content',
          section: Content,
          pageData: { parent: this },
        },
        {
          label: 'Scheduling',
          section: Scheduling,
          pageData: { parent: this },
        },
        {
          label: 'Query',
          section: Query,
          pageData: { parent: this },
        },
      ]
    };
  },
  methods: {
    closeDialog() {
      this.isResponseDialogOpen = false;
      this.showOneTimeSendDialog = false;
    },
    createNewTemplate() {
      this.selectedTemplate = {
        id: this.newId,
        name: 'New Template',
        cronSchedule: '',
        queryId: '',
        subjectLine: 'Please enter your subject here',
        body: '',
        category: '',
        type: 'email/html',
        enabled: true,
        json: {},
      };

      this.form = this.selectedTemplate;

      this.templates.unshift(this.selectedTemplate);
    },
    async deleteTemplate() {
      const id = this.form.id;

      const response = await DataService.deleteTemplate(id)
      if (response.status == 200) {
        this.$root.$emit('raise_alert', this.setAlert('success', 'Template successfully deleted'));

        await this.getTemplatesAll();

        document.getElementById('emailTemplateList').firstChild.firstChild.firstChild.click();

        this.showDeleteDialog = false;
      } else {
        this.$root.$emit('raise_alert', this.setAlert('fail', 'Something went wrong; Template was not deleted'));
      }
    },
    async getTemplatesAll() {
      this.$refs.form.resetValidation();
      this.loading = true;

      const templateResponse = await DataService.getTemplates()
      if (templateResponse.status === 200) {
        const templateRows = templateResponse.data;
        this.templates = templateRows.map(this.displayTemplateData);
        this.setLists();
      }

      if (!this.queries.length) {
        const queryResponse = await DataService.getClientQueries();
        if (queryResponse.status === 200) {
          const queryData = queryResponse.data;
          this.queries = queryData.map(this.displayQueryData);
        }
      }

      this.loading = false;
    },
    setFormByQueryData() {
      if (this.$route.query.id && this.$route.query.id != this.newId) {
        const element = document.getElementById(this.$route.query.id);
        if (element) {
          element.click();
          return;
        }
      } else {
        document.getElementById('emailTemplateList').firstChild.firstChild.firstChild.click();
      }
    },
    setLists() {
      this.templatesEmail = this.templates.filter((e) => e.type === 'email/html');
      this.templatesSMS = this.templates.filter((e) => e.type === 'sms/text');
    },
    async saveTemplate(disableAlert) {
      if (!this.$refs.form.validate()) { // todo validate subs
        return false;
      }

      const params = {
        ...this.selectedTemplate, // default to existing values
        id: this.selectedTemplate.id === this.newId ? 0 : Number(this.$route.query.id),
        name: this.form.name,
        enabled: this.form.enabled,
      };

      // handle sub tabs not loading (only edited eg the first tab)
      if (this.contentTab) {
        params.subjectLine = this.contentTab.form.subjectLine;
        params.body = this.contentTab?.form.body;
        params.type = this.contentTab?.form.messageType;
      }

      if (this.scheduleTab) {
        params.cronSchedule = this.scheduleTab.form.cronSchedule;
      }

      if (this.queryTab) {
        params.queryId = this.queryTab?.form.queryId;
        params.category = this.queryTab?.form.category.toLowerCase();
      }

      let saveSuccess;
      if (Number(params.id) > 0) {
        saveSuccess = await this.updateTemplate(params, disableAlert);
      } else {
        saveSuccess = await this.createTemplate(params, disableAlert);
      }

      return saveSuccess;
    },
    async createTemplate(params, disableAlert) {
      const createResult = await DataService.createTemplate(params);
      const createSuccess = createResult.status === 201;
      if (createSuccess) {
        this.responseDialogText = 'Template has been created successfully.';

        await this.getTemplatesAll();

        console.log('selecting new id:', createResult.data.id)
        setTimeout(() => this.selectTemplate(createResult.data.id), 200);
      } else {
        this.responseDialogText = 'New Template could not be created';
      }

      if (!disableAlert) {
        this.isResponseDialogOpen = true;
      }

      return createSuccess;
    },
    async updateTemplate(params, disableAlert) {
      const updateResult = await DataService.updateTemplate(params);
      const updateSuccess = updateResult.status === 200;
      if (updateSuccess) {
        this.responseDialogText = 'Template has been updated successfully.';

        this.selectedTemplate = { ...params };

        await this.getTemplatesAll();
      } else {
        this.responseDialogText = 'Template could not be updated';
      }

      if (!disableAlert) {
        this.isResponseDialogOpen = true;
      }

      return updateSuccess;
    },
    async sendTemplateImmediate() {
      const sendResult = await DataService.sendTemplateImmediate(Number(this.$route.query.id));
      if (sendResult.status === 200) {
        this.responseDialogText = 'Message was sent successfully';
      } else {
        this.responseDialogText = 'An error occurred and the message could not be sent';
      }

      this.showOneTimeSendDialog = false;
      this.isResponseDialogOpen = true;
    },
    async goToEmailEditor() {
      const saveResult = await this.saveTemplate(true);
      if (saveResult) {
        this.$router.push({
          name: 'EmailTemplateEditor',
          query: {
            id: this.$route.query.id
          },
        });
      }
    },
    selectTemplate(d) {
      this.selectedTemplate = d;

      this.form = {
        id: d.id,
        name: d.name,
        cronSchedule: d.cronSchedule ? d.cronSchedule : this.defaultCron,
        queryId: d.queryId,
        subjectLine: d.subjectLine,
        body: d.body,
        category: d.category?.charAt(0).toUpperCase() + d.category?.slice(1),
        type: d.type,
        enabled: d.enabled,
      };

      if (Number(this.$route.query.id) !== d.id) {
        this.$router.push({ query: { id: d.id }});
      }
    },
    displayTemplateData(d) {
      return {
        id: d.id,
        name: d.name,
        queryId: d.queryId,
        cronSchedule: d.cronSchedule,
        type: d.type,
        category: d.category,
        subjectLine: d.subjectLine,
        body: d.body,
        enabled: d.enabled,
      };
    },
    displayQueryData(d) {
      return {
        id: d.id,
        name: d.name,
        fields: d.fields,
        queryTest: d.queryText,
      };
    },
  },
  async mounted() {
    await this.getTemplatesAll();

    this.setFormByQueryData();
  },
};
</script>
<style>
.highlighted button {
  background-color: #b0d35e !important;
  color: #414a5b !important;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}
</style>

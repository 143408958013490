<template>
  <v-app>
    <v-container class="ma-3" style="height: 100%">
      <v-row>
        <ServiceMessage page="scheduling" />
      </v-row>
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <h1 class="page-title">
            <span>Schedule Appointment</span>
          </h1>
        </v-col>
      </v-row>
      <!-- Start Vertical Stepper Scheduler -->
      <template>
        <v-overlay v-if="apptSchedulingLoading"
          model-value="apptSchedulingLoading"
          class="align-center justify-center">
          <v-progress-circular
            color="primary"
            indeterminate
            size="64" />
        </v-overlay>
        <v-stepper v-model="currentStep" vertical>
          <!-- Start Stepper Step 1 -->
          <v-stepper-step
            :complete="currentStep > 1"
            step="1"
            @click="setStepIfBeyond(1)">
            Select a patient to schedule
            <span v-if="currentStep > 1">- {{`${selectedPatient.firstName} ${selectedPatient.lastName}`}}</span>
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-card style="max-height: 50%">
              <v-progress-circular
                v-if="patientsLoading"
                indeterminate
                color="primary"
                style="height: 50px !important; margin: auto; width: 100%" />
              <div v-if="!patientsLoading">
                <v-list flat>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in patientList"
                      :key="i"
                      @click="selectPatient(item)"
                      class="pb-2">
                      <v-btn
                        class="scheduleApptListItem"
                        x-large
                        outlined
                        style="color: #0079c6"
                        width="100%"
                        elevation="3">
                        <v-avatar
                          size="36"
                          color="#0079C6"
                          style="color: white"
                          class="mr-3">
                          {{item.firstName[0] + item.lastName[0]}}
                        </v-avatar>
                        <v-list-item-title style="color: #0079c6">
                          {{`${item.firstName} ${item.lastName}`}}
                        </v-list-item-title>
                      </v-btn>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-card>
          </v-stepper-content>
          <!-- End Stepper Step 1 -->

          <!-- Start Stepper Step 2 -->
          <v-stepper-step
            :complete="currentStep > 2"
            step="2"
            @click="setStepIfBeyond(2)">
            What is the reason for the appointment?
            <span v-if="currentStep > 2"> - {{ selectedAppointmentType.name }} <span style="color: #aaa"> ({{ selectedAppointmentType.appointmentLength }} minutes)</span></span>
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-card>
              <v-progress-circular
                v-if="apptTypeLoading"
                indeterminate
                color="primary"
                style="height: 50px !important; margin: auto; width: 100%"
              ></v-progress-circular>
              <div v-if="!apptTypeLoading">
                <v-list flat>
                  <v-list-item-group >
                    <v-list-item
                      v-for="(item, i) in appointmentTypes"
                      :key="i"
                      @click="selectAppointmentType(item)"
                      class="pb-2"
                    >
                      <v-btn
                        class="scheduleApptListItem"
                        x-large
                        outlined
                        style="color: #0079c6"
                        width="100%"
                        elevation="3">
                        <v-list-item-title style="color: #0079c6">
                          {{ item.name }} ({{ item.appointmentLength }} minutes)
                        </v-list-item-title>
                      </v-btn>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-card>
          </v-stepper-content>
          <!-- End Stepper Step 2 -->

          <!-- Start Stepper Step 3 -->
          <v-stepper-step
            :complete="currentStep > 3"
            step="3"
            @click="setStepIfBeyond(3)">
            Find an appointment
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-card>
              <v-progress-circular
                v-if="availabilityLoading"
                indeterminate
                color="primary"
                style="height: 50px !important; margin: auto; width: 100%" />
            </v-card>
            <v-card v-if="!availabilityLoading">
              <v-row>
                <v-col cols="2">
                  Preferred Provider
                  <v-list flat>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(item, i) in providers"
                        :key="i"
                        @click="selectProvider(item)"
                        class="pb-2">
                        <v-btn
                          class="scheduleApptListItem"
                          x-large
                          outlined
                          :style="{
                            color: '#0079c6',
                            padding: '10px',
                            height: 'auto',
                            'border-width': item.id === selectedProvider?.id ? '3px' : '1px',
                          }"
                          width="100%"
                          elevation="3">
                          <v-list-item-title style="color: #0079c6">
                            <v-avatar style="display: inline-flex">
                              <img :src="item.imageUrl" alt="Avatar">
                            </v-avatar>
                          <div style="display: block; padding-top: 10px;">{{item.firstName}} {{item.lastName}}</div>
                          </v-list-item-title>
                        </v-btn>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
                <v-col cols="3">
                  <v-date-picker
                    v-model="datePickerSelection"
                    style="border: 1px solid #414a5b; margin-top:2px; max-width:300px; height: auto !important;"
                    class="pb-3"
                    :allowed-dates="isDateAllowed"
                    @change="dateSelected"
                    @click:month="getAvailabilityForAppointmentType"
                    ref="datepicker" />
                </v-col>
                <v-col cols="6">
                  <span v-if="slotsForDate">Select a time slot for your appointment</span>
                  <v-row class="mt-1" v-if="slotsForDate">
                    <v-col
                      v-for="(item, i) in slotsForDate"
                      :key="i"
                      cols="2">
                      <v-btn
                        outlined
                        style="color: #0079c6"
                        @click="selectTime(item)">
                        {{item.timeString}}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-stepper-content>
          <!-- End Stepper Step 3 -->

          <!-- Start Stepper Step 4 -->
          <v-stepper-step step="4">Book your appointment</v-stepper-step>
          <v-stepper-content step="4">
            <v-card v-if="currentStep >= 4">
              <h4>Appointment Type</h4>
              <span>{{ selectedAppointmentType.name }}</span>
              <span style="color: #aaa"> ({{ selectedAppointmentType.appointmentLength }} minutes)</span>

              <h4>Date &amp; Time</h4>
              <span>{{ selectedDate.toDateString() }} at {{ selectedTime }}</span>

              <h4>Provider</h4>
              <span>{{selectedProvider.firstName}} {{selectedProvider.lastName}}</span>

              <br />

              <v-row class="mt-1" v-if="slotsForDate">
                <v-col cols="3">
                  <v-card outlined class="pa-2">
                    <h4>Add an additional comment for appointment?</h4>
                    <v-text-field v-model="additionalNote" />
                  </v-card>
                </v-col>
              </v-row>

              <br />

              <v-btn
                outlined
                x-large
                class="mt-2"
                style="color: #0079c6"
                @click="bookAppointment()">
                Book!
              </v-btn>
            </v-card>
          </v-stepper-content>
          <!-- End Stepper Step 4 -->
        </v-stepper>
      </template>
      <!-- End Vertical Stepper Scheduler -->
    </v-container>

    <!-- Start Schedule Success Dialog -->
    <v-dialog v-model="dialog" width="400">
      <v-card class="d-flex justify-center flex-row my-6">
        <div class="justify-center align-center" style="width: 50%">
          <h4>Your appointment has been scheduled!</h4>
          <v-card-actions>
            <v-btn
              color="#414a5b"
              style="background-color: #b0d35e"
              text
              elevation="3"
              @click="acknowledgeConfirm()">
              Ok
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <!-- End Schedule Success Dialog -->

  </v-app>
</template>
<script>
import ServiceMessage from '../../components/ServiceMessage';
import DataService from '../../../_services/DataService';

export default {
  name: 'PatientSchedule',
  props: ['template_props'],
  components: {
    ServiceMessage
  },
  data() {
    return {
      pageName: 'Patient Schedule',
      currentStep: 1,
      dialog: false,
      patientList: [],
      providers: [],
      patientsLoading: true,
      apptTypeLoading: false,
      providerLoading: true,
      datePickerSelection: new Date().toISOString().substring(0, 7),
      selectedProvider: null,
      appointmentTypes: [],
      appointmentType: '',
      attemptedAppointmentLoad: false,
      availableAppointmentSlots: [],
      slotsForDate: [],
      availabilityLoading: false,
      selectedDate: null,
      selectedTime: '',
      additionalNote: '',
      selectedOperatory: null,
      selectedPatient: null,
      apptSchedulingLoading: false,
      timeIncrementPreference: 10,
    };
  },
  methods: {
    setStepIfBeyond(step) {
      if (this.currentStep > step) {
        this.currentStep = step;
      }
    },
    async selectProvider(item) {
      this.selectedProvider = item;
      this.getAppointmentSlotsForCurrentDateSelection();
    },
    async getAppointmentSlots() {
      this.appointmentSlotsLoading = true;
      let params = {};

      params['ProvNum'] = this.selectedDentist;
      params['procedureCode'] = this.appointmentType.ProcCode;
      params['date'] = this.picker;
      
      // planned appointment if it has these two
      params['aptNum'] = this.appointmentType.AptNum;
      params['ProcTime'] = this.appointmentType.ProcTime;

      const response = await DataService.get_appointment_slots(params);
      if (response.status != 200) {
        let alert = {
          color: 'warning',
          text: 'Something went wrong!',
        };
        this.$root.$emit('raise_alert', alert);
        return
      }
      this.appointmentSlots = response.data.data;
      this.countAppointmentSlots = this.appointmentSlots.length;
      this.appointmentSlotsLoading = false;
    },
    async getPatientFamily() {
      this.patientsLoading = true;
      this.patientList = [];
      const response = await DataService.getPatientFamily();
      if (response.status === 200) {
        this.patientList = response.data;

        if (this.patientList.length === 1) {
          // just the one patient, no need to show a list
          this.selectPatient(this.patientList[0]);
        }
      } else {
        this.$root.$emit('raise_alert', {
          color: 'info',
          text: 'There was an error getting family info',
        });
      }

      this.patientsLoading = false;
    },
    async getAppointmentTypes() {
      this.apptTypeLoading = true;
      const response = await DataService.getSchedulableAppointmentTypes();

      this.appointmentTypes = response.data;
      this.apptTypeLoading = false;
    },
    async selectPatient(item) {
      this.currentStep = 2;
      this.selectedPatient = item;
      await this.getAppointmentTypes();
    },
    async selectAppointmentType(item) {
      this.currentStep = 3;
      this.selectedAppointmentType = item;
      this.getAvailabilityForAppointmentType();
    },
    async getAvailabilityForAppointmentType() {
      this.availabilityLoading = true;

      let startDate = new Date();
      // we've already loaded data; this load is being caused by a time selection change
      if (this.attemptedAppointmentLoad) {
        startDate = new Date(this.$refs.datepicker.tableYear, this.$refs.datepicker.tableMonth, 1);
      }

      this.attemptedAppointmentLoad = true;

      const endDate = new Date(startDate);
      endDate.setMonth(startDate.getMonth() + 1);

      const clientId = DataService.getClientId();
      const startDateString = startDate.toISOString().substring(0, 10);
      const endDateString = endDate.toISOString().substring(0, 10);
      let response;
      if (this.selectedAppointmentType.id) {
        response = await DataService.getAvailabilityForAppointmentType(clientId, this.selectedAppointmentType.id, startDateString, endDateString);
      } else {
        response = await DataService.getAvailabilityForCustomAppointment(clientId, this.selectedProvider.id, this.selectedAppointmentType.appointmentLength, startDateString, endDateString, this.selectedAppointmentType.pattern);
      }

      this.timeIncrementPreference = response.data.timeIncrementPreference;
      this.providers = response.data.providers;

      for (const provider of this.providers) {
        provider.imageUrl = `${window.acrew.apiUrl}/clients/${clientId}/providers/${provider.id}/image`;
      }

      if (this.providers.length > 1) {
        this.providers.unshift({ id: -1, firstName: 'Any', imageUrl: '/static/img/generic_person.png' });
      }

      this.selectedProvider = this.providers[0];

      this.availableAppointmentSlots = response.data.slots;

      if (this.availableAppointmentSlots.length) {
        // set to the first date (today typically)
        const firstDateString = this.availableAppointmentSlots[0].start.substring(0, 10);
        const [year, month, day] = firstDateString.split('-');
        this.selectedDate = new Date(Number(year), Number(month) - 1, Number(day));
        this.datePickerSelection = firstDateString;
        this.getAppointmentSlotsForCurrentDateSelection();
      } else {
        this.selectedDate = startDate;
        this.datePickerSelection = startDate.toISOString().substring(0, 10);
      }

      this.availabilityLoading = false;

      this.bindMonthClickHandlers();
    },
    getAppointmentSlotsForCurrentDateSelection() {
      const appointmentSlotsForDate = this.availableAppointmentSlots.filter((slot) => {
        if (this.selectedProvider?.id > 0 && slot.providerId !== this.selectedProvider.id) {
          return false;
        }

        const [year, month, day] = slot.start.substring(0, 10).split('-');
        const slotDate = new Date(Number(year), Number(month) - 1, Number(day));
        return slotDate.getTime() === this.selectedDate.getTime();
      });

      const appointmentTypeLength = this.selectedAppointmentType.appointmentLength;

      const uniqueSlots = {};
      for (const slot of appointmentSlotsForDate) {
        const endDate = new Date(slot.end);

        let dateCopy;
        let loop = 0; // track position and also prevent overflow

        do {
          dateCopy = new Date(slot.start);
          dateCopy.setMinutes(dateCopy.getMinutes() + this.timeIncrementPreference * loop);

          const slotTime = dateCopy.getTime();
          if (!uniqueSlots[slotTime]) {
            uniqueSlots[slotTime] = {
              time: slotTime,
              providerId: slot.providerId,
              operatoryId: slot.operatoryId,
            };
          }
        } while (++loop < 50 && dateCopy.setMinutes(dateCopy.getMinutes() + appointmentTypeLength) < endDate);
      }

      const anHourFromNow = new Date();
      anHourFromNow.setMinutes(anHourFromNow.getMinutes() + 60);

      this.slotsForDate = [];
      const timeSortedKeys = Object.keys(uniqueSlots).sort();
      for (const key of timeSortedKeys) {
        const slot = new Date(uniqueSlots[key].time);

        // don't allow past scheduling, and no sooner than an hour from now
        if (slot.getTime() < anHourFromNow) {
          continue;
        }

        const hourPiece = slot.getHours() % 12 || '12';
        const minutePiece = slot.getMinutes().toString().padStart(2, '0');
        const amPm = slot.getHours() >= 12 ? 'PM' : 'AM';

        this.slotsForDate.push({
          ...uniqueSlots[key],
          timeString: `${hourPiece}:${minutePiece} ${amPm}`,
        });
      }
    },
    isDateAllowed(value) {
      if (!this.availableAppointmentSlots.length) {
        return false;
      }

      const [year, month, day] = value.split('-');
      const date = new Date(Number(year), Number(month) - 1, Number(day));
      return this.availableAppointmentSlots.some((slot) => {
        if (this.selectedProvider?.id > 0 && slot.providerId !== this.selectedProvider.id) {
          return false;
        }

        const [loopYear, loopMonth, loopDay] = slot.start.substring(0, 10).split('-');
        const slotDate = new Date(Number(loopYear), Number(loopMonth) - 1, Number(loopDay));
        return slotDate.getTime() === date.getTime();
      });
    },
    dateSelected(date) {
      const [year, month, day] = date.split('-');
      this.selectedDate = new Date(Number(year), Number(month) - 1, Number(day));
      this.getAppointmentSlotsForCurrentDateSelection();
    },
    selectTime(item) {
      this.selectedTime = item.timeString;
      this.selectedProvider = this.providers.find((prov) => prov.id === item.providerId);
      this.selectedOperatory = item.operatoryId;

      this.currentStep = 4;
    },
    async bookAppointment() {
      this.apptSchedulingLoading = true;

      const [, hour, minute, amPm] = this.selectedTime.match(/(\d+):(\d+) ([AP]M)/);
      let normalizedHour = Number(hour) + (amPm === 'PM' ? 12 : 0);
      if (!(normalizedHour % 12)) {
        normalizedHour -= 12;
      }

      const appointmentDateString = `${this.selectedDate.getFullYear()}-${(this.selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${(this.selectedDate.getDate()).toString().padStart(2, '0')}T${normalizedHour.toString().padStart(2, '0')}:${minute.padStart(2, '0')}:00.000`; 

      const body = {
        providerId: this.selectedProvider.id,
        operatoryId: this.selectedOperatory,
        appointmentDate: appointmentDateString,
        appointmentTypeId: this.selectedAppointmentType.id,
        plannedAppointmentId: this.$route.query.plannedAppointmentId,
        codes: this.$route.query.codes ? JSON.parse(this.$route.query.codes) : undefined,
        additionalNote: this.additionalNote,
        appointmentLength: this.selectedAppointmentType.appointmentLength,
      };

      const resp = await DataService.scheduleAppointmentForPatient(body);

      if (resp.status == 201) {
        this.dialog = true;
      } 
      else {
        this.$root.$emit('raise_alert', {
          color: 'info',
          text: 'Something went wrong. Please try again later.',
        });
      }

      this.apptSchedulingLoading = false;
    },
    async acknowledgeConfirm() {
      this.dialog = false;

      this.selectedPatient = [];
      this.appointmentType = [];
      this.selectedDentist = [];
      this.selectedAppointmentSlot = [];
      this.currentStep = 1;

      this.this.$router.push({
        name: 'Dashboard',
      });
    },
    bindMonthClickHandlers() {
      // vuetify 2 doesn't offer us a way to capture month change via the prev/next button so... guess we're doing that ourselves
      // have to do wait for render and redo this on each load too weeeee
      setTimeout(() => {
        const prevBtn = this.$refs.datepicker.$el.querySelector('.v-btn[aria-label="Previous month"]')
        prevBtn.addEventListener('click', this.getAvailabilityForAppointmentType);
        const nextBtn = this.$refs.datepicker.$el.querySelector('.v-btn[aria-label="Next month"]')
        nextBtn.addEventListener('click', this.getAvailabilityForAppointmentType);
      }, 200);
    },
  },
  async mounted() {
    await this.getPatientFamily();

    if (this.$route.query.patientId && this.patientList.length > 1) {
      const patient = this.patientList.find((pat) => pat.id === Number(this.$route.query.patientId));
      if (patient) {
        this.selectPatient(patient);
      }
    }

    if (this.$route.query.providerId && this.$route.query.duration) {
      this.currentStep = 3;
      this.selectedProvider = { id: this.$route.query.providerId };
      this.selectedAppointmentType = { name: this.$route.query.description, pattern: this.$route.query.pattern, appointmentLength: Number(this.$route.query.duration) };

      await this.getAvailabilityForAppointmentType();
    }
  },
};
</script>

<!-- Cannot set style on v-stepper__label if style is scoped -->
<style>
.v-date-picker-table {
    height: auto !important;
}

.scheduleApptListItem {
  display: unset;
  margin-top: 0px;
  margin-bottom: 0px;
}
.v-stepper--vertical .v-stepper__step {
    padding: 24px 24px 16px 0px;
}
div .v-stepper__label {
  width: -webkit-fill-available !important;
  margin-right: 36px;
  min-width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  min-width: -moz-available;
}
.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
    margin-left: 12px;
}
div .v-list .v-sheet .theme--light .v-list--flat {
    margin-right: 36px;
}
div .v-picker__body {
  width: unset !important;
}
.mb-12 .v-card .v-sheet .theme--light {
  margin-bottom:0px;
}

</style>

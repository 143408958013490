<template>
  <v-layout row wrap class="no-toppad" no-gutters text-xs-right>
    <v-container>
      <v-skeleton-loader
        v-if="loading"
        type="table-heading"
        :loading="loading" />

      <h1 class="dashtitle" v-if="!loading">Upcoming Appointments</h1>

      <v-data-table
        :headers="headers"
        :items="upcomingAppointments"
        :expanded.sync="expanded"
        item-key="aptNum"
        disable-pagination
        hide-default-footer
        class="elevation-0 text-h5"
        v-if="!loading" />

      <h1 class="dashtitle" v-if="!loading">Past Appointments</h1>
      <v-data-table
        :headers="headers"
        :items="pastAppointments"
        :expanded.sync="expanded"
        item-key="aptNum"
        disable-pagination
        hide-default-footer
        class="elevation-0 text-h5"
        v-if="!loading" />

      <!-- Add New Appointment FAB btn  -->
      <v-container>
        <v-row>
          <v-card
            color="transparent"
            style="position: fixed; bottom: 100px; right: 10px">
            <v-card-text>
              <!-- speed dial here is unnecessary but might be needed later -->
              <v-speed-dial>
                <template v-slot:activator>
                  <v-btn
                    color="appPrimaryBtnColor"
                    class="animFabBtn"
                    dark
                    absolute
                    bottom
                    right
                    elevation="6"
                    fab
                    @click="$router.push('/patient_schedule')">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
              </v-speed-dial>
            </v-card-text>
          </v-card>
        </v-row>
      </v-container>
    </v-container>
  </v-layout>
</template>

<script>
import DataService from '../../../_services/DataService';
import Utils from '../../../src/utils.js';

export default {
  name: 'ScheduledAppointments',
  data() {
    return {
      loading: true,
      pageName: 'Appointments',
      pageIcon: 'mdi-calendar-month',
      form: {},
      upcomingAppointments: [],
      pastAppointments: [],
      expanded: [],
      headers: [
        {
          text: 'Patient',
          value: 'patientName',
          sortable: false,
          width: '300px',
        },
        {
          text: 'Provider',
          value: 'providerName',
          sortable: false,
          width: '300px',
        },
        {
          text: 'Date & Time',
          value: 'dateAndTime',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    async getScheduleData() {
      this.loading = true;
      const apptResponse = await DataService.getPatientAppointments()
      if (apptResponse.status === 200) {
        const mappedData = apptResponse.data.map(this.getDisplayData);

        const now = new Date();
        this.upcomingAppointments = [];
        this.pastAppointments = [];
        for (const row of mappedData) {
          if (new Date(row.rawDate) > now) {
            this.upcomingAppointments.push(row);
          } else {
            this.pastAppointments.push(row);
          }
        }

        // upcoming; earliest first
        this.upcomingAppointments.sort((a, b) => a.rawDate > b.rawDate ? 1 : -1);
        // past; most recent first
        this.pastAppointments.sort((a, b) => a.rawDate > b.rawDate ? -1 : 1);

        setTimeout(() => (this.loading = false), 100);
      }
    },
    getDisplayData(d) {
      return {
        rawDate: d.date,
        dateAndTime: `${Utils.formatDate(d.date)}\u00A0${Utils.formatTimeFromDate(d.date)}`,
        patientName: `${d.patient.firstName} ${d.patient.lastName}`,
        providerName: `${d.provider.firstName} ${d.provider.lastName}`,
      };
    },
  },
  created() {},
  async mounted() {
    await this.getScheduleData();
  },
};
</script>
<style>
.animFabBtn:hover {
  width: 220px;
  border-radius: 20px;
  padding: 0 20px 0 20px;
  transition-delay: 0.5s;
}
.animFabBtn:hover::after {
  content: 'Add New Appointment';
  padding-left: 5px;
  box-shadow: none;
  text-shadow: none;
}
td, tr {
  word-break: break-word;
  font-size: 18px !important;
}
.v-icon {
  font-size: 30px !important;
}

@media screen and (max-width: 600px) {
  td, tr {
    font-size: 16px !important;
  }
  .v-icon {
    font-size: 30px !important;
  }
}
</style>

<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="dashtitle">
          <v-icon>{{ this.pageIcon }}</v-icon> <span>{{ this.pageName }}</span>
        </h1>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="mt-1 text-right"> </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="messages"
      :rowClick="true"
      disable-pagination
      disable-sort
      hide-default-footer>
      <template v-slot:item="{item}">
        <tr @click="openDetails(item)">
          <td class="gridCell twoHundredWide">
            {{ item.patientFirstName }} {{ item.patientLastName }}
          </td>
          <td class="gridCell oneFiftyWide">
            <v-icon>
              {{ item.outgoing ? 'mdi mdi-email-fast-outline' : 'mdi mdi-email-arrow-left-outline' }}
            </v-icon>
            {{ item.outgoing ? 'Sent' : 'Received' }}
          </td>
          <td
            class="gridCell"
            :style="{ 'font-weight': !item.outgoing && item.unread ? 'bold' : '' }">
            {{ item.message }}
          </td>

          <td class="gridCell twoHundredWide">
            {{ Utils.formatDate(item.date) }} {{ Utils.formatTimeFromDate(item.date) }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-row class="mx-0 mt-3 d-flex d-flex justify-space-between">
      <v-col cols="4" sm="2" lg="1">
        <v-select
          class="dg-page-size"
          v-model="pageSize"
          :items="pageSizes"
          label="Items per Page"
          @change="handlePageSizeChange" />
      </v-col>
      <v-col>
        <v-pagination
          v-model="page"
          :length="totalPages"
          total-visible="5"
          next-icon="mdi-menu-right"
          prev-icon="mdi-menu-left"
          @input="handlePageChange" />
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessageDialog"
      persistent
      width="500px">
      <v-card>
        <v-card-title>
          Message Details
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <h4>Patient</h4>
              <span @click="goToPatient(selectedMessage.patientId)">
                {{ selectedMessage.patientFirstName }} {{ selectedMessage.patientLastName }} / {{ selectedMessage.patientPhoneNumber }}
              </span>
            </v-col>
            <v-col cols="6">
              <h4>Date</h4>
              <span>{{ Utils.formatDate(selectedMessage.date) }} {{ Utils.formatTimeFromDate(selectedMessage.date) }}</span>
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col>
              <h4>Message</h4>
              <span>{{ selectedMessage.message }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="appSecondaryBtnColor white--text"
            @click="showMessageDialog = false"
            dense>
            Close
          </v-btn>
          <v-btn @click="goToPatient(selectedMessage.patientId)">View Patient</v-btn>
          <v-btn
            v-if="!selectedMessage.outgoing"
            class="appPrimaryBtnColor white--text"
            @click="toggleReadStatus()"
            dense>
            Mark {{ selectedMessage.unread ? 'Read' : 'Unread' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DataService from '../../../_services/DataService';
import Utils from '../../../src/utils.js';

export default {
  name: 'SMS',
  data() {
    return {
      pageName: 'SMS',
      pageIcon: 'mdi-message-text',
      isLoaded: false,
      showMessageDialog: false,
      selectedMessage: {},
      messages: [],
      page: 1,
      totalPages: 1,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100, 200],
      headers: [{ text: 'Patient' }, { text: 'Sent/Received' }, { text: 'Message' }, { text: 'Date' }],
      Utils: Utils,
    };
  },
  computed: {
    activeCols: function () {
      return this.headers.filter(function (u) {
        return u.active == 1;
      });
    },
  },
  methods: {
    async openDetails(message) {
      this.selectedMessage = message;

      if (message.unread) {
        await this.toggleReadStatus(message);
      }

      this.showMessageDialog = true;
    },
    async fetchMessages() {
      const params = {
        size: this.pageSize,
        page: this.page,
      };

      const response = await DataService.fetchMessages(params);
      if (response.status === 200) {
        this.messages = response.data.messages;
        this.totalPages = response.data.pageCount;
      } else {
        this.$root.$emit('raise_alert', Utils.setAlert('fail'));
      }
    },
    async handlePageChange(value) {
      this.page = value;
      await this.fetchMessages();
    },
    handleRowClick(value) {
      if (this.clickable == true) {
        this.$parent.handleRowClick(value);
      }
    },
    goToPatient(patientId) {
      this.$router.push({
        name: 'Admin',
        query: { tab: 'patient management', patientId },
      });
    },
    async handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      await this.fetchMessages();
    },
    async toggleReadStatus() {
      let response;

      if (this.selectedMessage.unread) {
        response = await DataService.markSmsAsRead(this.selectedMessage.id);
      } else {
        response = await DataService.markSmsAsUnread(this.selectedMessage.id);
      }

      if (response.status !== 200) {
        this.$root.$emit('raise_alert', Utils.setAlert('fail'));
      } else {
        this.selectedMessage.unread = !this.selectedMessage.unread;
      }

      await this.fetchMessages();
    },
  },
  async mounted() {
    await this.fetchMessages();
  },
};
</script>
<style>
@media (min-width: 960px) {
  .v-dialog {
    width: 100%;
  }
}

@media (min-width: 1264px) {
  .v-dialog {
    width: 50%;
  }
}

@media (min-width: 1904px) {
  .v-dialog {
    width: 50%;
  }
}

.gridCell {
  font-size: medium !important;
  height: 12px !important;
  cursor: pointer;
}

.oneFiftyWide {
  width: 150px;
}

.twoHundredWide {
  width: 200px;
}
</style>

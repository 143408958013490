<template>
  <v-container class="pa-0">
    <!-- Insurance Title -->
    <v-row>
      <v-col class="d-flex justify-center">
        <h2 class="secondary--text">Insurance</h2>
      </v-col>
    </v-row>

    <v-row v-if="this.patient">
      <v-col class="secondary white--text d-flex justify-center">
        {{ patient.lastName }}, {{ patient.firstName }} ({{patient.birthDate }})
      </v-col>
    </v-row>

    <v-form ref="form" v-if="this.patient">
      <v-row>
        <v-col cols="12" class="px-3">
          <v-card class="d-flex align-center justify-space-between">
            <!-- Do you have Insurance? Card Title and Yes/No Button -->
            <v-card-title class="title pl-0"> Do you have Insurance? </v-card-title>
            <v-btn-toggle
              v-model="form.haveInsurance"
              mandatory
              class="white--text">
              <v-btn small> No </v-btn>
              <v-btn small> Yes </v-btn>
            </v-btn-toggle>
          </v-card>
        </v-col>
        <template v-if="form.haveInsurance">
          <v-col>
            To change insurance, or to add a secondary insurance provider, please contact office number {{ officePhone }}.
          </v-col>
          <v-col cols="12">
            <!-- Upload Insurance Documents header text -->
            Upload Insurance Documents
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-file-input v-show="false"
                  ref="fileFront"
                  @change="previewAndConvert('front')"
                  v-model="imageFront"
                  >
                </v-file-input>
                <!-- Upload Dental Insurance (Front), Upload Dental Insurance (Back) -->
                <v-btn block small class="secondary" @click="click('front')">
                  <v-icon small class="pr-3">mdi-upload</v-icon>
                  Upload Dental Insurance Card (Front)
                </v-btn>
                <v-img :src="urlFront"></v-img>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-file-input v-show="false"
                  ref="fileBack"
                  @change="previewAndConvert('back')"
                  v-model="imageBack"
                  >
                </v-file-input>
                <v-btn block small class="secondary" @click="click('back')">
                  <v-icon small class="pr-3">mdi-upload</v-icon>
                  Upload Dental Insurance Card (Back)
                </v-btn>
                <v-img :src="urlBack"></v-img>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6" lg="3" class="pb-0">
            <!-- v-select policy holder relationship to patient (required) -->
            <v-select
              v-model="form.policyHolderRelationshipToPatient"
              :items="relationshipToPatientItems"
              :rules="[rules.required]"
              outlined
              dense>
              <template v-slot:label>
                Policy Holder Relationship to Patient
                <span class="red--text">*</span>
              </template>
            </v-select>
          </v-col>

          <v-col cols="12" md="6" lg="3" class="pb-0">
            <!-- Text-field Policy Holder Birth Date (mm/dd/yyyy) (required) -->
            <v-text-field
              v-model="form.policyHolderBirthDate"
              type="date"
              class="required"
              :rules="[rules.required]"
              outlined
              dense>
              <template v-slot:label>
                Policy Holder Birth Date (mm/dd/yyyy)<span class="red--text"> *</span>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6" lg="3" class="pb-0">
            <!-- Text-field Subscriber ID (required) -->
            <v-text-field
              v-model="form.subscriberId"
              :rules="[rules.required, rules.max25]"
              outlined
              dense>
              <template v-slot:label>
                Subscriber ID<span class="red--text"> *</span>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6" lg="3" class="pb-0">
            <!-- Text field Group Number -->
            <v-text-field
              v-model="form.groupNumber"
              label="Group Number"
              outlined
              dense></v-text-field>
          </v-col>

          <v-col cols="12" md="6" lg="3" class="pb-0">
            <!-- Text field Policy Holder Social Security Number -->
            <v-text-field
              v-model="form.policyHolderSocialSecurityNumber"
              label="Policy Holder Social Security Number"
              outlined
              dense></v-text-field>
          </v-col>

          <v-col cols="12" md="6" lg="3" class="pb-0">
            <!-- Text field First name of policy holder (required) -->
            <v-text-field
              v-model="form.firstNameOfPolicyHolder"
              :rules="[rules.required, rules.max50]"
              outlined
              dense>
              <template v-slot:label>
                First Name of Policy Holder<span class="red--text"> *</span>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6" lg="3" class="pb-0">
            <!-- Text field Last Name of policy holder (required) -->
            <v-text-field
              v-model="form.lastNameOfPolicyHolder"
              :rules="[rules.required, rules.max50]"
              outlined
              dense>
              <template v-slot:label>
                Last Name of Policy Holder<span class="red--text"> *</span>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6" lg="3" class="pb-0">
            <!-- Text Field Employer Name -->
            <v-text-field
              v-model="form.employerName"
              label="Employer Name"
              outlined
              dense></v-text-field>
          </v-col>

          <v-col cols="12" md="6" lg="3" class="pb-0">
            <!-- v-select Insurance Company (required) -->
            <v-text-field
              v-model="form.insuranceCompany"
              :rules="[rules.required]"
              outlined
              dense>
              <template v-slot:label>
                Insurance Company<span class="red--text"> *</span>
              </template>
            </v-text-field>
          </v-col>
        </template>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import DataService from '../../../_services/DataService';
import Utils from '../../utils';
import ValidateService from '../../../_services/ValidateService';
import { jsPDF } from "jspdf";

export default {
  name: 'InsuranceForm',
  props: ['patient'],
  data() {
    return {
      officePhone: '',
      form: {
        id: 2,
        haveInsurance: true,
        // upload document buttons
        policyHolderRelationshipToPatient: '',
        policyHolderBirthDate: '',
        subscriberId: '',
        groupNumber: '',
        policyHolderSocialSecurityNumber: '',
        firstNameOfPolicyHolder: '',
        lastNameOfPolicyHolder: '',
        employerName: '',
        insuranceCompany: '',
      },
      relationshipToPatientItems: ['Self', 'Spouse', 'Child'],
      rules: ValidateService.validators,
      urlFront: null,
      urlBack: null,
      imageFront: null,
      imageBack: null,
      frontBase64: '',
      backBase64: '',
    };
  },
  methods: {
    click(side) {
      if (side == 'front') {
        this.$refs.fileFront.$refs.input.click()
      } else if (side == 'back') {
        this.$refs.fileBack.$refs.input.click()
      }
    },
    previewAndConvert(side) {

      let currentImage;

      // generate preview image
      if (side == "front") {
        this.urlFront = URL.createObjectURL(this.imageFront);
        currentImage = this.imageFront
      } else if (side == "back") {
        this.urlBack = URL.createObjectURL(this.imageBack);
        currentImage = this.imageBack
      }

      // generate base64
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(currentImage);
        reader.onload = () => {
          resolve(reader.result);
          if (side == 'front') {
            this.frontBase64 = reader.result;
          } else if(side == 'back') {
            this.backBase64 = reader.result;
          }
        }
        reader.onerror = (error) => reject(error);
      });
    },
    async validate() {
      return this.$refs.form.validate();
    },
    async sendForm(base64PDF) {
      this.form.base64PDF = base64PDF;

      const response = await DataService.postNewPatRegistration(this.form, this.patient.id);
      if (response.status === 200) {
        this.$emit('goToNextForm');
        this.$root.$emit('raise_alert', { color: 'success', text: 'Your entry has been recorded.' });
      } else {
        this.$emit('submitFailure');
        this.$root.$emit('raise_alert', { color: 'error', text: 'We were unable to save your insurance info, please try again or contact our team if the issue persists' });
      }
    },
    async generatePDF() {
      const doc = new jsPDF();
      doc.setFontSize(10).text(10, 20, `Do you have Insurance: ${this.form.haveInsurance == true ? 'Yes' : 'No'}`).setFont(undefined, 'bold');
      if (this.form.haveInsurance){
        doc.text(10, 25, 'Insurance').setFont(undefined, 'normal');
        doc.text(10, 30, `Policy Holder Relationship to Patient: ${this.form.policyHolderRelationshipToPatient || ''}`);
        doc.text(10, 35, `Policy Holder Birth Date: ${this.form.policyHolderBirthDate || ''}`);
        doc.text(10, 40, `Subscriber ID: ${this.form.subscriberId || ''}`);
        doc.text(10, 45, `Group Number: ${this.form.groupNumber || ''}`);
        doc.text(10, 50, `Policy Holder Social Security Number: ${this.form.policyHolderSocialSecurityNumber || ''}`);
        doc.text(10, 55, `First Name of Policy Holder: ${this.form.firstNameOfPolicyHolder}`);
        doc.text(10, 60, `Last Name of Policy Holder: ${this.form.lastNameOfPolicyHolder || ''}`);
        doc.text(10, 65, `Employer Name: ${this.form.employerName || ''}`);
        doc.text(10, 70, `Insurance Company: ${this.form.insuranceCompany || ''}`);
        doc.text(10, 75, `Date Signed: ${new Date().toISOString()}`);
        doc.addImage(this.frontBase64, 'JPEG', 10, 80, 140, 80, undefined, 'FAST');
        doc.addImage(this.backBase64, 'JPEG', 10, 165, 140, 80, undefined, 'FAST');
      }

      await this.sendForm(doc.output('datauristring').split(',')[1])
    },
  },
  async mounted() {
    const { phone } = await DataService.getClientDetails();
    this.officePhone = Utils.formatPhone(phone);
  },
};
</script>
<style scoped>
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 1;
  color: #1182d5;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  background-color: white;
}
.v-sheet.v-list:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%),
    0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
}
</style>

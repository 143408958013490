<template>
  <v-card class="pb-5 mx-3 mx-sm-5">
    <v-row>
      <v-col class="pt-5">
        <h1 class="dashtitle">{{ this.pageName }}</h1>
      </v-col>
      <v-col class="mt-1 text-right"> </v-col>
    </v-row>
    <v-card class="mx-2">
      <v-skeleton-loader
        v-if="loading"
        type="table-heading"
        :loading="loading" />
      <!-- datagrid -->
      <v-data-table
        v-if="!loading"
        class="elevation-1"
        item-key="id"
        :headers="patientHeaders"
        :items="family"
        :loading="loading"
        :loading-text="gridLoadMessage"
        :expanded.sync="expanded"
        show-expand
        :single-expand="false"
        hide-default-footer
        disable-pagination>
        <template v-slot:[`item.name`]="{item}">
          <span>{{ item.firstName }} {{ item.lastName }}</span>
        </template>
        <template v-slot:[`item.balance`]="{item}">
          <span>{{ formatMoney(balanceData.balancesByPatient[item.id]) }}</span>
        </template>
        <template v-slot:expanded-item="{item}">
          <td
            :colspan="patientHeaders.length"
            v-if="breakdownByFamilyMember[item.id] == ''">
            No charges have been posted for this patient.
          </td>
          <td
            v-if="breakdownByFamilyMember[item.id] != ''"
            :colspan="patientHeaders.length">
            <v-card
              class="my-1 ml-md-15"
              style="overflow-y: auto"
              max-height="500px"
              elevation="0"
              outlined
              rounded>
              <v-data-table
                item-key="transactionId"
                :headers="billHeaders"
                :items-per-page="5"
                :items="breakdownByFamilyMember[item.id]"
                dense>
                <template v-slot:[`item.date`]="{item}">
                  <span>{{ item.date && formatDate(item.date) }}</span>
                </template>
                <template v-slot:[`item.reference`]="{item}">
                  <span>{{ item.reference }}</span>
                </template>
                <template v-slot:[`item.type`]="{item}">
                  <span>{{ item.type }}</span>
                </template>
                <template v-slot:[`item.amount`]="{item}">
                  <span>{{ formatMoney(item.amount) }}</span>
                </template>
              </v-data-table>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <v-card class="mx-10 mt-8">
      <v-row class="d-flex justify-end">
        <p class="font-weight-medium mr-2">Account Balance:</p>
        {{ formatMoney(balanceData.balance) }}
      </v-row>
    </v-card>
    <v-row class="d-flex justify-end mr-2 mt-2 mb-5">
      <v-btn @click="startPayment()" color="#B0D35E">
        Make a Payment
      </v-btn>
    </v-row>

    <v-dialog v-model="paymentDialog" persistent width="400" max-height="400">
      <v-card outlined class="mx-auto">
        <v-card class="pt-4 pl-4 pb-4">
          <v-card-title class="text-xs-h6 text-md-h5 text-lg-h4">Make a Payment</v-card-title>
          <v-card-text class="text-body-1">
            <iframe
              v-if="paymentUrl"
              id="payconnectFrame"
              name="payconnectFrame"
              :src="paymentUrl"
              frameborder="0"
              style="width: 100%"
              height="350"
              scrolling="no">
            </iframe>
          </v-card-text>
          <v-list disabled class="pa-0">
            <v-list-item
              v-for="(item, i) in paymentMsgArray"
              :key="i"
              class="pa-0 my-0 mr-0 ml-6">
              <div class="pa-2">
                <v-icon>{{ item.icon }}</v-icon>
              </div>
              <v-list-item-title
                class="text-body-1"
                v-text="item.text"></v-list-item-title>
            </v-list-item>
          </v-list>
          <v-card-actions class="pa-4">
            <v-btn
              outlined
              elevation="0"
              color="green darken-1"
              text
              @click="paymentDialog = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog v-model="receiptDialog" persistent width="290" max-height="290">
      <v-card outlined class="pt-4">
        <v-card-text class="text-body-1 font-weight-medium">
          Your payment has been received and applied to your account.
        </v-card-text>
        <v-card-actions>
          <v-btn
            outlined
            color="green darken-1"
            text
            @click="receiptDialog = false">
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import DataService from '../../../_services/DataService';
import Utils from '../../utils.js';

export default {
  name: 'BillPay',
  props: [],
  components: {},
  data() {
    return {
      pageName: 'Bill Pay',
      page: 1,
      itemsPerPage: 5,
      loading: true,
      receiptDialog: false,
      paymentDialog: false,
      paymentMsgArray: [],
      gridLoadMessage: 'Gathering Bills Data..',
      balanceData: {},
      family: [],
      breakdownByFamilyMember: {},
      formatDate: Utils.formatDate,
      formatMoney: Utils.formatCurrency,
      expanded: [],
      paymentUrl: null,
      patientHeaders: [
        {
          text: 'Name',
          value: 'name',
          sortable: false,
          active: true,
          align: 'start',
        },
        {
          text: 'Balance',
          value: 'balance',
          sortable: false,
          active: true,
          align: 'end',
        },
        {
          text: '',
          value: 'data-table-expand',
          sortable: false,
          active: true,
        },
      ],
      billHeaders: [
        {
          text: 'Date',
          value: 'date',
          sortable: false,
          active: true,
        },
        {
          text: 'Reference',
          value: 'reference',
          sortable: false,
          active: true,
        },
        {
          text: 'Type',
          value: 'type',
          sortable: false,
          active: true,
        },
        {
          text: 'Amount',
          value: 'amount',
          sortable: false,
          active: true,
        },
      ],
    };
  },
  methods: {
    goToBilling() {
      this.$router.push({
        name: 'Account',
        query: {activeComponent: 4},
      });
    },
    async startPayment() {
      const paymentUrlResponse = await DataService.getPaymentUrl();
      if (paymentUrlResponse.status === 200) {
        window.addEventListener('message', this.handlePayConnectMessage, false);
        this.paymentUrl = paymentUrlResponse.data.url;
        this.paymentDialog = true;
      }
    },
    async handlePayConnectMessage(evt) {
      console.log('received window message')
      if (evt.data.status === 'ONLOAD') {
        console.log('Payment portal loaded');
        this.paymentDialog = true;
      } else if (evt.data.status === 'SUCCESS') {
        const { referenceId } = evt.data.response;
        const paymentResponse = await DataService.savePayment({ referenceId });
        if (paymentResponse.status === 200) {
          await Promise.all([
            this.getBalanceData(),
            this.getBillingBreakdown(),
          ]);

          this.paymentDialog = false;
          this.paymentUrl = null;
          this.receiptDialog = true;
        }
      }
    },
    async getPatientFamily() {
      const response = await DataService.getPatientFamily();
      if (response.status === 200) {
        const primary = response.data.find((patient) => patient.status === 'Self');
        const rest = response.data.filter((patient) => patient.status !== 'Self');
        rest.sort((a, b) => a.firstName > b.firstName ? 1 : -1); // first name sort because family. diff last names are shrug
        this.family = [primary, ...rest];
      }
    },
    async getBalanceData() {
      const response = await DataService.getPatientAccountBalance()
      if (response.status === 200) {
        this.balanceData = response.data;
      }
    },
    async getBillingBreakdown() {
      const response = await DataService.getPatientAccountBreakdown();
      if (response.status === 200) {
        this.breakdownByFamilyMember = response.data;
      } else {
        this.noPaymentInfoFound = true;
        this.$root.$emit(
          'raise_alert',
          {
            text: 'No billing information found for given user.',
            icon: 'mdi-alert-circle-outline',
            color: 'error',
          },
        );
      }
    },
  },
  async mounted() {
    this.loading = true;

    await this.getPatientFamily();
    await Promise.all([
      this.getBalanceData(),
      this.getBillingBreakdown(),
    ]);

    this.loading = false;
  },
};
</script>
<style scoped></style>

<template>
  <v-card class="pb-2">
    <v-form ref="form">
      <v-row class="d-flex justify-center" v-if="agreementText">
        <v-col cols="12" md="6" class="text-center">
          <h4>
            I have read, understand fully, and agree to the content of this form.
          </h4>
        </v-col>
      </v-row>

      <v-row v-if="!agreementText">
        <v-col cols="12" class="d-flex justify-center pb-0">
          <!-- Disclaimer Title -->
          <h3 class="pt-2">Disclaimer</h3>
        </v-col>
        <v-col cols="12">
          <v-row class="d-flex justify-center">
            <v-col cols="12" sm="6">
              <h6>
                To the best of my knowledge, the questions on this form have been
                accurately answered. I understand that providing incorrect
                information can be dangerous to my (or patient's) health. It is my
                responsibility to inform the dental office of any changes in medical
                status.
              </h6>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center"  v-if="!agreementText">
        <v-col cols="12" sm="6" md="4" lg="3" class="px-0 mr-1">
          <v-select
            v-model="relationshipToPatient"
            :items="relationshipToPatientItems"
            :rules="[rules.required]"
            outlined
            dense>
            <template v-slot:label>
              Relationship to Patient<span class="red--text"> *</span>
            </template>
          </v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3" class="px-0" v-if="relationshipToPatient !== 'Self'">
          <v-text-field
            v-model="nameIfNotPatient"
            :rules="[rules.required, rules.max25]"
            outlined
            dense>
            <template v-slot:label>
              Name if not Patient<span class="red--text"> *</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="!agreementText">
        <v-col class="d-flex justify-center pa-0">
          Patient or Guardian Signature<span class="red--text"> *</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center pa-0">
          <VueSignaturePad
            :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()}}"
            id="signature"
            style="border: 1px solid #a0a0a0"
            width="400px"
            height="200px"
            ref="signaturePad" />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center mb-3">
          <v-btn @click="clearSignature()">Clear</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import DataService from '../../../_services/DataService';
import ValidateService from '../../../_services/ValidateService';
import Vue from 'vue';
import VueSignaturePad from 'vue-signature-pad';
Vue.use(VueSignaturePad);

export default {
  name: 'Disclaimer',
  props: ['agreementText'],
  data() {
    return {
      clientName: '',
      relationshipToPatient: '',
      nameIfNotPatient: '',
      relationshipToPatientItems: ['Self', 'Spouse', 'Parent / Guardian'],
      rules: ValidateService.validators,
    };
  },
  methods: {
    async clearSignature() {
      this.$refs.signaturePad.clearSignature();
    },
    async validate() {
      let valid = this.$refs.form.validate();
      if (!valid) {
        return valid;
      }

      // validate signature
      const { isEmpty } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        // alert not valid
        let alert = {
          color: 'error',
          text: 'Please sign the form.',
        };
        this.$root.$emit('raise_alert', alert);
        return isEmpty;
      }
      return valid;
    },
  },
  async mounted() {
    const { name } = await DataService.getClientDetails();
    this.clientName = name;
  },
};
</script>
<template>
  <v-card class="mx-6 mb-6 mx-lg-10">
    <v-row>
      <v-col
        v-for="(tile, i) in tiles"
        :key="i"
        cols="12"
        sm="6"
        class="pb-6">
        <v-item-group>
          <v-item>
            <InfoTile :tile_data="tile" :loading="tile.loading" />
          </v-item>
        </v-item-group>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import DataService from '../../_services/DataService';
import InfoTile from '../components/Tiles/InfoTile.vue';
import {mapGetters} from 'vuex';
import Utils from '../utils.js';

export default {
  name: 'Dashboard',
  components: {
    InfoTile,
  },
  data() {
    return {
      admin: false,
      drawer: false,
      pageName: 'Dashboard',
      pageIcon: 'mdi-monitor-dashboard',
      roleName: '',
      title: '',
      form: {},
      panel: [1],
      received: [],
      tiles: [],
      companyLogo: '',
      tileNonAdmin0: {
        type: 'appointment',
        title: 'Next Appointment',
        icon: 'mdi-calendar-blank-outline',
        data: { loading: true },
      },
      tileNonAdmin1: {
        type: 'invoice',
        title: 'Pay Bill',
        icon: 'mdi-credit-card-outline',
        data: [
          {
            subject: '',
            amount: '$0.00',
            desc: '',
            desc2: 'Your account balance is',
            late: false,
            location: '',
            body: '',
            page: 'BillPay',
            loading: true,
          },
        ],
      },
      tileNonAdmin2: {
        type: 'forms',
        title: 'Forms',
        icon: 'mdi-file-document-outline',
        data: [{
          loading: true,
          btn: 'View Forms',
          action: () => {
            this.$router.push({
              name: 'Account',
              query: { tab: 'forms' },
            });
          },
        }],
      },
      tileNonAdmin3: {
        type: 'treatmentplan',
        title: 'Treatment Plan',
        icon: 'mdi-notebook-outline',
        data: [{
          loading: false,
          btn: 'View Plan',
          alert: 'Your doctor has pending treatment planned, you may schedule an appointment',
          action: () => {
            this.$router.push({
              name: 'PatientTreatmentPlans',
            });
          },
        }],
      },
    };
  },
  computed: {
    activeCols: function () {
      return this.headers.filter(function (u) {
        return u.active == 1;
      });
    },
    ...mapGetters(['getAdminDashboadTiles']),
  },
  methods: {
    async getConfigLogo() {
      const response = await DataService.getConfigLogo();
      this.companyLogo = response.data;
    },
    async setNonAdminApptTile() {
      const body = await DataService.getPatientAppointments();
      const appointments = body.data;

      const nextAppointment = this.findNextAppointment(appointments);
      if (nextAppointment) {
        this.mapTileAppointment(nextAppointment);
      } else {
        // Handle case when there are no appointments with AptDate
        this.setTileNoAppointments();
      }
    },
    findNextAppointment(appointments) {
      if (!appointments.length) {
        return undefined;
      }

      const now = new Date();
      return appointments.find((appointment) => new Date(appointment.date) > now);
    },
    setTileNoAppointments() {
      this.tileNonAdmin0.data = [
        {
          btn: 'Create New Appointment',
          icon2: 'mdi-domain',
          icon3: 'mdi-account',
          doctor: 'No appointments are currently scheduled',
          loading: false,
          page: 'PatientSchedule',
        },
      ];
    },
    mapTileAppointment(d) {
      this.tileNonAdmin0.data = [
        {
          icon2: 'mdi-domain',
          icon3: 'mdi-account',
          date: Utils.formatDate(d?.date),
          time: Utils.formatTimeFromDate(d?.date),
          doctor: `Doctor: ${d.provider.firstName} ${d.provider.lastName}`,
          patient: `Patient: ${d.patient.firstName} ${d.patient.lastName}`,
          btn: `View Upcoming Appointments`,
          loading: false,
          page: 'ScheduledAppointments',
        },
      ];
    },
    async getTileCards() {
      if (this.roleName === 'patient') {
        this.tiles.push(
          this.tileNonAdmin0,
          this.tileNonAdmin1,
          this.tileNonAdmin2,
        );
      } else {
        this.tiles = this.$store.getters.getAdminDashboardTiles;
      }
    },
    setBalanceTile(totalBalance) {
      this.tiles = this.tiles.map((el) => {
        if (el.type == 'invoice') {
          el.data[0].amount = Utils.formatCurrency(totalBalance);
          el.data[0].loading = false;
        }
        return el;
      });
    },
    async getData() {
      if (this.roleName === 'patient') {
        const [balanceResponse, lastFormResponse, formsResponse, treatmentPlansResponse] = await Promise.all([
          DataService.getPatientAccountBalance(),
          DataService.getLastFormForCurrentPatient(),
          DataService.getFormsForPatient(),
          DataService.getTreatmentPlansForPatient(),
        ]);

        if (balanceResponse.status === 200) {
          this.setBalanceTile(balanceResponse.data.balance);
        }

        if (lastFormResponse.status === 200) {
          const outstandingForms = lastFormResponse.data.Description !== 'Registration - 5 - Privacy Notice';
          this.tiles = this.tiles.map((el) => {
            if (el.type === 'forms') {
              if (outstandingForms) {
                el.data[0].alert = 'You have pending registration forms to complete';
              }

              el.data[0].loading = false;
            }

            return el;
          });
        }

        if (formsResponse.status === 200) {
          const outstandingForms = formsResponse.data.some((form) => !form.completedAt);
          this.tiles = this.tiles.map((el) => {
            if (el.type === 'forms') {
              if (outstandingForms) {
                el.data[0].alert = 'You have pending forms to complete';
              }

              el.data[0].loading = false;
            }

            return el;
          });
        }

        if (treatmentPlansResponse.status === 200) {
          if (treatmentPlansResponse.data.plannedAppointments.length) {
            // this.tiles.push(this.tileNonAdmin3);
          }
        }

        await this.setNonAdminApptTile();
      } else {
        const messageCountResponse = await DataService.getUnreadMessageCount();
        if (messageCountResponse.status === 200 && messageCountResponse.data > 0) {
          this.tiles = this.tiles.map((el) => {
            if (el.type === 'email') {
              el.data = [{
                ...el.data[0],
                alert: `There are ${messageCountResponse.data} unread sms messages`,
              }];
            }

            el.data[0].loading = false;

            return el;
          });
        }
      }
    },
  },
  created() {
    // redirect if redirectURI is found
    if (this.$route.query.redirectURI != undefined) {
      window.location.href = this.$route.query.redirectURI;
    }
  },
  async mounted() {
    this.roleName = DataService.getRole();

    await this.getConfigLogo();
    //these are called seperately so that the tiles can be rendered while data is loading
    await this.getTileCards();
    await this.getData();
  },
};
</script>
<style scoped>
.welcomeText {
  color: #414a5b;
  /* //same as the new theme color */
  font-size: 25px;
  font-weight: 700;
}

.btns {
  color: #414a5b;
  background-color: #b0d35e;
  font-family: 'Inter', sans-serif;
  font-size: 12px !important;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: normal;
  padding: 20px;
}
</style>

<template>
    <v-card flat cols="12" md="12" sm="12" width="100%">
      <v-tabs v-model="tab" :showArrows="showArrows">
        <v-tab v-for="tab in tabs" :key="tab.label" active-class="activetab">
          {{ tab.label }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" :touchless="true">
        <v-tab-item v-for="tab in tabs" :key="tab.label">
          <v-card flat>
            <component :is="tab.section" :pageData="tab.pageData" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
</template>
<script>
export default {
  props: ['tabs', 'startTab', 'showArrows'],
  data() {
    return {
      tab: null,
    };
  },
  watch: {
    startTab() {
      this.tab = Number(this.startTab);
    },
  },
}
</script>
<style>
.activetab {
  background-color: #d0d1d5;
}
</style>
<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <v-row>
          <v-col cols="4">
            <v-text-field label="Search" outlined rounded dense></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-select label="Sort By" outlined rounded dense></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="employees">
      <template>
        <v-row>
          <v-checkbox
            class="mx-1"
            label=":M"
            v-model="item.availability.M"
          ></v-checkbox>
          <v-checkbox
            class="mx-1"
            label=":T"
            v-model="item.availability.T"
          ></v-checkbox>
          <v-checkbox
            class="mx-1"
            label=":W"
            v-model="item.availability.W"
          ></v-checkbox>
          <v-checkbox
            class="mx-1"
            label=":Th"
            v-model="item.availability.Th"
          ></v-checkbox>
          <v-checkbox
            class="mx-1"
            label=":F"
            v-model="item.availability.F"
          ></v-checkbox>
        </v-row>
      </template>
      <template>
        <v-btn color="primary" @click="openEdit">Edit</v-btn>
        <v-btn color="primary">Remove</v-btn>
        <span>{{ item.actions }}</span>
      </template>
    </v-data-table>
    <v-dialog v-model="edit" width="600" height="600">
      <v-card>
        <v-card-title>
          <v-avatar class="mx-2" color="primary"></v-avatar>
          <span>Name Goes Here</span>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-1">
            <v-text-field
              outlined
              dense
              class="mx-1"
              label="First Name"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              class="mx-1"
              label="Last Name"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field outlined dense label="Type"></v-text-field>
            <v-checkbox class="mx-1" label=":M"></v-checkbox>
            <v-checkbox class="mx-1" label=":T"></v-checkbox>
            <v-checkbox class="mx-1" label=":W"></v-checkbox>
            <v-checkbox class="mx-1" label=":Th"></v-checkbox>
            <v-checkbox class="mx-1" label=":F"></v-checkbox>
          </v-row>
          <v-textarea outlined dense label="Notes"></v-textarea>
          <v-btn class="mb-2" color="primary">Save</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import DataService from "../../../_services/DataService";

export default {
  name: "StaffManagement",
  components: {},
  data() {
    return {
      employees: [],
      edit: false,
      headers: [
        {
          text: "Last Name",
          value: "lastName",
          sortable: false,
          active: true,
        },
        {
          text: "First Name",
          value: "firstName",
          sortable: false,
          active: true,
        },
        {
          text: "Type",
          value: "users",
          sortable: false,
          active: true,
        },
        {
          text: "Availability",
          value: "avaliablity",
          sortable: false,
          active: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          active: true,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    async getEmployees() {
      await DataService.getAllEmployees()
        .then((response) => {
          const users = response.data;
          this.employees = users.map(this.displayEmployeeData);
          setTimeout(() => (this.loading = false), 100);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    displayEmployeeData(d) {
      return {
        id: d.EmployeeNum,
        firstName: d.FName,
        lastName: d.LName,
        availability: { M: 1, T: 1, W: 1, Th: 1, F: 1 },
      };
    },
    openEdit() {
      //pass id in
      this.edit = true;
    },
  },

  created() {},
  async mounted() {
    await this.getEmployees();
  },
};
</script>

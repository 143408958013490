<template>

<v-container fill-height fluid>
    <v-row class="mt-6" justify="center" align="center" >
            <v-icon>mdi-gpp_bad</v-icon>          
            <h1 style="color:red">Unauthorized Access</h1>
    </v-row>
    <v-row justify="center" align="center" >
        <p>You are not authorized to access the requested resource.</p>
    </v-row>
    <v-row justify="center" align="center" ><v-btn @click="goHome">Go Back</v-btn></v-row>
</v-container>
  </template>
  <script>
    export default {
  name: 'CompanyConfig',
  props: [
  ],
  components: {
   
  },
  data() {
    return {
      
    };
  },
  computed: {
  },
  watch: {

  },
  methods: {
    goHome(){
        this.$router.push('/dashboard')
    }
    
  },
  created() {
  },
  mounted() {
    this.getClientInfo();
  }
}
  </script>
<template>
  <v-container>
    <v-layout row>
      <v-flex class="mb-6">
        <v-card class="login-card text-center" style="margin-top: 5vh !important;" tile>
          <v-card-title class="client-banner mb-2">
            <img
              cover
              :width="150"
              :src="clientLogo"
              v-if="clientLogo" />
          </v-card-title>
          <v-card-text>
            <v-container>
              <form @submit.prevent="submit">
                <v-layout row wrap>
                  <v-flex xs12>
                    <div v-if="!signUpSuccess">
                      <h3 class="logn-header">Create your account</h3>
                      <v-text-field
                        v-model="form.firstName"
                        :rules="[rules.name]"
                        placeholder="First Name"
                        type="name"
                        required
                        @change="submitStatusCheck()"
                        disabled
                        outlined
                        dense
                        rounded />
                      <v-text-field
                        v-model="form.lastName"
                        :rules="[rules.name]"
                        placeholder="Last Name"
                        type="name"
                        @change="submitStatusCheck()"
                        disabled
                        required
                        outlined
                        dense
                        rounded />
                      <v-text-field
                        v-model="form.email"
                        :rules="[rules.email]"
                        placeholder="Email"
                        type="email"
                        required
                        @change="submitStatusCheck()"
                        disabled
                        outlined
                        dense
                        rounded />
                      <hr />
                      <h3 class="logn-header" style="margin-top: 16px">
                        Enter a password
                      </h3>
                      <v-text-field
                        placeholder="Password"
                        outlined
                        @change="submitStatusCheck()"
                        v-model="form.password"
                        :rules="[rules.required, rules.pass_all, rules.max50]"
                        dense
                        type="password" />
                      <v-text-field
                        placeholder="Repeat Password"
                        outlined
                        @change="submitStatusCheck()"
                        v-model="form.confirmPassword"
                        :rules="[
                          rules.required,
                          rules.pass_all,
                          rules.max50,
                          mustMatch,
                        ]"
                        dense
                        type="password" />
                      <v-container class="form-bottom">
                        <v-row no-gutters style="padding-bottom: 15px">
                          <v-col md="12" class="text-center">
                            <v-btn
                              :disabled="submitStatus"
                              @change="submitStatusCheck()"
                              class="sign-in success"
                              type="submit"
                              style="width: 200px">
                              Submit
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                    <div v-if="signUpSuccess">
                      <h3 class="logn-header">Your account has been created!</h3>
                      <v-container class="form-bottom">
                        <v-row no-gutters style="padding-bottom: 15px">
                          <v-col md="12" class="text-center">
                            <v-btn
                              @click="goToLogin()"
                              class="sign-in success"
                              style="width: 200px; margin: 5px">
                              Go to Login
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                  </v-flex>
                </v-layout>
              </form>
            </v-container>
          </v-card-text>
          <span style="color: #aaa">By creating an account, you agree to receive email and/or sms messages from the Acrew platform. These message may include confirmations, reminders, and special offers or promotions from your dental provider.</span>
          <br />
          <br />
          <span style="color: #aaa">Powered by</span>
          <br />
          <img
              src="@/../public/static/img/acrew-sec-logo.png"
              alt="Logo"
              width="150px" />
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<style scoped>
@media screen and (max-width: 450px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.center-all {
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-center {
  margin-left: auto;
  margin-right: auto;
}

.theme--light.v-card.login-card {
  max-width: 500px;
  background-color: #ffffff !important;
  margin: 0 auto;
  margin-top: 10vh;
  box-shadow: 0 !important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.login-inner-form {
  background-color: #ffffff !important;
  padding: 1rem 2.5rem 0 2.5rem;
}

.logn-header {
  font-weight: normal;
  margin-bottom: 1rem;
  color: #68b4c9;
}

.v-input--selection-controls {
  margin-top: 0;
}

.form-bottom {
  padding: 0;
}

.client-banner {
  background-color: v-bind(clientColor) !important;
}

.client-banner img {
  margin: auto;
}
</style>
<script>
import DataService from '../../_services/DataService';
import ValidateService from '../../_services/ValidateService';
export default {
  name: 'PatientSignup',
  data() {
    return {
      form: {
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        email: '',
        patientId: '',
      },
      submitStatus: true,
      error: false,
      signUpSuccess: false,
      rules: ValidateService.validators,
      clientLogo: null,
      clientColor: null,
    };
  },
  methods: {
    goToLogin() {
      this.$router.push('/login');
    },
    numDash(e) {
      var num = this.form.phone;
      if (e.key != 'Backspace' && (num.length === 3 || num.length === 7)) {
        this.form.phone += '-';
      }
    },
    mustMatch() {
      return this.form.password === this.form.confirmPassword ? true : 'Passwords do not match.';
    },
    submitStatusCheck() {
      this.submitStatus = !(
        this.form.firstName !== '' &&
        this.form.lastName !== '' &&
        this.form.email !== '' &&
        this.form.password !== '' &&
        this.form.confirmPassword !== '');
    },
    async getClientPreferences() {
      const response = await DataService.getClientPreferences();
      if (response.status === 200) {
        this.clientLogo = response.data.clientLogo;
        this.clientColor = response.data.clientColor;
      }
    },
    async submit() {
      const response = await DataService.addPatientUser({
        password: this.form.password,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email,
        patientId: this.form.patientId,
      });

      if (response.status === 201) {
        this.signUpSuccess = true;
      } else {
        this.$root.$emit('raise_alert', {
          color: 'info',
          text: response.data || 'Something went wrong',
        });
      }
    },
  },
  async mounted() {
    if (this.$route.query.q) {
      const { patientId, clientId, firstName, lastName, email } = JSON.parse(atob(this.$route.query.q));

      DataService.updateClientId(clientId);

      this.form.patientId = patientId;
      this.form.firstName = firstName;
      this.form.lastName = lastName;
      this.form.email = email;
    } else {
      if (this.$route.query.clientId) {
        DataService.updateClientId(this.$route.query.clientId);
      }

      this.form.patientId = this.$route.query.patientId;
      this.form.firstName = this.$route.query.firstName;
      this.form.lastName = this.$route.query.lastName;
      this.form.email = this.$route.query.email;
    }

    await this.getClientPreferences();
  },
};
</script>

<template>
  <v-container>
    <v-layout row>
      <v-flex class="mb-6">
        <v-card class="login-card text-center" tile>
          <img
            v-if="!clientColor"
            src="@/../public/static/img/acrew-sec-logo.png"
            alt="Logo"
            width="400px"
          />
          <v-card-title v-if="clientColor" class="client-banner mb-2">
            <img
              cover
              :width="150"
              :src="clientLogo"
              v-if="clientLogo" />
          </v-card-title>
          <v-card-text>
            <v-container class="login-inner-form">
              <form @submit.prevent="submit">
                <v-layout row wrap>
                  <v-flex xs12>
                    <div v-if="this.tokenValid && !updateSuccess">
                      <h3 class="logn-header">
                        Enter a new password
                      </h3>
                      <v-text-field label="New Password"
                        outlined v-model="password" :rules="[rules.required, rules.pass_all, rules.max50]" dense type="password" />
                      <v-text-field label="Repeat New Password"
                        outlined v-model="confirmPassword" :rules="[rules.required, rules.pass_all, rules.max50, mustMatch]" dense type="password" />
                      <v-container class="form-bottom">
                        <v-row no-gutters style="padding-bottom: 15px">
                          <v-col md="12" class="text-center">
                            <v-btn class="sign-in success" type="submit" style="width:200px">
                              Submit
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                    <div v-if="updateSuccess">
                      <h3 class="logn-header">
                          Your password has been changed!
                        </h3>
                        <v-container class="form-bottom">
                          <v-row no-gutters style="padding-bottom: 15px">
                            <v-col md="12" class="text-center">
                              <v-btn @click="$router.push('/login')" class="sign-in success" style="width:200px; margin:5px;">
                                Return to Login
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                    </div>
                    <div v-if="!this.tokenValid">
                      <h3 class="logn-header">
                        Invalid or Expired Token!
                      </h3>
                      <v-container class="form-bottom">
                        <v-row no-gutters style="padding-bottom: 15px">
                          <v-col md="12" class="text-center">
                            <v-btn @click="$router.push('/forgot_password')" class="sign-in success" style="width:200px; margin:5px;">
                              Request New Token
                            </v-btn>
                            <v-btn @click="$router.push('/login')" class="sign-in success" style="width:200px; margin:5px;">
                              Return to Login
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                  </v-flex>
                </v-layout>
              </form>
            </v-container>
          </v-card-text>
          <div v-if="clientColor">
            <span style="color: #aaa">Powered by</span>
            <br />
            <img
              src="@/../public/static/img/acrew-sec-logo.png"
              alt="Logo"
              width="150px" />
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<style scoped>
@media screen and (max-width: 450px) {

  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.center-all {
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-center {
  margin-left: auto;
  margin-right: auto;
}

.theme--light.v-card.login-card {
  max-width: 500px;
  background-color: #ffffff !important;
  margin: 0 auto;
  margin-top: 10vh;
  box-shadow: 0 !important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.login-inner-form {
  background-color: #ffffff !important;
  padding: 1rem 2.5rem 0 2.5rem;
}

.logn-header {
  font-weight: normal;
  margin-bottom: 1rem;
  color: #68B4C9;
}

.v-input--selection-controls {
  margin-top: 0;
}

.form-bottom {
  padding: 0;
}

.client-banner {
  background-color: v-bind(clientColor) !important;
}

.client-banner img {
  margin: auto;
}
</style>
<script>
import DataService from '../../_services/DataService.js';
import ValidateService from '../../_services/ValidateService';
export default {
  name: 'ForgotPassword',
  data() {
    return {
      clientLogo: null,
      clientColor: null,
      username: '',
      password: '',
      confirmPassword: '',
      error: false,
      tokenValid: false,
      updateSuccess: false,
      rules: [],
    };
  },
  methods: {
    getValidators() {
      this.rules = (ValidateService.validators)
    },
    mustMatch(){
      return this.password === this.confirmPassword ? true : 'Passwords do not match.';
    },
    async submit() {
      const body = {
        username: this.username,
        token: this.$route.query.token,
        password: this.password
      }
      const response = await DataService.changePassword(body)
      this.tokenValid = response.data.validToken;
      this.updateSuccess = response.data.success;
    },
    async getClientPreferences() {
      const response = await DataService.getClientPreferences();
      if (response.status === 200) {
        this.clientLogo = response.data.clientLogo;
        this.clientColor = response.data.clientColor;
      }
    },
  },
  async created() {
    this.getValidators();
    let token = this.$route.query.token;
    const data = await DataService.checkPasswordResetToken({token: token})
    this.tokenValid = data.data.validToken
  },
  async mounted() {
    if (this.$route.query.clientId) {
      DataService.updateClientId(this.$route.query.clientId);
      await this.getClientPreferences();
    }
  }
};
</script>


<template>
  <v-layout row wrap class="no-toppad" no-gutters text-xs-right>
    <v-container
    >
      <v-row noGutters>
        <v-col cols="12" md="12" sm="12">
          <h3>Update Account Info</h3>
          <AccountInfo />
          <h3>Update Password</h3>
          <PasswordChange />
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>
<script>
import AccountInfo from '../../components/Forms/AccountInfo.vue';
import PasswordChange from '../../components/Forms/PasswordChange.vue';
export default {
  name: "updateProfile",
  data() {
    return {
      pageName: "Update Profile",
    };
  },
  components: { PasswordChange, AccountInfo }
}
</script>

exports.formatDuration = (minutes) => {
  if (!minutes) {
    return '--';
  }

  const hours = Math.floor(minutes / 60);
  const leftoverMinutes = minutes % 60;

  let retVal = '';
  if (hours > 0) {
    retVal += `${hours}hr`;
  }

  if (leftoverMinutes > 0) {
    retVal += ` ${leftoverMinutes}min`;
  }

  return retVal.trim();
};

exports.formatCurrency = (num) => {
  if (!num) {
    return '$0.00';
  }
  if (num < 0) {
    num = Number(num) * -1;
    return `($${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')})`;
  } else {
    num = Number(num);
    return `$${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  }
};

exports.formatNumber = (num, decimal = 0) => {
  if (!num) {
    return 0;
  }
  if (num < 0) {
    num = Number(num) * -1;
    return `(-${num.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')})`;
  } else {
    num = Number(num);
    return `${num.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  }
};

exports.getLongDateString = (date) => {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return date.toLocaleString('en-Us', options);
};



exports.convertProcedurePatterntoMinutes = (pattern) => {
  return pattern.length * 10;
};

exports.convertAppointmentPatterntoMinutes = (pattern) => {
  return pattern.length * 5;
};

//******BEGIN*DATE*TIME*FORMATTING*FUNCTIONS****** 

/* 
func: formatDate

desc: formats date(s) as MM/DD/YY

param(s): date (string or numeric) 

example(valid): April 1 2023, 4/1/2023, 4-1-2023 
  returns: Saturday, April 1, 2023 

example(invalid): undefined, October 69th 2420, 13/13/2013 
  returns: '-' OR 'Invalid Date'
*/
exports.formatDate = (date) => {
  try{
    const defReturn = '- -/- -/- -';
    if (!isTypeValid(date)) return defReturn;
    
    const options = {timeZone: 'UTC'};
    const formattedDate = new Date(date).toLocaleDateString(
        'en-us',
          options);

    return (formattedDate !== 'Invalid Date') ?
      formattedDate : defReturn;
  } catch (err) {
    console.log(err);
    return err;
  }
};

/* 
func: formatDateAndTime

desc: formats date(s) as MM/DD/YY and time as 

param(s): date (string or numeric) 

example(valid):  2023-05-11T17:50:00.000Z
  returns 5/11/2023, 5:50:00 PM

example(invalid): undefined, October 69th 2420, 13/13/2013 
  returns: '-' OR 'Invalid Date'
*/
exports.formatDateAndTime = (date) => {
  try{
    const defReturn = '- -/- -/- -';
    if (!isTypeValid(date)) return defReturn;
 
    const options = {timeZone: 'UTC'};
    const formattedDate = new Date(date).toLocaleString(
        'en-us',
          options);

    return (formattedDate !== 'Invalid Date') ?
      formattedDate : defReturn;
  } catch (err) {
    console.log(err);
    return err;
  }
};

/* 
func: formatHour

desc: formats first hour of date time string 

param(s): date (string or numeric) 

example(valid):  2023-05-12T17:50:00.000Z 
  returns 5 PM

example(invalid): undefined, October 69th 2420, 13/13/2013 
  returns: '-' OR 'Invalid Date'
*/
exports.formatHour = (date) => {
  try{
    const defReturn = '- -/- -/- -';
    if (!isTypeValid(date)) return defReturn;
    
    const options = { hour: 'numeric', hour12: true, timeZone: 'UTC'};
    const formattedDate = new Date(date).toLocaleString(
        'en-us',
          options);

    return (formattedDate !== 'Invalid Date') ?
      formattedDate : defReturn;
  } catch (err) {
    console.log(err);
    return err;
  }
};

/* 
func: formatLongDateString

desc: formats date into long MMDDYY string

param(s): date (string or numeric) 

example (valid): 2023-04-27T09:00:00 - 2023-04-27
  returns: 4/27/2023

example (invalid): October 69th 2420 - 2023-04-27T30:00:00
  returns: '--/--/--' 
*/
exports.formatLongDateString = (date) => {
  try{
    const defReturn = '- -/- -/- -';
    if (!isTypeValid(date)) return defReturn;

    const options = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      timeZone: 'UTC' // needed pre ECMAScript ed 6
    };
    const formattedDate = new Date(date).toLocaleDateString(
        'en-us', options );

    return (formattedDate !== 'Invalid Date') ?
        formattedDate : defReturn;
  } catch (err) {
    console.log(err);
    return err;
  }
};

/* 
func: formatTime

desc: formats time into normal US format

param(s): time (string or numeric) 

example(valid): 09:00:00
  returns: 09:00 AM

example(invalid): 30:00:00
returns: '-' OR 'Invalid Time' 
*/
exports.formatTime = (time) => {
  try{
    const defReturn = '--:--';
    if (!isTypeValid(time)) return defReturn;

    const options = { 
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'UTC' 
      };
    const timeDateObj = new Date(
        `1970-01-01T${time}Z`); 

    const formattedTime = timeDateObj.toLocaleTimeString(
        [], options );
        
    return (formattedTime !== 'Invalid Date') ?
        formattedTime : defReturn;
  } catch (err) {
    console.log(err);
    return err;
  }
};

/* 
func: formatTimeFromDate

desc: converts time into normal US format from date 

param(s): date (string or numeric) 

example(valid): 2023-04-27T09:00:00
  returns: 09:00 AM

example(invalid): October 69th 2420 - 2023-04-27T30:00:00
  returns: '-'
*/
exports.formatTimeFromDate = (date) => {
  try{
    const defReturn = '--:--';
    if (!isTypeValid(date)) return defReturn;
  
    let options = {
       hour: '2-digit',
        minute: '2-digit'
    };
    
    const timeDateObj = new Date(date);
    const formattedTime = timeDateObj.toLocaleTimeString([], options);
    return (formattedTime !== 'Invalid Date') ? formattedTime : defReturn;
  } catch (err) {
    console.log(err);
    return err;
  }
};

/* Returns current date with option for long format */
exports.getCurrentDateFormatted = (format) => {
  if (format === 'long')
    return new Date().toLocaleDateString('en-us',{ 
      weekday: 'long', 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric'
    });

  return new Date().toLocaleDateString();
};

/* Handles Null values not returned 
as invalid by Js Date Obj */
function isTypeValid(date){
  return (typeof(date) == "string" 
    || typeof(date) == "number" ) ?
    true : false;
}

//******END*DATE*TIME*FORMATTING*FUNCTIONS******

exports.formatPhone = (phoneNumber) => {
  const cleaned = ('' + phoneNumber)
    // replaces the plus symbol (+) followed by any single digit with 
    // an empty string for local centric phone fomatting
    // e.g. +12151231234 -> 2155121234
    .replace(/\+\d/, '')
    .replace(/[^\d^x]/g, '')
    .replace(/\s+/g, '');

  const match = cleaned.match(/^(\d{3})(\d{3})[ .-]?(\d{4})(?: *x(\d+))?\s*$/);

  if (match) {
    let ext = match[4] ? ' x' + match[4] : '';
    return '(' + match[1] + ') ' + match[2] + '-' + match[3] + ext;
  }
  return phoneNumber;
};

exports.setDefVal = (val, defVal1, defVal2) => {
  if (defVal2 !== undefined) {
    return val || val == 0 ? defVal1 : defVal2;
  } else return val !== undefined && val !== '' ? val : defVal1;
};

exports.getUsTimezones = () => {
  const tzObjArr = [
    {
      name: 'Eastern Time (ET)',
      location: 'America/New_York',
    },
    {
      name: 'Central Time (CT)',
      location: 'America/Chicago',
    },
    {
      name: 'Mountain Time (MT)',
      location: 'America/Denver',
    },
    {
      name: 'Pacific Time (PT)',
      location: 'America/Los_Angeles',
    },
  ];
  return tzObjArr;
};

exports.setAlert = (status, msg) => {
  let error = {
    text: 'There was an error',
    icon: 'mdi-alert-circle-outline',
    color: 'error',
  };
  let success = {
    text: 'success',
    icon: 'mdi-alert-circle-outline',
    color: 'success',
  };
  let info = {
    text: 'There was an error',
    icon: 'mdi-information-slab-circle-outline',
    color: 'info',
  };
  let result = (String(status) == 'fail') ? error : (status == 'success') ?  success : info ;
  if (msg) {
    result.text = msg;
  }
  return result;
};
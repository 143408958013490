<template>
  <v-container>
    <h2 v-if="patientList.length > 1">View Forms For</h2>
    <v-select
      v-if="patientList.length > 1"
      v-model="selectedPatient"
      :items="patientList"
      :item-text="item => `${item.firstName} ${item.lastName}`" 
      item-value="item => item.id"
      label="Patient"
      return-object
      outlined
      dense
      style="width: 400px"
      @change="handlePatientSelect" />

    <v-progress-circular
      v-if="formLoading"
      indeterminate
      color="primary"
      style="
        position: fixed;
        top: 50%;
        left: 0%;
        height: 50px !important;
        margin: auto;
        width: 100%;
        z-index: 99;" />

    <v-overlay v-model="formLoading" />
    <v-stepper v-model="currentStep">
      <v-stepper-header>
        <v-layout v-for="form in forms" :key="form.key">
          <v-stepper-step
            color="secondary"
            :complete="currentStep > form.step"
            :step="form.step">
          </v-stepper-step>
          <v-divider v-if="form !== form.step" />
        </v-layout>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          v-for="form in forms"
          :key="`${form.step}-content`"
          :step="form.step">

          <component
            :is="form.component"
            :patient="selectedPatient"
            :userForm="form.userForm"
            :formsDisabled="formsDisabled"
            ref="currentFormComponent"
            @goToNextForm="goToNextForm"
            @submitFailure="submitFailure"
            :key="reRenderKey" />

          <!-- Previous and Next Button -->
          <v-row class="d-flex justify-center pb-3" v-if="!formsDisabled">
            <v-col cols="4" v-if="form.step != 1">
              <v-btn block class="mr-3" @click="previousStep(form.step)">Previous</v-btn>
            </v-col>
            <v-col cols="4" v-if="form.step != forms.length">
              <v-btn block class="secondary" @click="nextStep(form.step)">Next</v-btn>
            </v-col>
            <v-col cols="4" v-if="form.step == forms.length">
              <v-btn block class="secondary" @click="nextStep(form.step)">Submit</v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import CustomForm from "@/components/Forms/CustomForm.vue";
import DentalForm from "@/components/Forms/DentalForm.vue";
import InsuranceForm from "@/components/Forms/InsuranceForm.vue"; 
import MedicalForm from "@/components/Forms/MedicalForm.vue";
import OfficePolicyForm from "@/components/Forms/OfficePolicyForm.vue";
import PrivacyPracticesForm from "@/components/Forms/PrivacyPracticesForm.vue";
import DataService from '../../_services/DataService';

export default {
  name: 'PatientForms',
  components: {
    InsuranceForm,
    MedicalForm,
    DentalForm,
    OfficePolicyForm,
    PrivacyPracticesForm,
  },
  data() {
    return {
      currentStep: 1,
      formsDisabled: false,
      forms: [
        {
          component: InsuranceForm,
          name: 'Insurance Form',
          step: 1,
        },
        {
          component: MedicalForm,
          name: 'Medical Form',
          step: 2,
        },
        {
          component: DentalForm,
          name: 'Dental Form',
          step: 3,
        },
        {
          component: OfficePolicyForm,
          name: 'Office Policy Form',
          step: 4,
        },
        {
          component: PrivacyPracticesForm,
          name: 'Privacy Practices Form',
          step: 5,
        },
      ],
      reRenderKey: 1,
      formLoading: true,
      patientList: [],
      selectedPatient: {},
    };
  },
  methods: {
    setStepIfBeyond(step) {
      if (this.currentStep > step) {
        this.currentStep = step;
      }
    },
    forceRerender() {
      this.reRenderKey += 1;
    },
    previousStep (n) {
      if (n !== 1) {
        this.currentStep = n - 1;
        window.scrollTo(0,0);
      }
    },
    async nextStep (n) {
      if (n <= this.forms.length) {
        // validate child form
        if(await this.$refs.currentFormComponent[this.currentStep - 1].validate()) {
          this.formLoading = true;
          // create pdf of current form
          await this.$refs.currentFormComponent[this.currentStep - 1].generatePDF();
          
        } else {
          // form not validated, create alert
          this.$root.$emit('raise_alert', { color: 'error', text: 'Please fill out the required inputs.' });
        }
      }
    },
    goToNextForm() {
      this.formLoading = false;
      this.currentStep++;
      window.scrollTo(0,0);
    },
    submitFailure() {
      this.formLoading = false;
    },
    async getFamily() {
      const response = await DataService.getPatientFamily();
      if (response.status === 200) {
        this.patientList = response.data;
        this.selectedPatient = this.patientList.find((pat) => pat.status === 'Self');
      }
    },
    async continueForms() {
      this.formLoading = true;
      this.formsDisabled = false;

      const latestFormResponse = await DataService.getLastPatientForm(this.selectedPatient.id);
      if (latestFormResponse.status === 200) {
        switch (latestFormResponse.data.Description) {
          case 'Registration - 1 - Insurance':
            this.currentStep = 2;
            break;
          case 'Registration - 2 - Medical History':
            this.currentStep = 3;
            break;
          case 'Registration - 3 - Dental Form':
            this.currentStep = 4;
            break;
          case 'Registration - 4 - Office Policy':
            this.currentStep = 5;
            break;
          case 'Registration - 5 - Privacy Notice':
            // all forms have been subitted already
            this.currentStep = 6;
            break;
          default:
            this.currentStep = 1;
            break;
        }
      }

      if (this.currentStep === 6) {
        const maybeId = this.selectedPatient.status === 'Self' ? undefined : this.selectedPatient.id;
        const formsResponse = await DataService.getFormsForPatient(maybeId);
        if (formsResponse.status === 200) {
          const pendingForms = formsResponse.data.filter((form) => !form.completedAt);
          if (pendingForms.length) {
            for (const form of pendingForms) {
              this.forms.push({
                component: CustomForm,
                name: form.name,
                step: this.forms.length + 1,
                userForm: form,
              });
            }

            // todo this is probably wrong.
            this.currentStep = 5;
            setTimeout(() => { this.currentStep = 6; }, 0);
          } else {
            this.formsDisabled = true;
          }
        } else {
          this.formsDisabled = true;
        }
      }

      this.formLoading = false;
    },
    async handlePatientSelect() {
      await this.continueForms();
    },
  },
  async mounted(){
    await this.getFamily();
    await this.continueForms();
  },
};
</script>
<template>
  <v-layout row wrap class="no-toppad" no-gutters text-xs-right>
    <v-container class="">
      <v-row>
        <v-col cols="12">
          <h2>
            <span>Privacy & Consent</span>
          </h2>
        </v-col>
      </v-row>
      <v-card class="pt-5 pb-2">
        <v-form ref="form" @submit.prevent="submitForm">
          <v-row>
            <v-col cols="12" class="d-flex justify-center pb-0">
              <h2 class="pt-0 pb-3">Agree to Share with your Guarantor</h2>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-row class="d-flex justify-center mx-0">
                <v-col cols="12" sm="6" md="5" lg="4">
                  <h3 class="font-weight-regular">
                    Your account is managed by another account ({{ guarantor.firstName + ' ' + guarantor.lastName }}), but they require your consent to view information from within this website.
                  </h3>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mx-0 mt-0 d-flex justify-center">
            <v-col cols="12" sm="6" md="5" lg="4" class="pt-0">
              <v-checkbox v-model="consentCheckbox">
                <template v-slot:label><span class="text-body-1">I release my medical information to the account guarantor.</span>
                </template>
              </v-checkbox>
              <v-checkbox v-model="noConsentCheckbox" class="pt-0 mt-0">
                <template v-slot:label><span class="text-body-1">I do not release my medical information to the account guarantor.</span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center mb-3">
              <v-btn type="submit" color="appPrimaryBtnColor" @click="submitForm" dense>Submit</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
  </v-layout>
</template>
<script>
import DataService from '../../../_services/DataService';
import ValidateService from '../../../_services/ValidateService';
// import Vue from 'vue';
// import VueSignaturePad from 'vue-signature-pad';
// Vue.use(VueSignaturePad);
export default {
  name: 'PrivacyConsent',
  components: {},
  data() {
    return {
      guarantor: {},
      consentCheckbox: null,
      noConsentCheckbox: null,
      consentToShareSignature: null,
      rules: ValidateService.validators,
    };
  },
  watch: {
    consentCheckbox(val) {
      if (val) {
        this.noConsentCheckbox = 0;
      }
    },
    noConsentCheckbox(val) {
      if (val) {
        this.consentCheckbox = 0;
      }
    },
  },
  methods: {
    async submitForm() {
      const response = await DataService.updatePatientConsent({ consentedToShare: this.consentCheckbox });
      if (response.status === 200) {
        this.$root.$emit('raise_alert', {
          color: 'success',
          text: 'Your privacy settings have been saved!',
        });
        this.$router.push('/dashboard');
      } else {
        this.$root.$emit('raise_alert', {
          color: 'error',
          text: 'Your privacy settings could not be saved at this time, please try again!',
        });
      }
    },

    async checkClientConsent() {
      const userInfoResp = await DataService.getPatientInfo();
      if (userInfoResp.status === 200) {
        const { consentedToShare } = userInfoResp.data.consent;
        this.consentCheckbox = consentedToShare;
        this.noConsentCheckbox = !consentedToShare;

        this.guarantor = userInfoResp.data.guarantor;

        this.$refs.form.resetValidation();
      }
    },
  },
  async mounted() {
    await this.checkClientConsent();
  },
};
</script>

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // Ascendent Health Light
        primary: '#414A5B', //the new theme color

        // primary: '#74C9CC', //the initial theme color
        // primary: '#005C84', // testing

        secondary: '#1182D5',
        accent: '#46A24A',
        error: '#F37D7D',
        success: '#92CBA4',
        header: '#68B4C9',
        navcolor: '#414A5B', //the new theme color
        appPrimaryBtnColor: '#B0D35E',
        appSecondaryBtnColor: '#E3B900',
        deleteBtnColor: '#FF0000',

        // navcolor: '#429EB9', //the initial theme color
        // navcolor: '#005C84', // testing

        cardcolor: '#414A5B', //the new theme color
        cardcolorlight: '#707f9c',
        highlight: '#B0D35E',
        // cardcolor: '#74C9CB', //the initial theme color
        // cardcolor: '#005C84', // testing
        // selected: '#74C9CC',

        selected: '#005C84', // testing
        // primary_font: '#65AFC7',
        primary_font: '#414A5B', //the new theme color

        // info: '#D4EBE1',
        info: '#b3e8ff', // testing
        info2: '#FCF1D3',

        // MacTech Theme:
        // primary: '#ED7923',
        // secondary: '#1182D5',
        // accent: '#46A24A',
        // error: '#D50000',
        // success: '#46A24A',
      },
      dark: {
        //Ascendent Health Theme
        primary: '#414A5B', //the new theme color
        // primary: '#74C9CC',
        secondary: '#1182D5',
        accent: '#46A24A',
        error: '#F37D7D',
        success: '#46A24A',
        header: '#68B4C9',
        navcolor: '#68B4C9',
        cardcolor: '#74C9CB',
        selected: '#74C9CC',
        primary_font: '#65AFC7',

        // MacTech Theme:
        // primary: '#ffffff',
        // secondary: '#01498E',
        // accent: '#46A24A',
        // error: '#D50000',
        // success: '#46A24A',
      },
    },
  },
});

<template>
  <v-layout row wrap class="no-toppad" no-gutters text-xs-right>
    <v-container class="pa-0">
      <v-card class="pa-0">
        <v-row v-if="loading" class="mt-4">
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            style="
              height: 50%;
              margin: auto;
              padding-top: 50%;
            " />
        </v-row>
        <!-- top section row - begins -->
        <v-row v-if="!loading" class="px-0 mt-0" wrap>
          <!-- top section - left - begins -->
          <v-col cols="12" xs="12" sm="12" md="8">
            <!-- nameplate card - begins -->
            <v-card v-if="patient" dark color="cardcolorlight">
              <div class="text-center pt-2">
                <v-avatar size="100" style="background-color: #b0d35e">
                  <span class="white--text" style="font-size: 48px">
                    {{ this.patient.firstName.charAt(0) + this.patient.lastName.charAt(0) }}
                  </span>
                </v-avatar>
              </div>
              <div class="text-center pb-2">
                <h2 style="color: white">
                  {{ this.patient.firstName + ' ' + this.patient.lastName }}
                </h2>
              </div>
            </v-card>

            <v-col cols="12" class="pt-0">
              <v-row class="mt-5" wrap>
                <v-col cols="6" class="py-0 pl-0">
                  <!-- begin phone card -->
                  <v-card
                    class="mx-auto"
                    min-height="280px"
                    dark
                    flat
                    color="cardcolorlight">
                    <div class="pa-5">
                      <p>
                        <span style="color: #efefef">
                          Date of Birth: {{this.patient.birthDate}}
                        </span>
                      </p>
                      <p>
                        <span style="color: #efefef">
                          Home Phone: {{this.formatPhone(this.patient.homePhone)}}
                        </span>
                      </p>
                      <p>
                        <span style="color: #efefef">
                          Cell Phone: {{this.formatPhone(this.patient.cellPhone)}}
                        </span>
                      </p>
                      <p>
                        <span style="color: #efefef">
                          Work Phone: {{this.formatPhone(this.patient.workPhone)}}
                        </span>
                      </p>
                    </div>
                  </v-card>
                </v-col>
                <!-- begin contact card -->
                <v-col cols="6" class="py-0 pr-0">
                  <v-card
                    class="mx-auto"
                    min-height="280px"
                    dark
                    flat
                    color="cardcolorlight">
                    <div class="pa-5">
                      <p>
                        <span style="color: #efefef">
                          Address: {{this.patient.address1}} {{this.patient.address2}}
                        </span>
                      </p>
                      <p>
                        <span style="color: #efefef">
                          City: {{this.patient.city}}
                        </span>
                      </p>
                      <p>
                        <span style="color: #efefef">
                          State: {{this.patient.state}}
                        </span>
                      </p>
                      <p>
                        <span style="color: #efefef">
                          Zip: {{this.patient.postalCode}}
                        </span>
                      </p>
                    </div>
                  </v-card>
                </v-col>
                <!-- end user cards -->
              </v-row>
            </v-col>
          </v-col>
          <!-- top section - right - begins -->
          <v-col v-if="patient && !patient.isSubAccount" cols="12" sm="12" md="4" class="pt-1 px-md-3">
            <!-- - begin dep list -->
            <v-card>
              <v-row class="ml-0">
                <v-card-title>Dependents</v-card-title>
              </v-row>
              <v-list disabled flat>
                <div class="text-center">
                  <!-- loading  -->
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="primary"
                    style="
                      height: 50%;
                      margin: auto;
                      padding-top: 50%;
                    "></v-progress-circular>
                  <!-- no data display -->
                  <div v-if="!loading && !hasDependents">
                    <div>
                      <v-icon large>mdi-account-details</v-icon>
                      <p>No dependents for the selected patient.</p>
                    </div>
                  </div>
                </div>
                <!-- dependent display -->
                <v-list-item-group active-class="active-dependent">
                  <v-list-item
                    v-for="dep in this.dependents"
                    :key="dep.FName"
                    class="pb-2">
                    <v-btn
                      class="dependentListItem"
                      x-large
                      outlined
                      style="color: #0079c6"
                      width="100%"
                      elevation="3">
                      <v-avatar
                        size="36"
                        color="#0079C6"
                        style="color: white"
                        class="mr-3">
                        {{ dep.firstName.charAt(0) + dep.lastName.charAt(0) }}
                      </v-avatar>
                      <v-list-item-title
                        v-text="dep.firstName + ' ' + dep.lastName"
                        style="color: #0079c6"></v-list-item-title>
                    </v-btn>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <!-- end dep list -->
        </v-row>
        <!-- end page row -->
      </v-card>
    </v-container>
  </v-layout>
</template>
<script>
import DataService from '../../../_services/DataService';
import Utils from '../../../src/utils.js';

export default {
  name: 'User_Data',
  props: ['userInfo'],
  components: {},
  data() {
    return {
      pageName: 'User Data',
      loading: true,
      patient: null,
      dependents: [],
      hasDependents: false,
    };
  },
  computed: {},
  watch: {
    dependentPage: {
      async handler() {
        await this.getDependentData();
      },
      deep: false,
    },
  },
  methods: {
    formatDate(item) {
      if (item != undefined){
        return Utils.formatDate(item);
      }
      else return '-';
    },
    formatPhone(item) {
      if (item != undefined) {
        return Utils.formatPhone(item);
      } else return '-';
    },
    async getDependents() {
      const familyResponse = await DataService.getPatientFamily();
      this.dependents = familyResponse.data.filter((pat) => pat.id !== this.patient.id);

      this.hasDependents = this.dependents.length > 0 ? true : false;
    },
  },
  created() {},
  async mounted() {
    const patientResp = await DataService.getPatientInfo();
    this.patient = patientResp.data;

    await this.getDependents();

    this.loading = false;
  },
};
</script>
<style scoped>
.dependentListItem {
  display: unset;
  margin-top: 0px;
  margin-bottom: 0px;
}
.formsList {
  display: unset;
}
</style>

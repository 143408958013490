<template>
  <v-container class="pa-0">
    <!-- Medical Title -->
    <v-row>
      <v-col class="d-flex justify-center">
        <h2 class="secondary--text">Medical</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="secondary white--text d-flex justify-center">
        <!-- Last Name, First Name (mm/dd/yyyy) ribbon -->
        {{ patient.lastName }}, {{ patient.firstName }} ({{ patient.birthDate }})
      </v-col>
    </v-row>

    <!-- Medical Condition Questionnaire -->
    <v-row>

      <v-col cols="12" class="px-3 pb-0 justify-center">
        <v-card class="d-flex align-center">
          <v-col cols="8" sm="6" md="4" lg="3" xl="2" class="pa-0">
            <v-card-title class="body-1 pl-0"> Heart / Circulation </v-card-title>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-btn-toggle
              v-model="hasCondHeart"
              mandatory
              class="white--text">
              <v-btn small> No </v-btn>
              <v-btn small> Yes </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-card>
        <v-card v-if="hasCondHeart">
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0" v-for="item in itemsHeart" :key="item.key">
              <v-checkbox 
                v-model="item.selected"
                class="ma-0"
                :label="item.label"
                :value="item.id">
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" class="px-3 pb-0">
        <v-card class="d-flex align-center">
          <v-col cols="8" sm="6" md="4" lg="3" xl="2" class="pa-0">
            <v-card-title class="body-1 pl-0">
              Blood Disorder / Cancer
            </v-card-title>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-btn-toggle
              v-model="hasCondBDC"
              mandatory
              class="white--text">
              <v-btn small> No </v-btn>
              <v-btn small> Yes </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-card>
        <v-card v-if="hasCondBDC">
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0" v-for="item in itemsBD" :key="item.key">
              <v-checkbox 
                v-model="item.selected"
                class="ma-0"
                :label="item.label"
                :value="item.id">
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" class="px-3 pb-0">
        <v-card class="d-flex align-center">
          <v-col cols="8" sm="6" md="4" lg="3" xl="2" class="pa-0">
            <v-card-title class="body-1 pl-0">
              Liver / Kidney / Intestine
            </v-card-title>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-btn-toggle
              v-model="hasCondLKI"
              mandatory
              class="white--text">
              <v-btn small> No </v-btn>
              <v-btn small> Yes </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-card>
        <v-card v-if="hasCondLKI">
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0" v-for="item in itemsLKI" :key="item.key">
              <v-checkbox 
                v-model="item.selected"
                class="ma-0"
                :label="item.label"
                :value="item.id">
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" class="px-3 pb-0">
        <v-card class="d-flex align-center">
          <v-col cols="8" sm="6" md="4" lg="3" xl="2" class="pa-0">
            <v-card-title class="body-1 pl-0">
              Bone or joint Conditions
            </v-card-title>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-btn-toggle
              v-model="hasCondBJC"
              mandatory
              class="white--text">
              <v-btn small> No </v-btn>
              <v-btn small> Yes </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-card>
        <v-card v-if="hasCondBJC">
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0" v-for="item in itemsBJC" :key="item.key">
              <v-checkbox 
                v-model="item.selected"
                class="ma-0"
                :label="item.label"
                :value="item.id">
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" class="px-3 pb-0">
        <v-card class="d-flex align-center">
          <v-col cols="8" sm="6" md="4" lg="3" xl="2" class="pa-0">
            <v-card-title class="body-1 pl-0">
              Lung / Endocrine / Tobacco
            </v-card-title>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-btn-toggle
              v-model="hasCondLET"
              mandatory
              class="white--text">
              <v-btn small> No </v-btn>
              <v-btn small> Yes </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-card>
        <v-card v-if="hasCondLET">
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0" v-for="item in itemsLET" :key="item.key">
              <v-checkbox 
                v-model="item.selected"
                class="ma-0"
                :label="item.label"
                :value="item.id">
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" class="px-3 pb-0">
        <v-card class="d-flex align-center">
          <v-col cols="8" sm="6" md="4" lg="3" xl="2" class="pa-0">
            <v-card-title class="body-1 pl-0">
              For Women
            </v-card-title>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-btn-toggle
              v-model="hasCondWomen"
              mandatory
              class="white--text">
              <v-btn small> No </v-btn>
              <v-btn small> Yes </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-card>
        <v-card v-if="hasCondWomen">
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0" v-for="item in itemsWomen" :key="item.key">
              <v-checkbox 
                v-model="item.selected"
                class="ma-0"
                :label="item.label"
                :value="item.id">
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" class="px-3 pb-0">
        <v-card class="d-flex align-center">
          <v-col cols="8" sm="6" md="4" lg="3" xl="2" class="pa-0">
            <v-card-title class="body-1 pl-0"> Other Conditions </v-card-title>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-btn-toggle
              v-model="hasCondOther"
              mandatory
              class="white--text">
              <v-btn small> No </v-btn>
              <v-btn small> Yes </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-card>
        <v-card v-if="hasCondOther">
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0">
              <v-text-field v-model="otherConditions" outlined></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" class="px-3 pb-0">
        <v-card class="d-flex align-center">
          <v-col cols="8" sm="6" md="4" lg="3" xl="2" class="pa-0">
            <v-card-title class="body-1 pl-0"> Allergies </v-card-title>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-btn-toggle
              v-model="hasAllergies"
              mandatory
              class="white--text">
              <v-btn small> No </v-btn>
              <v-btn small> Yes </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-card>
        <v-card v-if="hasAllergies">
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0" v-for="item in itemsAllergies" :key="item.key">
              <v-checkbox 
                v-model="item.selected"
                class="ma-0"
                :label="item.label"
                :value="item.id">
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" class="px-3 pb-5">
        <v-card class="d-flex align-center">
          <v-col cols="8" sm="6" md="4" lg="3" xl="2" class="pa-0">
            <v-card-title class="body-1 pl-0">
              Are you taking medications?
            </v-card-title>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-btn-toggle
              v-model="hasCurrMedications"
              mandatory
              class="white--text">
              <v-btn small> No </v-btn>
              <v-btn small> Yes </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-card>
        <v-card v-if="hasCurrMedications">
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0">
              <v-combobox
                class="pt-3"
                v-model="medications"
                label="Specify Medications (press tab to separate)"
                multiple
                chips
                outlined />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <Disclaimer ref="disclaimer" />
  </v-container>
</template>

<script>
import DataService from '../../../_services/DataService';
import Disclaimer from './Disclaimer.vue';
import { jsPDF } from "jspdf";

export default {
  name: 'MedicalForm',
  props: ['patient'],
  components: {
    Disclaimer,
  },
  data() {
    return {
      hasCondHeart: 0,
      hasCondBDC: 0,
      hasCondLKI: 0,
      hasCondBJC: 0,
      hasCondLET: 0,
      hasCondWomen: 0,
      hasCondOther: 0,
      hasAllergies: 0,
      hasCurrMedications: 0,
      itemsHeart: [],
      itemsBD: [],
      itemsLKI: [],
      itemsBJC: [],
      itemsLET: [],
      itemsWomen: [],
      otherConditions: '',
      itemsAllergies: [],
      medications: [],
      form: {
        id: 3,
      },
    };
  },
  methods: {
    addMedicationField() {
      this.medicationFields.push({ 
        value: "",
      })
    },
    removeMedicationField(index) {
      this.medicationFields.splice(index, 1)
    },
    async validate() {
      return this.$refs.disclaimer.validate();
    },
    async sendForm(base64PDF) {
      this.form = {
        "id": 3,
        "heartIds": this.getSelectedIds(this.itemsHeart),
        "BDIds": this.getSelectedIds(this.itemsBD),
        "LKIIds": this.getSelectedIds(this.itemsLKI),
        "BJCIds": this.getSelectedIds(this.itemsBJC),
        "LETIds": this.getSelectedIds(this.itemsLET),
        "WomenIds": this.getSelectedIds(this.itemsWomen),
        "otherConditions": this.otherConditions,
        "allergyIds": this.getSelectedIds(this.itemsAllergies),
        "medications": this.medications,
        "base64PDF": base64PDF,
        "signature": this.$refs.disclaimer.$refs.signaturePad._data.signatureData
      }

      const response = await DataService.postNewPatRegistration(this.form, this.patient.id);
      if (response.status === 200) {
        this.$emit('goToNextForm');
        this.$root.$emit('raise_alert', { color: 'success', text: 'Your entry has been recorded.' });
      } else {
        this.$emit('submitFailure');
        this.$root.$emit('raise_alert', { color: 'error', text: 'We were unable to save your medical info, please try again or contact our team if the issue persists' });
      }
    },
    getSelectedIds(array) {
      return array.filter(item => item.selected).map(item => item.id);
    },
    async getData() {
      const response = await DataService.getClientDiseaseCategories();
      const heartAndCirculation = response.data.find((cat) => cat.category === 'Heart / Circulation');
      const bloodAndCancer = response.data.find((cat) => cat.category === 'Blood Disorder / Cancer');
      const liverKidneyIntestine = response.data.find((cat) => cat.category === 'Liver / Kidney / Intestine');
      const boneAndJoint = response.data.find((cat) => cat.category === 'Bone / Joint Conditions');
      const lungEndocrineTabacco = response.data.find((cat) => cat.category === 'Lung / Endocrine / Tobacco');
      const women = response.data.find((cat) => cat.category === 'Women');

      let heartIds = heartAndCirculation.openDentalDiseaseIds;
      let BDIds = bloodAndCancer.openDentalDiseaseIds;
      let LKIIds = liverKidneyIntestine.openDentalDiseaseIds;
      let BJCIds = boneAndJoint.openDentalDiseaseIds;
      let LETIds = lungEndocrineTabacco.openDentalDiseaseIds;
      let womenIds = women.openDentalDiseaseIds;

      // get previously selected diseases if form was already completed
      let patientDiseases = await DataService.getPatientDiseases(this.patient.id);
      let previouslySelectedDiseases = patientDiseases.data.map(obj => obj.DiseaseDefNum);

      // get all diseases, selected true if previously checked
      let diseases = await DataService.getDiseaseDefinitions().then(res => {
        return res.data.map(item => ({id:item.DiseaseDefNum, label: item.DiseaseName, selected: previouslySelectedDiseases.includes(item.DiseaseDefNum) ? [item.DiseaseDefNum] : false}))
      });

      // place items in correct categories and toggle UI if includes previously selected
      this.itemsHeart = diseases.filter(item => heartIds.includes(item.id));
      this.hasCondHeart = this.itemsHeart.some(item => item.selected !== false) ? 1 : 0;

      this.itemsBD = diseases.filter(item => BDIds.includes(item.id));
      this.hasCondBDC = this.itemsBD.some(item => item.selected !== false) ? 1 : 0;

      this.itemsLKI = diseases.filter(item => LKIIds.includes(item.id));
      this.hasCondLKI = this.itemsLKI.some(item => item.selected !== false) ? 1 : 0;

      this.itemsBJC = diseases.filter(item => BJCIds.includes(item.id));
      this.hasCondBJC = this.itemsBJC.some(item => item.selected !== false) ? 1 : 0;

      this.itemsLET = diseases.filter(item => LETIds.includes(item.id));
      this.hasCondLET = this.itemsLET.some(item => item.selected !== false) ? 1 : 0;

      this.itemsWomen = diseases.filter(item => womenIds.includes(item.id));
      this.hasCondWomen = this.itemsWomen.some(item => item.selected !== false) ? 1 : 0;

      // get previously selected allergies if form was already completed
      let patientAllergies = await DataService.getPatientAllergies(this.patient.id);
      let previouslySelectedAllergies = patientAllergies.data.map(obj => obj.AllergyDefNum);
      
      // get all allergies
      this.itemsAllergies = await DataService.getAllergyDefinitions().then(res => {
        return res.data.map(item => ({id: item.AllergyDefNum, label: item.Description, selected: previouslySelectedAllergies.includes(item.AllergyDefNum) ? [item.AllergyDefNum] : false}));
      })
      this.hasAllergies = patientAllergies.data.length > 0 ? 1 : 0;

      // get previously selected medications if form was already completed
      let patientMedications = await DataService.getPatientMedications(this.patient.id);
      this.medications = patientMedications.data.map(obj => obj.MedName);
      this.hasCurrMedications = patientMedications.data.length > 0 ? 1 : 0;
    },
    async generatePDF() {
      let y = 20;
      const doc = new jsPDF();
      doc.setFontSize(10).text(10, y, `Medical`);

      y = this.generateLinesForSection(doc, y, 'Heart / Circulation Conditions', this.hasCondHeart, this.itemsHeart);
      y = this.generateLinesForSection(doc, y, 'Blood Disorder / Cancer', this.hasCondBDC, this.itemsBD);
      y = this.generateLinesForSection(doc, y, 'Liver / Kidney / Intestine', this.hasCondLKI, this.itemsLKI);
      y = this.generateLinesForSection(doc, y, 'Bone or joint Conditions', this.hasCondBJC, this.itemsBJC);
      y = this.generateLinesForSection(doc, y, 'Lung / Endocrine / Tobacco', this.hasCondLET, this.itemsLET);
      y = this.generateLinesForSection(doc, y, 'Women', this.hasCondWomen, this.itemsWomen);
      y = this.generateLinesForSection(doc, y, 'Other Conditions', this.hasCondOther, this.otherConditions);
      y = this.generateLinesForSection(doc, y, 'Allergies', this.hasAllergies, this.itemsAllergies);
      y = this.generateLinesForSection(doc, y, 'Current Medications', this.hasCurrMedications, this.medications, (item) => item);

      doc.addPage();
      y = 20;

      doc.text(10, y+=5, `Relationship to Patient: ${this.$refs.disclaimer.relationshipToPatient}`);

      if (this.$refs.disclaimer.relationshipToPatient !== 'Self') {
        doc.text(10, y+=5, `Name if not Patient: ${this.$refs.disclaimer.nameIfNotPatient}`);
      }

      doc.text(10, y+=5, `Date Signed: ${new Date().toISOString()}`);

      doc.addImage(this.$refs.disclaimer.$refs.signaturePad._data.signatureData, 'JPEG', 10, y+=10, 100, 70, undefined, 'FAST');

      await this.sendForm(doc.output('datauristring').split(',')[1], this.patient.id)
    },
    generateLinesForSection(doc, yStart, sectionName, sectionWasChecked, sectionItems, customTextFn) {
      let updatedY = yStart;

      if (updatedY > 285) {
        doc.addPage();
        updatedY = 20;
      }

      doc.setFont(undefined, 'bold').text(10, updatedY += 5, sectionName).setFont(undefined, 'normal')

      if (sectionWasChecked) {
        for (const item of sectionItems) {
          if (updatedY > 285) {
            doc.addPage();
            updatedY = 20;
          }
          
          doc.text(10, updatedY += 5, customTextFn ? customTextFn(item) : `- ${item.label}: ${item.selected ? 'Yes' : 'No'}`)
        }
      } else {
        doc.text(10, updatedY += 5, `- Patient indicated no conditions in this section`)
      }

      return updatedY;
    }
  },
  async mounted() {
    await this.getData();
  },
};
</script>
<style scoped>
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 1;
  color: #1182d5;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  background-color: white;
}
.v-sheet.v-list:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%),
    0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
}
</style>

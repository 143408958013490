<template>
  <v-container>
    <div v-if="loading" class="text-center">
      <v-progress-circular
        indeterminate
        color="primary"
        style="height: 50%; margin: auto; padding-top: 50%;" />
    </div>
    <v-row v-if="!loading">
      <v-col cols="4">
        <h2 class="dashtitle">Forms</h2>
        <v-card outlined>
          <v-list>
            <v-list-item-group active-class="highlighted">
              <v-list-item v-for="(form, i) in forms" :key="i" :id="form.id" @click="selectForm(form)" class="my-n1">
                <v-btn class="py-2" width="100%" outlined>
                  <v-list-item-content>
                    <v-list-item-title>{{ form.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-btn>
              </v-list-item>
            </v-list-item-group>
            <span class="pa-4" v-if="!forms.length">No forms have been configured yet.</span>
          </v-list>
        </v-card>
        <h2 class="dashtitle">Disease Question Groupings</h2>
        <v-card outlined>
          <v-list>
            <v-list-item-group active-class="highlighted">
              <v-list-item @click="selectCategory()" class="my-n1">
                <v-btn class="py-2" width="100%" outlined>
                  <v-list-item-content>
                    <v-list-item-title>Add a new grouping</v-list-item-title>
                  </v-list-item-content>
                </v-btn>
              </v-list-item>
              <v-list-item v-for="(category, i) in diseaseQuestionGroupings" :key="i" @click="selectCategory(category)" class="my-n1">
                <v-btn class="py-2" width="100%" outlined>
                  <v-list-item-content>
                    <v-list-item-title>{{ category.category }}</v-list-item-title>
                  </v-list-item-content>
                </v-btn>
              </v-list-item>
            </v-list-item-group>
            <span class="pa-4" v-if="!forms.length">No forms have been configured yet.</span>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="8" v-if="selectedForm">
        <v-card>
          <v-form ref="form" @submit.prevent="submit" lazy-validation>
            <v-sheet class="py-2 px-1">
              <div class="d-flex justify-end">
                <v-btn @click="createNewForm" color="#6bb2f4" class="white--text mr-3" dense>
                  Create New Form
                </v-btn>
                <v-btn @click="saveForm()"  color="#6bb2f4" class="white--text mr-3" dense>
                  Save
                </v-btn>
                <v-btn @click="showDeleteDialog = true" style="color: #ffffff; font-weight: 700" class="deleteBtnColor mr-3" dense>
                  Delete
                </v-btn>
              </div>

              <v-text-field
                label="Title"
                hide-details="auto"
                class="my-4"
                placeholder="New Form"
                v-model="selectedForm.name"
                validate-on-blur
                :rules="[rules.required, rules.max50]" />

              <v-checkbox label="Assign to patients at signup?" v-model="selectedForm.assignAtSignUp" class="py-0 my-0" />

              <v-card outlined @click="goToTemplateEditor">
                <div v-if="selectedForm.body">
                  <iframe
                    style="
                      width: 100%;
                      cursor: pointer;
                      height: 55vh;
                      border: none"
                    :srcdoc="selectedForm.body" />
                  <div
                    style="
                      position: absolute;
                      left: 0;
                      top: 0;
                      width: 100%;
                      height: 100%;
                      background-color: rgba(0, 0, 0, 0.2);"
                    >
                  </div>
                </div>
                <span v-else style="cursor: pointer;">
                  &lt;Click here to go to form editor&gt;
                </span>
              </v-card>

              <v-dialog v-model="showDeleteDialog" persistent width="290" max-height="290">
                <v-card>
                  <v-card-title>Delete</v-card-title>
                  <v-card-text>Are you sure you delete this form?</v-card-text>
                  <v-card-actions>
                    <v-btn outlined color="danger" text @click="deleteForm()">Delete</v-btn>
                    <v-btn outlined text @click="showDeleteDialog = false">Cancel</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-sheet>
          </v-form>
        </v-card>
      </v-col>
      <v-col cols="8" v-if="selectedCategory">
        <v-card class="mt-6">
          <div class="d-flex justify-end">
            <v-btn @click="saveCategory()"  color="#6bb2f4" class="white--text mr-3" dense>
              Save
            </v-btn>
            <v-btn @click="showDeleteCategoryDialog = true" style="color: #ffffff; font-weight: 700" class="deleteBtnColor mr-3" dense>
              Delete
            </v-btn>
          </div>

          <v-text-field
            label="Title"
            hide-details="auto"
            class="my-4"
            placeholder="New Grouping"
            v-model="selectedCategory.category"
            validate-on-blur
            :rules="[rules.required, rules.max50]" />

          <h4>Included conditions</h4>

          <v-autocomplete
            class="pt-3"
            v-model="selectedCategory.openDentalDiseaseIds"
            :items="allOpenDentalDiseases"
            item-text="DiseaseName"
            item-value="DiseaseDefNum"
            label="Select Disease Mappings"
            multiple
            outlined
            dense />

          <v-dialog v-model="showDeleteCategoryDialog" persistent width="290" max-height="290">
            <v-card>
              <v-card-title>Delete</v-card-title>
              <v-card-text>Are you sure you delete this grouping?</v-card-text>
              <v-card-actions>
                <v-btn outlined color="danger" text @click="deleteCategory()">Delete</v-btn>
                <v-btn outlined text @click="showDeleteCategoryDialog = false">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DataService from '../../../_services/DataService';
import ValidateService from '../../../_services/ValidateService';

export default {
  name: 'FormManagement',
  data() {
    return {
      forms: [],
      diseaseQuestionGroupings: [],
      selectedForm: {},
      selectedCategory: null,
      allOpenDentalDiseases: [],
      loading: true,
      showDeleteDialog: false,
      showDeleteCategoryDialog: false,
      rules: ValidateService.validators,
    };
  },
  methods: {
    async closeAndReload() {
      await this.getClientInfo();
      this.dialogSuccess = false;
    },
    createNewForm() {
      if (Object.keys(this.selectedForm).length && !this.selectedForm.id) {
        return;
      }

      this.selectedForm = {
        name: 'New Form',
        assignAtSignUp: false,
        json: {},
        body: '',
      };

      this.forms.unshift(this.selectedForm);

      if (this.$route.query.id) {
        this.$router.replace({ query: { tab: 'form management', id: '' } });
      }
    },
    async getDiseaseDefinitions() {
      const response = await DataService.getDiseaseDefinitions();
      if (response.status === 200) {
        this.allOpenDentalDiseases = response.data;
      }
    },
    async getClientDiseaseCategories() {
      const response = await DataService.getClientDiseaseCategories();
      if (response.status === 200) {
        this.diseaseQuestionGroupings = response.data;
      }
    },
    async getClientForms() {
      const response = await DataService.getClientForms();
      if (response.status === 200) {
        this.forms = response.data;
      }
    },
    async saveForm(disableAlert) {
      if (this.selectedForm.id) {
        const response = await DataService.updateForm(this.selectedForm.id, { name: this.selectedForm.name, assignAtSignUp: this.selectedForm.assignAtSignUp });
        if (response.status === 200) {
          if (!disableAlert) {
            this.$root.$emit('raise_alert', { color: 'success', text: 'Saved' });
          }

          return true;
        }

        this.$root.$emit('raise_alert', { color: 'info', text: 'Unable to save' });
      } else {
        const response = await DataService.createForm(this.selectedForm);
        
        if (response.status === 201) {
          this.$router.push({ query: { tab: 'form management', id: response.data.id }});
          if (!disableAlert) {
            this.$root.$emit('raise_alert', { color: 'success', text: 'Saved' });
          }

          return true;
        }

        this.$root.$emit('raise_alert', { color: 'info', text: 'Unable to save' });
      }

      return false;
    },
    async saveCategory() {
      if (this.selectedCategory.id) {
        const response = await DataService.updateClientDiseaseCategory(this.selectedCategory.id, this.selectedCategory);
        if (response.status === 200) {
          this.$root.$emit('raise_alert', { color: 'success', text: 'Saved' });
        } else {
          this.$root.$emit('raise_alert', { color: 'info', text: 'Unable to save' });
        }
      } else {
        const response = await DataService.createClientDiseaseCategory(this.selectedCategory);
        
        if (response.status === 201) {
          this.diseaseQuestionGroupings.push(response.data);
          this.selectedCategory = this.diseaseQuestionGroupings[this.diseaseQuestionGroupings.length - 1];
        } else {
          this.$root.$emit('raise_alert', { color: 'info', text: 'Unable to save' });
        }
      }
    },
    async deleteForm() {
      const id = this.selectedForm.id;
      if (!id) {
        return;
      }

      const response = await DataService.deleteForm(id);
      if (response.status == 200) {
        this.showDeleteDialog = false;

        this.$root.$emit('raise_alert', { color: 'success', text: 'Form deleted' });

        await this.getClientForms();

        document.getElementById('templateList').firstChild.click();
      } else {
        this.$root.$emit('raise_alert', { color: 'error', text: 'Failed to delete form' });
      }
    },
    async deleteCategory() {
      const response = await DataService.deleteClientDiseaseCategory(this.selectedCategory.id);
      if (response.status == 200) {
        this.showDeleteCategoryDialog = false;

        this.$root.$emit('raise_alert', { color: 'success', text: 'Grouping deleted' });

        await this.getClientDiseaseCategories();

        document.getElementById('templateList').firstChild.click();
      } else {
        this.$root.$emit('raise_alert', { color: 'error', text: 'Failed to delete grouping' });
      }
    },
    selectForm(form) {
      this.selectedCategory = null;
      this.selectedForm = form;

      if (form.id && Number(this.$route.query.id) !== form.id) {
        this.$router.replace({ path: this.$route.path, query: { tab: 'form management', id: form.id }});
      }
    },
    selectCategory(category) {
      this.selectedForm = null;

      if (category) {
        this.selectedCategory = category;
      } else {
        this.selectedCategory = {
          category: '',
          openDentalDiseaseIds: [],
        };
      }
    },
    async goToTemplateEditor() {
      const saveResult = await this.saveForm(true);
      if (saveResult) {
        this.$router.push({
          name: 'EmailTemplateEditor',
          query: {
            id: this.$route.query.id,
            type: 'form',
          },
        });
      }
    }
  },
  async mounted() {
    this.loading = true;

    await Promise.all([
      this.getDiseaseDefinitions(),
      this.getClientDiseaseCategories(),
      this.getClientForms(),
    ]);

    if (this.$route.query.id) {
      setTimeout(() => {
        const element = document.getElementById(this.$route.query.id);
        if (element) {
          element.click();
        }
      }, 200);
    }

    this.loading = false;
  },
};
</script>
<style>
.highlighted button {
  background-color: #b0d35e !important;
  color: #414a5b !important;
}
</style>
<template>
  <v-bottom-navigation class="d-lg-none" width="100vw" height="auto" fixed app>
    <v-tabs grow height="75">
      <v-tab
        v-for="item in menuArray"
        :key="item.key"
        class="mobile-nav d-flex flex-column"
        @click="navigateTo(item)"
      >
        <v-icon size="x-large" class="pb-1">{{ item.icon }}</v-icon>
        <span style="font-size: 65%;">{{ item.title }}</span>
      </v-tab>
    </v-tabs>
  </v-bottom-navigation>
</template>
<script>
import DataService from '../../_services/DataService';
export default {
  data() {
    return {
      permission: '',
      menuArray: [],
      patient: [
          {
            icon: 'mdi-monitor-dashboard',
            title: 'Dashboard',
            link: '/dashboard',
          },
          {
            icon: 'mdi-calendar-month',
            title: 'Schedule',
            link: '/scheduled_appointments',
          },
          {
            icon: 'mdi-account-circle-outline',
            title: 'Account',
            link: '/account',
          },
          {
            title: 'Logout',
            click: 'onLogout',
            icon: 'mdi-logout',
            active: 1,
          },
        ],
        clientAdmin: [
          {
            icon: 'mdi-monitor-dashboard',
            title: 'Dashboard',
            link: '/dashboard',
          },
          {
            icon: 'mdi-calendar-month',
            title: 'Schedule',
            link: '/schedule',
          },
          {
            icon: 'mdi-email',
            title: 'Messaging',
            link: '/messaging',
          },
          {
            icon: 'mdi-shield-account-outline',
            title: 'Admin',
            link: '/admin',
          },
          {
            icon: 'mdi-account-circle-outline',
            title: 'Account',
            link: '/account',
          },
          {
            title: 'Logout',
            click: 'onLogout',
            icon: 'mdi-logout',
            active: 1,
          },
        ],
    };
  },
  mounted(){
      switch (DataService.getRole()) {
        case 'clientAdmin':
          this.menuArray = this.clientAdmin;
          break;
        case 'patient':
          this.menuArray = this.patient;
          break;
        default:
          this.menuArray = [];
      }
  },
  methods: {
    navigateTo(route) {
      route.link ? this.$router.push(route.link) : this.onLogout();
    },
    onLogout() {
      this.$root.$emit('logout');
      DataService.logout();
    },
  },
  
};
</script>
<style>
/* place tabs slider on top */
.v-tabs-slider {
  transform: translateY(-73px) !important;
}

/* remove unnecessary whitespace */
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: none;
}

/* background color of mobile nav */
/* .v-slide-group__wrapper {
  background-color: #429eb911;
} */
</style>

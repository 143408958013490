<template>
  <v-container class="pa-0">
    <!-- Dental Title -->
    <v-row>
      <v-col class="d-flex justify-center">
        <h2 class="secondary--text">Office Policy</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="secondary white--text d-flex justify-center">
        {{ patient.lastName }}, {{ patient.firstName }} ({{ patient.birthDate }})
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center">
        <v-img max-height="300" max-width="450" src="https://s3.amazonaws.com/gr-share-us/email-marketing/message-templates/Sk/cadd1fc6-299a-43d8-9fa9-3f12bef208a0.jpg">
        </v-img>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <h4>
          Thank you so much for choosing {{ clientName }} for your oral health needs! We are looking forward to working with you to meet your oral health goals by providing you with the tools, education and treatments to help promote overall wellness in your life. Before we get started, please review the below office policy to make sure we all have a healthy relationship and good experiences moving forward.
        </h4>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>Appointment/Reservation Cancellations:</b> Our dental team loves helping patients and our patients love coming to see us! When you make an appointment we reserve time, space and a provider especially for you. Should you have an emergency requiring you to move an appointment, please let us know as soon as possible! That allows us time to provide care to someone else who really needs it.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>Cancellation/No-show fees:</b> We understand that sometimes things come up last minute and we are happy to work with you on those to get your care rescheduled ASAP. Should a patient have more than one cancellation with less than 2 business days advance notice in a 12 month period, you agree to pay a $45 fee for hygiene/cleaning visits and/or a $90 fee for doctor visits. Each additional no show appointment will come with an additional, appropriate charge.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>Minor patients and appointments:</b> Patients under the age of 18 or anyone with an appointed guardian/power of attorney must arrive for their dental appointments with a guardian present for the entirety of their treatment or appropriate consent forms must be signed and returned to the office prior to the appointment time. Payment is due at the time of service for all patients, so please make arrangements for payment in advance.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>Dental Benefits ["Insurance"]:</b> We are happy to work with you and your dental benefit provider in the capacity that we will submit all necessary paperwork on your behalf. Your dental benefit provider is a for-profit company and not your friend or fiduciary. Please recognize that your dental benefit provider has a vested interest in you paying your premiums each month and the company's shareholders are much happier not paying for any of your services. You are ultimately responsible for paying for the services you choose to have completed. After 30 days of attempts for reimbursement from your dental benefit company we will bill you for the full amount and supply you with any necessary clinical documentation to submit for payment. After 60 days of non-payment you will have a monthly finance charge of 1.5% applied to your account. {{ clientName }} is not responsible for changes to your dental benefit plan that you or your employer may have changed, renegotiated or otherwise altered in between visits. We will make every effort to work with you to maximize the benefits you recieve from your dental benefit provider but ultimately the person who has entered into the contract with the dental benefit provider is you. Please provide us with any updated dental benefit information before your appointment so our team can help you minimize headaches associated with utilizing dental benefits.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>Payment for services rendered:</b> Payment is due at the time of service unless written payment arrangements are made with the financial coordinator in advance. For your convenience we accept Visa/Mastercard and also offer patient financing which can offer low interest payments made over time. Our office can extend a 5% discount to patients who do not utilize dental benefit coverage and who pay in full via cash or check on the day of service.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>Amalgam or Silver Filling Material:</b> Regardless of what your insurance company will cover, we only place resin ("tooth colored") fillings. Placing amalgam ("silver") fillings may cause a health risk to the providers who handle the material frequently. Due to that risk, {{ clientName }} will not place silver type fillings. Silver and tooth colored fillings have similar wear, longevity, and survival properties and are a safer alternative for our team.
        </p>
      </v-col>
    </v-row>

    <Disclaimer ref="disclaimer" agreement-text="true"/>

  </v-container>
</template>

<script>
import DataService from '../../../_services/DataService';
import Disclaimer from './Disclaimer.vue';
import { jsPDF } from "jspdf";

export default {
  name: 'OfficePolicyForm',
  props: ['patient'],
  components: {
    Disclaimer,
  },
  data() {
    return {
      clientName: '',
      form: {
        id: 5,
      }
    };
  },
  methods: {
    async validate() {
      return this.$refs.disclaimer.validate();
    },
    async generatePDF() {
      let y = 20;
      const doc = new jsPDF('p', 'pt', 'a4');
      doc.setFont(undefined, 'bold').text(10, y, `Office Policy Form`).setFont(undefined, 'normal').setFontSize(10);
      doc.text(10, y+=20, `Thank you so much for choosing ${this.clientName} for your oral health needs! We are looking forward to working with you to meet your oral health goals by providing you with the tools, education and treatments to help promote overall wellness in your life. Before we get started, please review the below office policy to make sure we all have a healthy relationship and good experiences moving forward.`, { maxWidth: 500 });
      doc.text(10, y+=50, `Appointment/Reservation Cancellations: Our dental team loves helping patients and our patients love coming to see us! When you make an appointment we reserve time, space and a provider especially for you. Should you have an emergency requiring you to move an appointment, please let us know as soon as possible! That allows us time to provide care to someone else who really needs it.`, { maxWidth: 500 });
      doc.text(10, y+=50, `Cancellation/No-show fees: We understand that sometimes things come up last minute and we are happy to work with you on those to get your care rescheduled ASAP. Should a patient have more than one cancellation with less than 2 business days advance notice in a 12 month period, you agree to pay a $45 fee for hygiene/cleaning visits and/or a $90 fee for doctor visits. Each additional no show appointment will come with an additional, appropriate charge.`, { maxWidth: 500 });
      doc.text(10, y+=60, `Minor patients and appointments: Patients under the age of 18 or anyone with an appointed guardian/power of attorney must arrive for their dental appointments with a guardian present for the entirety of their treatment or appropriate consent forms must be signed and returned to the office prior to the appointment time. Payment is due at the time of service for all patients, so please make arrangements for payment in advance.`, { maxWidth: 500 });
      doc.text(10, y+=60, `Dental Benefits ["Insurance"]: We are happy to work with you and your dental benefit provider in the capacity that we will submit all necessary paperwork on your behalf. Your dental benefit provider is a for-profit company and not your friend or fiduciary. Please recognize that your dental benefit provider has a vested interest in you paying your premiums each month and the company's shareholders are much happier not paying for any of your sevices. You are ultimately responsible for paying for the services you choose to have completed. After 30 days of attempts for reimbursement from your dental benefit company we will bill you for the full amount and supply you with any necessary clinical documentation to submit for payment. After 60 days of non-payment you will have a monthly finance charge of 1.5% applied to your account. ${this.clientName} is not responsible for changes to your dental benefit plan that you or your employer may have changed, renegotiated or otherwise altered in between visits. We will make every effort to work with you to maximize the benefits you recieve from your dental benefit provider but ultimately the person who has entered into the contract with the dental benefit provider is you. Please provide us with any updated dental benefit information before your appointment so our team can help you minimize headaches associated with utilizing dental benefits.`, { maxWidth: 500 });
      doc.text(10, y+=160, `Payment for services rendered: Payment is due at the time of service unless written payment arrangements are made with the financial coordinator in advance. For your convenience we accept Visa/Mastercard and also offer patient financing which can offer low interest payments made over time. Our office can extend a 5% discount to patients who do not utilize dental benefit coverage and who pay in full via cash or check on the day of service. Amalgam or Silver Filling Material: Regardless of what your insurance company will cover, we only place resin ("tooth colored") fillings. Placing amalgam ("silver") fillings may cause a health risk to the providers who handle the material frequently. Due to that risk, ${this.clientName} will not place silver type fillings. Silver and tooth colored fillings have similar wear, longevity, and survival properties and are a safer alternative for our team. I have read, agree to, and understand fully the office policies of ${this.clientName}.`, { maxWidth: 500 });
      doc.text(10, y+=115, `Date Signed: ${new Date().toISOString()}`);
      doc.addImage(this.$refs.disclaimer.$refs.signaturePad._data.signatureData, 'JPEG', 10, y+=5, 200, 150, undefined, 'FAST');
      await this.sendForm(doc.output('datauristring').split(',')[1])
    },
    async sendForm(base64PDF) {
      this.form.base64PDF = base64PDF;

      const response = await DataService.postNewPatRegistration(this.form, this.patient.id);
      if (response.status === 200) {
        this.$emit('goToNextForm');
        this.$root.$emit('raise_alert', { color: 'success', text: 'Your entry has been recorded.' });
      } else {
        this.$emit('submitFailure');
        this.$root.$emit('raise_alert', { color: 'error', text: 'We were unable to save your confirmation, please try again or contact our team if the issue persists' });
      }
    },
  },
  async mounted() {
    const { name } = await DataService.getClientDetails();
    this.clientName = name;
  },
};
</script>
<style scoped>
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 1;
  color: #1182d5;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  background-color: white;
}
.v-sheet.v-list:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%),
    0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
}
</style>

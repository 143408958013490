import Admin from './pages/Admin';
import BillPay from './pages/BillPay';
import PatientTreatmentPlans from './pages/PatientTreatmentPlans';
import DataService from '../_services/DataService';
import Dashboard from './pages/Dashboard.vue';
import Email from './pages/Messaging/Email';
import EmailTemplateEditor from './pages/Messaging/EmailTemplateEditor';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login.vue';
import Messaging from './pages/Messaging';
import TemplateManager from './pages/Messaging/CreateEditMessage';
import NewAccount from './pages/NewAccount';
import NewAccountAppointment from './pages/NewAccountAppointment';
import PatientSignup from './pages/PatientSignup';
import PatientSchedule from './pages/PatientSchedule';
import Router from 'vue-router';
import PatientForm from './pages/PatientForm';
import ResetPassword from './pages/ResetPassword';
import ScheduledAppointments from './pages/ScheduledAppointments';
import ScheduleApptNoLogin from './pages/ScheduleAppointmentNoLogin';
import Settings from './pages/Settings';
import TwoFactorAuthentication from './pages/TwoFactorAuthentication.vue';
import Unauthorized from './pages/UnauthorizedAccess.vue';
import Unsubscribe from './pages/Settings/Unsubscribe.vue';
import Vue from 'vue';

Vue.use(Router);

async function guardMyroute(to, _from, next) {
  if (!localStorage.getItem('token')) {
    if (to.path == '/patientForms') {
      return next(`/login?redirectURI=${to.fullPath}`)
    }

    return next(`/login`);
  }

  const response = await DataService.validateToken({ token: localStorage.getItem('token') });
  if (response.status === 401) {
    return clearAuthenticationDataAndGoToLogin(next);
  }

  const { role, newToken } = response.data;
  localStorage.setItem('token', newToken);

  return validateUserNextPath(to, role)
      ? next()
      : next('/unauthorized_access');
}

function clearAuthenticationDataAndGoToLogin(next) {
  DataService.logout();
  return next('/login');
}

function checkIfLoggedIn(to, from, next) {
  if (localStorage.getItem('token')) {
    next('/dashboard')
  } else {
    next('/login');
  }
}

function validateUserNextPath(to, user_role) {
  const claimsGroups = to.meta.allowedGroups ? to.meta.allowedGroups : ['none'];
  if (claimsGroups.includes(user_role) || claimsGroups.includes('none')) {
    return true;
  } else {
    return false;
  }
}

export default new Router({
  routes: [
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      beforeEnter: guardMyroute,
    },
    {
      path: '/unauthorized_access',
      name: 'UnauthorizedAccess',
      component: Unauthorized,
      beforeEnter: guardMyroute,
    },
    {
      path: '/admin',
      name: 'Admin',
      component: Admin,
      beforeEnter: guardMyroute,
      meta: {allowedGroups: ['clientAdmin', 'clientUser']},
    },
    {
      path: '/messaging',
      name: 'Messaging',
      component: Messaging,
      beforeEnter: guardMyroute,
      meta: {allowedGroups: ['clientAdmin', 'clientUser']},
    },
    {
      path: '/email',
      name: 'Email',
      component: Email,
      beforeEnter: guardMyroute,
      meta: {allowedGroups: ['clientAdmin', 'clientUser']},
    },
    {
      path: '/email_template_editor',
      name: 'EmailTemplateEditor',
      component: EmailTemplateEditor,
      beforeEnter: guardMyroute,
      meta: {allowedGroups: ['clientAdmin', 'clientUser']},
    },
    {
      path: '/template_manager',
      name: 'TemplateManager',
      component: TemplateManager,
      beforeEnter: guardMyroute,
      meta: {allowedGroups: ['clientAdmin', 'clientUser']},
    },
    {
      path: '/patient_schedule',
      name: 'PatientSchedule',
      component: PatientSchedule,
      beforeEnter: guardMyroute,
    },
    {
      path: '/new_appointment',
      name: 'ScheduleApptNoLogin',
      component: ScheduleApptNoLogin,
    },
    {
      path: '/scheduled_appointments',
      name: 'ScheduledAppointments',
      component: ScheduledAppointments,
      beforeEnter: guardMyroute,
    },
    {
      path: '/account',
      name: 'Account',
      component: Settings,
      beforeEnter: guardMyroute,
    },
    {
      path: '/',
      name: 'root',
      beforeEnter: checkIfLoggedIn,
    },
    {
      path: '/new_account',
      name: 'NewAccount',
      component: NewAccount,
    },
    {
      path: '/new_account/appointment',
      name: 'NewAccountAppointment',
      component: NewAccountAppointment,
    },
    {
      path: '/patient_signup',
      name: 'PatientSignup',
      component: PatientSignup,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/forgot_password',
      name: 'ForgotPassword',
      component: ForgotPassword,
    },
    {
      path: '/reset_password',
      name: 'ResetPassword',
      component: ResetPassword,
    },
    {
      path: '/bill_pay',
      name: 'BillPay',
      component: BillPay,
    },
    {
      path: '/treatment_plan',
      name: 'PatientTreatmentPlans',
      component: PatientTreatmentPlans,
    },
    {
      path: '/unsubscribe',
      name: 'Unsubscribe',
      component: Unsubscribe,
    },
    {
      path: '/two_factor_authentication',
      name: 'TwoFactorAuthentication',
      component: TwoFactorAuthentication,
    },
    {
      path: '/patientForms',
      name: 'PatientForm',
      component: PatientForm,
      beforeEnter: guardMyroute,
    },
  ],
});

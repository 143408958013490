<template>
  <v-layout row wrap class="no-toppad" no-gutters text-xs-right>
    <v-container v-if="this.$vuetify.breakpoint.mdAndUp">
      <v-row>
        <v-col cols="3">
          <v-row class="mx-4 mt-3"> </v-row>
          <v-row class="mx-4 mt-3">
            <h2 class="dashtitle"><span>Manage Family</span></h2>
          </v-row>
          <v-row>
            <v-col cols="8">
              <v-text-field
                label="Search"
                v-model="search"
                outlined
                rounded
                dense></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn color="primary" @click="searchDependentData()"
                >Search</v-btn>
            </v-col>
          </v-row>
          <v-card
            class="justify-center"
            outlined
            style="height:70vh; overflow-y:scroll; border-radius: 20px">
            <div class="text-center">
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                style="
                  height: 50%;
                  margin: auto;
                  padding-top: 50%;
                "></v-progress-circular>
              <div
                v-if="!loading && !hasDependents"
                style="height: 50%; margin: auto; padding-top: 50%">
                <div>
                  <v-icon large>mdi-account-details</v-icon>
                  <p>No dependents for the selected patient.</p>
                </div>
              </div>
            </div>

            <div v-if="!loading">
              <v-list flat>
                <v-list-item-group>
                  <v-list-item
                    v-for="(item, i) in dependentList"
                    :key="i"
                    @click="selectDependent(item)"
                    active-class="highlighted"
                    id="dependentsList"
                    class="pb-2">
                    <v-btn
                      x-large
                      outlined
                      style="color: #0079c6; width: 100%"
                      elevation="3">
                      <v-avatar
                        size="36"
                        color="#0079C6"
                        style="color: white"
                        class="mr-3">
                        {{ item.firstName[0] + item.lastName[0] }}
                      </v-avatar>
                      <v-list-item-title style="color: #0079c6">
                        {{item.firstName + ' ' + item.lastName}}
                      </v-list-item-title>
                    </v-btn>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <div v-if="form" style="padding-top: 2vw; padding-left: 16px">
              <v-row class="row" style="margin: 0px">
                <h2>
                  <span>Dependent Information</span>
                </h2>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="form.firstName"
                    label="First Name"
                    outlined
                    dense />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="form.lastName"
                    label="Last Name"
                    outlined
                    dense />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    type="date"
                    v-model="form.birthDate"
                    label="Date Of Birth"
                    outlined
                    dense></v-text-field></v-col>
                <v-col cols="4"
                  ><v-text-field
                    v-model="form.email"
                    label="Email"
                    required
                    outlined
                    dense></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col cols="4"
                  ><v-text-field
                    v-model="form.address1"
                    label="Address Line 1"
                    outlined
                    dense></v-text-field></v-col>
                <v-col cols="4"
                  ><v-text-field
                    v-model="form.address2"
                    label="Address Line 2"
                    outlined
                    dense></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col cols="4"
                  ><v-text-field
                    v-model="form.city"
                    label="City"
                    outlined
                    dense></v-text-field></v-col>
                <v-col cols="4"
                  ><v-text-field
                    v-model="form.state"
                    label="State"
                    outlined
                    dense></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col cols="4"
                  ><v-text-field
                    v-model="form.postalCode"
                    label="Zip"
                    outlined
                    dense></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col cols="4"
                  ><v-text-field
                    v-model="form.homePhone"
                    label="Home Phone"
                    outlined
                    dense></v-text-field></v-col>
                <v-col cols="4"
                  ><v-text-field
                    v-model="form.workPhone"
                    label="Work Phone"
                    outlined
                    dense></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col cols="4"
                  ><v-text-field
                    v-model="form.mobilePhone"
                    label="Cell Phone"
                    outlined
                    dense></v-text-field></v-col>
                <v-col cols="4">
                  <v-select
                    :items="patientStatuses"
                    label="Patient Status"
                    v-model="form.status"
                    outlined
                    dense></v-select>
                </v-col>
              </v-row>
              <v-row style="margin: 0">
                <v-col cols="4">
                  <v-btn
                    color="#B0D35E"
                    style="margin-right: 1em"
                    @click="updateDependent()"
                    >Save</v-btn>
                  <v-btn
                    color="#E3B900"
                    style="margin-right: 1em"
                    :disabled="Object.keys(originalForm).length === 0"
                    @click="cancelEditDependent()"
                    >Cancel</v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- ////////////////////--- SM AND DOWN: Tablet & Mobile ---/////////////////////////////// -->
    <v-container v-if="this.$vuetify.breakpoint.smAndDown">
      <v-row class="mx-0 mt-3">
        <h2 class="dashtitle"><span>Manage Dependents</span></h2>
      </v-row>
      <v-row class="">
        <v-col cols="9" class="mr-n3">
          <v-text-field
            label="Search"
            v-model="search"
            outlined
            rounded
            dense></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-btn color="primary" @click="searchDependentData()">Search</v-btn>
        </v-col>
      </v-row>
      <v-card outlined>
        <div class="text-center">
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            style="
              height: 50%;
              margin: auto;
              padding-top: 50%;
            "></v-progress-circular>
          <div
            v-if="!loading && !hasDependents"
            style="height: 50%; margin: auto; padding-top: 50%">
            <div>
              <v-icon large>mdi-account-details</v-icon>
              <p>No dependents for the selected patient.</p>
            </div>
          </div>
        </div>

        <div v-if="!loading">
          <v-list flat>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in dependentList"
                :key="i"
                @click="selectDependent(item)"
                class="pb-2">
                <v-btn
                  x-large
                  outlined
                  style="color: #0079c6; width: 100%"
                  elevation="3">
                  <v-avatar
                    size="36"
                    color="#0079C6"
                    style="color: white"
                    class="mr-3"
                    >{{ item.firstName[0] + item.lastName[0] }}</v-avatar>
                  <v-list-item-title style="color: #0079c6">{{
                    item.firstName + ' ' + item.lastName
                  }}</v-list-item-title>
                </v-btn>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-card>

      <!-- Dependent Info Dialog modal-->
      <v-dialog v-model="modalDependentInfo" width="700">
        <form @submit.prevent="">
          <v-card height="600">
            <v-card-title class="primary d-flex justify-space-between">
              <h3>
                <span class="white--text">Dependent Information</span>
              </h3>
              <v-btn
                class="mx-0"
                text
                color="white"
                dense
                @click="cancelEditDependent">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="mt-0">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="form.firstName"
                      label="First Name"
                      outlined
                      dense
                      hide-details />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="form.lastName"
                      label="Last Name"
                      outlined
                      dense
                      hide-details />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      type="date"
                      v-model="form.birthDate"
                      label="Date Of Birth"
                      outlined
                      dense
                      hide-details />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="form.email"
                      label="Email"
                      required
                      outlined
                      dense
                      hide-details />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="form.address1"
                      label="Address Line 1"
                      outlined
                      dense
                      hide-details />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="form.mdAndUpddress2"
                      label="Address Line 2"
                      outlined
                      dense
                      hide-details />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="form.city"
                      label="City"
                      outlined
                      dense
                      hide-details />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="form.state"
                      label="State"
                      outlined
                      dense
                      hide-details/>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="form.postalCode"
                      label="Zip"
                      outlined
                      dense
                      hide-details />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="form.homePhone"
                      label="Home Phone"
                      outlined
                      dense
                      hide-details />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="form.workPhone"
                      label="Work Phone"
                      outlined
                      dense
                      hide-details />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="form.cellPhone"
                      label="Cell Phone"
                      outlined
                      dense
                      hide-details />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      :items="patientStatuses"
                      label="Patient Status"
                      v-model="form.status"
                      outlined
                      dense
                      hide-details />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions v-if="form" class="mt-0 pt-0 mb-5 mr-5">
              <v-spacer></v-spacer>
              <v-btn
                color="appPrimaryBtnColor"
                style="font-weight: 800; width: 100px"
                @click="updateDependent()"
                class="mb-3 white--text">
                Save
              </v-btn>
              <v-btn
                color="appSecondaryBtnColor"
                style="font-weight: 800; width: 100px"
                @click="cancelEditDependent()"
                class="mb-3">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-dialog>
    </v-container>
    <!-- ////////////////////--- END OF SM AND DOWN: Tablet & Mobile ---/////////////////////////////// -->
  </v-layout>
</template>
<script>
import DataService from '../../../_services/DataService';

export default {
  name: 'updateProfile',
  data() {
    return {
      modalDependentInfo: false,
      page: 0,
      sizes: [5, 10, 20, 50, 100, 200],
      size: 5,
      totalPages: 13,
      dependentList: [],
      dependents: [],
      hasDependents: false,
      loading: true,
      search: '',
      pageName: 'Update Profile',
      editIcon: 'mdi-pencil-outline',
      editDependent: {},
      patientStatuses: [
        {text: 'Patient', enum: 0, value: 'Patient'},
        {text: 'NonPatient', enum: 1, value: 'NonPatient'},
        {text: 'Inactive', enum: 2, value: 'Inactive'},
        {text: 'Archived', enum: 3, value: 'Archived'},
        {text: 'Deleted', enum: 4, value: 'Deleted'},
        {text: 'Deceased', enum: 5, value: 'Deceased'},
        {text: 'Prospective', enum: 6, value: 'Prospective'},
      ],
      form: {},
      originalForm: {}
    };
  },
  computed: {},
  watch: {
    page: {
      async handler() {
        await this.getData();
      },
      deep: false,
    },
  },
  methods: {
    async handlesizeChange(size) {
      this.size = size;
      this.page = 1;
      await this.getData();
    },
    async cancelEditDependent() {
      this.form = this.originalForm;
      this.modalDependentInfo = false;
    },
    async searchDependentData() {
      this.dependentList = [];
      this.page = 0;
      this.getData();
    },
    async updateDependent() {
      if (!this.form.id) {
        throw new Error('Please select a dependent');
      }

      const response = await DataService.updatePatientInfo(this.form.id, this.form);
      if (response.status == 200) {
        this.$root.$emit('raise_alert', {
          color: 'success',
          text: 'Saved!',
        });
        this.originalForm = {};
        this.modalDependentInfo = false;
      } else {
        this.$root.$emit('raise_alert', {
          color: 'warning',
          text: response.data || 'Something went wrong',
        });
      }
    },
    async getData() {
      try {
        this.loading = true;
        this.dependentList = [];
        this.dependents = [];
        let params = {};
        params['size'] = this.size;
        params['page'] = this.page; // The page is 1, but backend wants it to be 0.
        // In this case, the backend subtracts 1 from the
        // page being provided (1). This is not standard.
        // There is no standard for this at this time.
        // Please review the controller you are calling
        // to be sure you know whetherpage is expected to
        // be a value representing a position in an array
        // , or a "page number".

        if (this.search && this.search !== '') {
          params['search'] = this.search;
        }

        const response = await DataService.getPatientFamily();
        this.dependentList = response.data;
        this.hasDependents = response.data.length > 0 ? true : false;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$root.$emit('raise_alert', {
          color: 'info',
          text: 'There was an error getting dependents',
        });
      }
    },
    getTextStatus(statusId) {
      switch (statusId) {
        case 0:
          return 'Patient';
        case 1:
          return 'NonPatient';
        case 2:
          return 'Inactive';
        case 3:
          return 'Archived';
        case 4:
          return 'Deleted';
        case 5:
          return 'Deceased';
        case 6:
          return 'Prospective';
        default:
          return 'Patient';
      }
    },
    async selectDependent(item) {
      this.form = item;
      this.originalForm = Object.assign({}, this.form);
      this.modalDependentInfo = true;
    },
  },
  created() {},
  async mounted() {
    await this.getData();
    // Simulate a click so that first record
    // is selected automatically
    if (this.dependentList.length) {
      document.getElementById("dependentsList").firstChild.click();
    }
  },
};
</script>

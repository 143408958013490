import Vue from 'vue';
import Vuex from 'vuex';

//The main functionality behind vueX store came from the following example
//https://codesandbox.io/s/vuex-store-forked-x02074

//For future use, In order to set up,
//create store page, the current one is in frontend/src/store
//link in store to main.js
//  new Vue({
//   store,
//   router,
//   vuetify,
//   render: h => h(App),
// })
//   .$mount('#app');
//make a componet that utilizes a computed attribute or method attached to the store such as the following
// methods: {
//   addNumber(numberInput) {
//     this.$store.dispatch("addNumber", Number(numberInput));
//   }
// }
// computed: {
//   getNumbers() {
//     return this.$store.getters.getNumbers;
//   }
// }
//import to page and place in componets

Vue.use(Vuex);

const state = {
  numbers: [1, 2, 3],
  selectedDentist: '',
  showDate: false,
  adminTiles: [
    {
      type: 'email',
      title: 'Email/SMS Log',
      clientTitle: 'Email Log',
      icon: 'mdi-email',
      data: [
        {
          date: '',
          time: '',
          from: '',
          subject: '',
          body: '',
          page: 'Messaging',
          tab: 'message logs'
        },
      ],
    },
    {
      type: 'patient',
      title: 'Patient Management',
      clientTitle: 'Patient Management',
      icon: 'mdi-tooth-outline',
      data: [
        {
          subject: '',
          date: '',
          time: '',
          location: '',
          body: `Patient Management and Settings`,
          clientBody: `Patient Management and Settings`,
          page: 'Admin',
          clientPage: 'Admin',
          tab: 'patient management',
        },
      ],
    },
    {
      type: 'template',
      title: 'Template Manager',
      clientTitle: 'Template Management',
      icon: 'mdi-application-edit',
      data: [
        {
          subject: '',
          date: '',
          time: '',
          body: `Access and Manage Templates`,
          page: 'Messaging',
          tab: 'manage templates',
        },
      ],
    },
    {
      type: 'admin',
      title: 'Admin Management',
      clientTitle: 'Patient Management',
      icon: 'mdi-shield-account-outline',
      data: [
        {
          subject: '',
          date: '',
          time: '',
          location: '',
          body: `Admin Management and Settings`,
          clientBody: `Patient Management and Settings`,
          page: 'Admin',
          clientPage: 'Admin',
          tab: 'client user management',
        },
      ],
    },
  ],

};

const mutations = {
  //test mutation can delete
  ADD_NUMBER(state, payload) {
    state.numbers.push(payload);
  },

  //Schedule Appointment mutations
  SELECT_DENTIST(state, payload) {
    state.selectedDentist = payload;
    state.showDate = true;
  },
};

const actions = {
  //test action can delete
  addNumber(context, number) {
    context.commit('ADD_NUMBER', number);
  },

  //Schedule Appointment actions
  selectedDentist(context, number) {
    context.commit('SELECT_DENTIST', number);
  },
};

const getters = {
  //test getter can delete
  getNumbers(state) {
    return state.numbers;
  },

  //Schedule Appointment getters
  getSelectedDentist(state) {
    return state.selectedDentist;
  },

  getShowDate(state) {
    return state.showDate;
  },

  getAdminDashboardTiles(state) {
    return state.adminTiles;
  },
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});

<template>
  <v-card outlined>
    <v-row class="px-4">
      <v-col>
        <v-select
          class="my-2"
          validate-on-blur
          v-model="form.queryId"
          :item-value="(query) => query.id"
          :item-text="(query) => query.name"
          :items="queries"
          label="Query"
          outlined
          dense
          :rules="[rules.required]"
          hide-details />
      </v-col>
      <v-col>
        <v-select
          v-model="form.category"
          validate-on-blur
          class="my-2"
          :items="categories"
          outlined
          label="Category"
          :rules="[rules.required]"
          dense
          hide-details />
      </v-col>
    </v-row>
    <v-row class="px-4" style="overflow: scroll;">
      <v-col>
        <v-btn @click="loadQuery">Load Query Results</v-btn>
        <span v-if="hasLoadedQuery && queryResults.length === 0">No results</span>
        <table class="table p-2">
          <tr v-if="hasLoadedQuery && queryResults.length > 0">
            <th v-for="header of headers" :key="header">{{ header }}</th>
          </tr>
          <tr v-for="(row, i) in queryResults" :key="i">
            <td v-for="header of headers" :key="header">{{ row[header] }}</td>
          </tr>
        </table>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import DataService from '../../../../_services/DataService';
import ValidateService from '../../../../_services/ValidateService';

export default {
  name: 'Query',
  props: ['pageData'],
  components: {},
  data() {
    return {
      categories: ['Marketing', 'Reminders', 'Invitation', 'Welcome', 'Appointment Set'],
      queries: [],
      form: {
        category: '',
        queryId: '',
      },
      hasLoadedQuery: false,
      queryResults: [],
      headers: [],
      rules: ValidateService.validators,
    };
  },
  watch: {
    ['pageData.parent']: {
      handler() {
        this.setupParentRelationship();
      },
      deep: false,
    },
    ['pageData.parent.selectedTemplate']: {
      handler() {
        this.setSelectedTemplate();
      },
      deep: false,
    },
    ['pageData.parent.queries']: {
      handler() {
        this.setQueries();
      },
      deep: false,
    },
  },
  methods: {
    setupParentRelationship() {
      // eslint-disable-next-line
      this.pageData.parent.queryTab = this; // hack
    },
    setSelectedTemplate() {
      const { selectedTemplate } = this.pageData.parent;
      this.form.queryId = selectedTemplate.queryId;

      let { category } = selectedTemplate;
      category = category ?? '';
      this.form.category = category.split(' ').map((a) => a.charAt(0).toUpperCase() + a.slice(1)).join(' ');
    },
    setQueries() {
      this.queries = this.pageData.parent.queries;
    },
    async loadQuery() {
      const response = await DataService.getClientQueryResult(this.form.queryId);
      if (response.status === 200) {
        this.hasLoadedQuery = true;
        this.queryResults = response.data;
        if (this.queryResults.length) {
          this.headers = Object.keys(this.queryResults[0]);
        }
      }
    }
  },
  mounted() {
    if (this.pageData?.parent) {
      this.setupParentRelationship();

      if (this.pageData.parent.selectedTemplate) {
        this.setSelectedTemplate();
      }

      if (this.pageData.parent.queries) {
        this.setQueries();
      }
    }
  },
};
</script>
<style>
  .table {
    overflow-x: scroll;
  }

  .table, .table th, .table td {
    min-width: 75px;
    border: 1px solid black;
    border-collapse: collapse;
    font-size: 12px;
  }
</style>
<template>
  <v-form
    ref="form"
    @submit.prevent="submit"
    v-model="isFormValid"
    lazy-validation>
    <v-container>
      <!-- begin form -->
      <v-row>
        <v-col cols="12" xs="5" sm="6" md="6" lg="6" xl="6">
          <v-row>
            <v-col cols="5" xs="12" sm="5" md="5" lg="5" xl="5">
              <v-text-field
                v-model="form.firstName"
                label="First Name"
                :rules="[rules.required, rules.alpha_char, rules.max50]"
                outlined
                dense
                required />
            </v-col>
            <v-col cols="2" xs="2" sm="2" md="2" lg="2" xl="2">
              <v-text-field
                :rules="[rules.alpha_char, rules.max25]"
                v-model="form.middleName"
                label="Middle Initial"
                outlined
                dense />
            </v-col>
            <v-col cols="5" xs="5" sm="5" md="5" lg="5" xl="5">
              <v-text-field
                v-model="form.lastName"
                label="Last Name"
                :rules="[rules.required, rules.alpha_char, rules.max50]"
                outlined
                dense />
            </v-col>
          </v-row>
          <!-- end row 1 -->
          <v-row>
            <v-col cols="7" xs="7" sm="7" md="7" lg="7" xl="7">
              <v-text-field
                :rules="[rules.alpha_char, rules.max100]"
                v-model="form.preferredName"
                label="Preferred Name"
                outlined
                dense />
            </v-col>
            <v-col cols="5" xs="5" sm="5" md="5" lg="5" xl="5">
              <v-text-field
                :rules="[rules.max50]"
                v-model="form.birthDate"
                label="Date of Birth"
                type="date"
                outlined
                dense />
            </v-col>
          </v-row>
          <!-- end row 2 -->
          <v-row>
            <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                :rules="[rules.required, rules.alpha_char, rules.max100]"
                v-model="form.address1"
                label="Address 1"
                outlined
                dense />
            </v-col>
            <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                :rules="[rules.alpha_char, rules.max50]"
                v-model="form.address2"
                label="Address 2"
                outlined
                dense />
            </v-col>
          </v-row>
          <!-- end row 3 -->
          <v-row>
            <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                :rules="[rules.required, rules.alpha_char, rules.max50]"
                v-model="form.city"
                label="City"
                outlined
                dense />
            </v-col>
            <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
              <v-select
                v-model="form.state"
                label="State"
                :rules="[rules.required, rules.alpha_char, rules.max50]"
                :items="stateOptions"
                outlined
                dense
                required />
            </v-col>
          </v-row>
          <!-- end row 4 -->
          <v-row>
            <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                v-model="form.postalCode"
                label="Zip"
                :rules="[rules.required, rules.zip]"
                outlined
                dense
                required />
            </v-col>
            <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                v-model="form.email"
                label="Email"
                :rules="[rules.email, rules.max50]"
                disabled
                outlined
                dense />
            </v-col>
            <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                v-model="form.homePhone"
                label="Home Phone Number"
                outlined
                dense
                :rules="[rules.phone]"
                @keydown="(event) => numDash(event, 'form.phone')" />
            </v-col>
            <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                v-model="form.cellPhone"
                label="Cell Phone Number"
                outlined
                dense
                :rules="[rules.phone]"
                @keydown="(event) => numDash(event, 'form.cellphone')" />
            </v-col>
            <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                v-model="form.workPhone"
                label="Work Phone Number"
                outlined
                dense
                :rules="[rules.phone]"
                @keydown="(event) => numDash(event, 'form.workphone')" />
            </v-col>
          </v-row>
          <!-- end row 5 -->
          <v-row align-self="center" justify="end">
            <v-btn
              color="success"
              class="mr-5"
              @click="submitForm"
              dense
              :disabled="!isFormValid">
              Save
            </v-btn>
            <v-btn
              color="error"
              class="mr-5"
              @click="cancelForm"
              dense
              :disabled="!isFormValid">
              Reset
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import DataService from '../../../_services/DataService';
import StateService from '../../../_services/StateService';
import ValidateService from '../../../_services/ValidateService';

export default {
  props: ['userInfo'],
  data() {
    return {
      valid: false,
      lastname: '',
      isFormValid: false,
      form: {},
      loadForm: {},
      rules: ValidateService.validators,
      stateOptions: [],
      alertFail: {
        text: 'Your profile could not be updated.',
        icon: 'mdi-alert-circle-outline',
        color: 'error',
      },
      alertSuccess: {
        text: 'Your profile was successfully updated!',
        icon: 'mdi-alert-circle-outline',
        color: 'success',
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    numDash(e) {
      let num = e.target.value;
      if (e.key != 'Backspace' && (num.length === 3 || num.length === 7)) {
        e.target.value += '-';
      }
    },
    getStates() {
      this.stateOptions = StateService.getStates()
        .map((state) => ({
          text: state.name,
          value: state.abbreviation,
        }));
    },
    setAlert(status, string) {
      let result = status == 'fail' ? this.alertFail : this.alertSuccess;
      if (string) {
        result.text = string;
      }
      return result;
    },
    async submitForm() {
      try {
        if (this.$refs.form.validate()) {
          const response = await DataService.updatePatientInfo(this.form);

          if (response.status == 200) {
            this.$root.$emit('raise_alert', this.setAlert('success'));
          }
        }
      } catch (err) {
        this.$root.$emit('raise_alert', this.setAlert('fail'));
      }
    },
    cancelForm() {
      this.form = {};
    },
  },
  created() {
    this.getStates();
  },
  async mounted() {
    if (this.userInfo) {
      this.form.firstName = this.userInfo.firstName;
      this.form.middleName = this.userInfo.middleName;
      this.form.lastName = this.userInfo.lastName;
      this.form.preferredName = this.userInfo.preferredName;
      this.form.birthDate = this.userInfo.birthDate;
      this.form.address1 = this.userInfo.address1;
      this.form.address2 = this.userInfo.address2;
      this.form.city = this.userInfo.city;
      this.form.state = this.userInfo.state;
      this.form.postalCode = this.userInfo.postalCode;
      this.form.email = this.userInfo.email;
      this.form.homePhone = this.userInfo.homePhone;
      this.form.cellPhone = this.userInfo.cellPhone;
      this.form.workPhone = this.userInfo.workPhone;
    } else {
      const patientResp = await DataService.getPatientInfo();
      this.form.firstName = patientResp.data.firstName;
      this.form.middleName = patientResp.data.middleName;
      this.form.lastName = patientResp.data.lastName;
      this.form.preferredName = patientResp.data.preferredName;
      this.form.birthDate = patientResp.data.birthDate;
      this.form.address1 = patientResp.data.address1;
      this.form.address2 = patientResp.data.address2;
      this.form.city = patientResp.data.city;
      this.form.state = patientResp.data.state;
      this.form.postalCode = patientResp.data.postalCode;
      this.form.email = patientResp.data.email;
      this.form.homePhone = patientResp.data.homePhone;
      this.form.cellPhone = patientResp.data.cellPhone;
      this.form.workPhone = patientResp.data.workPhone;
    }

    this.$refs.form.resetValidation();
  },
};
</script>
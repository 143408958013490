<template>
  <v-container>
    <v-row class="mt-3">
      <v-col cols="12" sm="12" md="5" class="mx-3">
        <v-row class="mx-0">
          <h1 class="dashtitle"><span>Select Patient</span></h1>
        </v-row>
        <v-row>
          <v-col cols="9">
            <v-text-field
              label="Search"
              v-model="search"
              outlined
              dense
              style="color: #414a5b; border-width: 2px" />
          </v-col>
          <v-col cols="1">
            <v-btn color="primary" @click="getPatients()">Search</v-btn>
          </v-col>
        </v-row>
        <v-card class="justify-center" outlined>
          <div class="text-center">
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="primary"
              style="height: 50%; margin: auto; padding-top: 50%" />
          </div>
          <div v-if="!loading" class="py-2">
            <v-list class="py-0">
              <v-list-item-group
                active-class="selectedItemClass">
                <v-list-item
                  v-for="(item, i) in patients"
                  :key="i"
                  @click="selectPatient(item)"
                  class="my-n1"
                  id="patientList">
                  <v-btn class="py-2" width="100%" outlined>
                    <v-avatar class="primary mr-5" size="30">
                      <span class="white--text">{{item.firstName.charAt(0) + item.lastName.charAt(0)}}</span>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{item.firstName + ' ' + item.lastName}} ({{ item.id }})</v-list-item-title>
                    </v-list-item-content>
                  </v-btn>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </v-card>
        <v-row class="align-center pt-2">
          <v-col cols="3">
            <v-select
              class="dg-page-size pl-4"
              v-model="pageSize"
              :items="pageSizes"
              label="Items per Page"
              @change="handlePageSizeChange"></v-select>
          </v-col>
          <v-col>
            <v-pagination
              v-model="page"
              :length="totalPages"
              total-visible="5"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              active-class="pageNum">
            </v-pagination>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" class="mx-3">
        <v-row class="mx-0">
          <h1 class="dashtitle">
            Patient Settings
          </h1>
        </v-row>
        <v-row class="mx-0 mt-6">
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="!isVerifiedUser"
                @click="sendPasswordReset()"
                class="appSecondaryBtnColor buttons mr-5">
                Send Password Reset Email
              </v-btn>
            </template>
            <span>Send this verified user an email with instructions to reset their password.</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="!isVerifiedUser"
                @click="toggleShowConfirmChangePasswordDialog()"
                class="appSecondaryBtnColor buttons">
                Send New Random Password
              </v-btn>
            </template>
            <span>Reset this users password to a new randon password and email it to the user.</span>
          </v-tooltip>
        </v-row>
        <v-card class="mt-6">
          <v-row class="my-1">
            <v-col cols="6">
              <h2>
                <span>Personal Information</span>
              </h2>
            </v-col>
            <v-col cols="3" v-if="!isVerifiedUser">
              <v-btn class="mr-1" @click="sendPatientUserInvitation()">
                Send Invitation
                <v-icon color="#74c9cc" style="padding-left: 5px">mdi-email-fast</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="3" v-if="isVerifiedUser">
              <v-btn class="mr-1" @click="showPatientForms()">
                Assign Forms
                <v-icon color="#74c9cc" style="padding-left: 5px">mdi-form-select</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="3" v-if="form.cellPhone">
              <v-tooltip bottom>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                    style="margin-right: 1em"
                    v-bind="attrs"
                    v-on="on"
                    @click="loadSmsLog()">
                    View SMS Log
                    <v-icon color="#74c9cc" style="padding-left: 5px">mdi-comment</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row class="my-0" no-gutters>
            <v-col cols="6">
              <v-text-field
                v-model="form.firstName"
                label="First Name"
                outlined
                dense
                class="mr-3" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.lastName"
                label="Last Name"
                outlined
                dense
                class="mr-3" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                type="date"
                v-model="form.birthDate"
                label="Date Of Birth"
                outlined
                dense
                class="mr-3" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.email"
                label="Email"
                required
                outlined
                dense
                class="mr-3" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.address1"
                label="Address Line 1"
                outlined
                dense
                class="mr-3" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.address2"
                label="Address Line 2"
                outlined
                dense
                class="mr-3" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.city"
                label="City"
                outlined
                dense
                class="mr-3" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.state"
                label="State"
                outlined
                dense
                class="mr-3" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.postalCode"
                label="Zip"
                outlined
                dense
                class="mr-3" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.homePhone"
                label="Home Phone"
                outlined
                dense
                class="mr-3" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.workPhone"
                label="Work Phone"
                outlined
                dense
                class="mr-3" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.cellPhone"
                label="Cell Phone"
                outlined
                dense
                class="mr-3" />
            </v-col>
            <v-col cols="6">
              <v-select
                :items="patientStatuses"
                label="Patient Status"
                v-model="form.status"
                outlined
                dense
                class="mr-3" />
            </v-col>
          </v-row>
        </v-card>
        <v-row class="mx-0 my-1">
          <v-btn class="appPrimaryBtnColor buttons mr-3" @click="savePatient()">
            Save
          </v-btn>
          <v-btn class="appSecondaryBtnColor buttons mr-3" @click="cancel()"
            >Cancel</v-btn
          >
          <v-btn class="appPrimaryBtnColor buttons" @click="addPatient()">
            Add new Patient
          </v-btn>
        </v-row>
      </v-col>

      <v-dialog v-model="invite" max-width="600px" persistant>
        <v-card>
          <v-card-title>Invite New User</v-card-title>
          <v-card-text class="pb-2">
            <v-text-field outlined dense label="Full Name" />
            <v-text-field outlined dense label="Email" />
            <v-btn text color="primary">Send Invite</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showConfirmPasswordDialog"
        max-width="600px"
        persistant>
        <v-card>
          <v-card-title>Confirm</v-card-title>
          <v-card-text class="pb-2">
            Are you sure you want to continue? This will change this user's
            password.
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" @click="changeAndSendPassword()"
              >Continue</v-btn
            >
            <v-btn
              text
              color="secondary"
              @click="toggleShowConfirmChangePasswordDialog()"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showSmsLogDialog" max-width="600px" persistant>
        <v-card>
          <v-card-title>SMS Log</v-card-title>
          <v-card-text id="sms-log" class="pb-2">
            <v-progress-circular
              v-if="loadingSmsLog"
              indeterminate
              color="primary"
              style="height: 50%; margin: auto; padding-top: 50%" />
            <div v-if="!loadingSmsLog">
              <v-list v-for="smsLog in smsLogs" :key="smsLog.id">
                <v-list-item :class="smsLog.outgoing ? 'clinic-message' : 'patient-message'">
                  {{ smsLog.message }}
                </v-list-item>
              </v-list>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="secondary" @click="showSmsDialog = true" class="mr-1">Send SMS</v-btn>
            <v-btn color="primary" @click="showSmsLogDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showSmsDialog" max-width="500px" persistant>
        <v-card>
          <v-card-title>Send SMS</v-card-title>
          <v-card-text class="pb-2">
            <v-textarea v-model="messageToPatient" outlined dense label="Content" />
          </v-card-text>
          <v-card-actions>
            <v-btn :disabled="sendingSms" color="secondary" @click="sendSms()" class="mr-1">Send</v-btn>
            <v-btn color="primary" @click="showSmsDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showFormAssignment" max-width="500px" persistant>
        <v-card>
          <v-card-title>Assign Forms for Patient</v-card-title>
          <v-card-text class="py-2">
            <v-progress-circular
              v-if="loadingPatientForms"
              indeterminate
              color="primary"
              style="height: 50%; margin: auto; padding-top: 50%" />
            <div v-else>
              <v-row v-for="(form, i) of currentUserForms" :key="i" class="py-0">
                <v-col class="py-0">
                  <v-checkbox
                    class="my-0"
                    hide-details="auto"
                    v-model="form.assigned"
                    :disabled="!!form.completed">
                    <template v-slot:label>
                      {{ form.name }}
                      <span v-if="form.assignAtSignUp" style="color: #bbb">&nbsp;(Assigned at sign up)</span>
                    </template>
                  </v-checkbox>
                  <div v-if="form.assigned">
                    <div v-for="(assignedForm, j) of form.assignedForms" :key="j">
                      <span class="pl-8">Assigned {{ formatDate(assignedForm.createdAt) }}</span>
                      <span v-if="assignedForm.completedAt" style="color: #0a0">
                        &nbsp;Completed {{ formatDate(assignedForm.completedAt) }}
                        <a @click="showReassignForm = true; formIdToAssign = form.id">(Assign again?)</a>
                      </span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions class="mt-4">
            <v-btn :disabled="loadingPatientForms || updatingForms" color="secondary" @click="updatePatientFormAssignment()" class="mr-1">Save</v-btn>
            <v-btn color="primary" @click="showFormAssignment = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showReassignForm" max-width="250px" persistant>
        <v-card>
          <v-card-title>Assign Form</v-card-title>
          <v-card-text class="py-2">Do you want to assign this form again?</v-card-text>
          <v-card-actions class="mt-4">
            <v-btn :disabled="updatingForms" color="secondary" @click="reassignPatientFormAssignment()" class="mr-1">Assign</v-btn>
            <v-btn color="primary" @click="showReassignForm = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import DataService from '../../../_services/DataService';
import Utils from '../../utils.js';

export default {
  name: 'PatientManagement',
  props: ['template_props'],
  data() {
    return {
      page: 1,
      totalPages: 0,
      pageSizes: [10, 20, 50, 100, 200],
      pageSize: 10,
      pageName: 'Patient Settings',
      loading: true,
      search: '',
      invite: false,
      isVerifiedUser: false,
      showConfirmPasswordDialog: false,
      showSmsDialog: false,
      loadingSmsLog: true,
      showSmsLogDialog: false,
      sendingSms: false,
      showFormAssignment: false,
      showReassignForm: false,
      loadingPatientForms: false,
      updatingForms: false,
      smsLogs: [],
      messageToPatient: '',
      patients: [],
      selectedUser: 0,
      patientStatuses: [
        {text: 'Patient', enum: 0, value: 'Patient'},
        {text: 'NonPatient', enum: 1, value: 'NonPatient'},
        {text: 'Inactive', enum: 2, value: 'Inactive'},
        {text: 'Archived', enum: 3, value: 'Archived'},
        {text: 'Deleted', enum: 4, value: 'Deleted'},
        {text: 'Deceased', enum: 5, value: 'Deceased'},
        {text: 'Prospective', enum: 6, value: 'Prospective'},
      ],
      tabs: [
        {label: 'Admin Management', section: ''},
        {label: 'Patient Management', section: ''},
        {label: 'Schedule Management', section: ''},
      ],
      form: {},
      originalForm: {},
      clientForms: [],
      currentUserForms: [],
      formIdToAssign: null,
      formatDate: Utils.formatDate,
    };
  },
  watch: {
    page: {
      async handler() {
        await this.getPatients();
      },
    },
  },
  methods: {
    addPatient() {
      this.form = {};
    },
    async handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      await this.getPatients();
    },
    async sendPatientUserInvitation() {
      try {
        const body = {
          clientId: DataService.getClientId(),
          patientId: this.form.id,
          patientEmail: this.form.email,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
        };
        const response = await DataService.createInvitation(body);
        if (response.status == 200) {
          this.$root.$emit('raise_alert', {
            color: 'success',
            text: response.data,
          });
        }
      } catch (err) {
        this.$root.$emit('raise_alert', {
          color: 'info',
          text: err.response.data || 'Something went wrong',
        });
      }
    },
    async connectToClient() {
      try {
        const body = {
          clientUID: DataService.getClientId(),
          patientId: this.form.id,
          patientEmail: this.form.email,
          userId: this.selectedUser,
          RoleId: 3,
        };
        const response = await DataService.connectClientUserToPatient(body);
        if (response.status == 200) {
          this.$root.$emit('raise_alert', {
            color: 'success',
            text: response.data,
          });
        }
      } catch (err) {
        this.$root.$emit('raise_alert', {
          color: 'info',
          text: err.response.data || 'Something went wrong',
        });
      }
    },
    toggleShowConfirmChangePasswordDialog() {
      this.showConfirmPasswordDialog = !this.showConfirmPasswordDialog;
    },
    async changeAndSendPassword() {
      try {
        this.showConfirmPasswordDialog = false;
        const response = await DataService.changeAndSendPassword({
          username: this.form.email,
        });
        if (response.status == 200) {
          this.$root.$emit('raise_alert', {
            color: 'success',
            text: response.data,
          });
        }
      } catch (err) {
        this.$root.$emit('raise_alert', {
          color: 'info',
          text: err.response.data || 'Something went wrong',
        });
      }
    },
    async sendPasswordReset() {
      try {
        const response = await DataService.sendPasswordResetEmail({
          username: this.form.email,
        });
        if (response.status == 200) {
          this.$root.$emit('raise_alert', {
            color: 'success',
            text: response.data,
          });
        }
      } catch (err) {
        this.$root.$emit('raise_alert', {
          color: 'info',
          text: err.response.data || 'Something went wrong',
        });
      }
    },
    async loadSmsLog() {
      this.showSmsLogDialog = true;
      this.loadingSmsLog = true;

      const response = await DataService.getPatientSmsLog(this.form.id);
      if (response.status == 200) {
        this.smsLogs = response.data;

        setTimeout(() => {
          const logElement = document.getElementById('sms-log');
          logElement.scrollTop = logElement.scrollHeight;
      }, 0);
      } else {
        this.$root.$emit('raise_alert', {
          color: 'info',
          text: response.data || 'Something went wrong',
        });
      }

      this.loadingSmsLog = false;
    },
    async sendSms() {
      if (!this.messageToPatient || this.sendingSms) {
        return;
      }

      this.sendingSms = true;

      const response = await DataService.sendPatientSms(this.form.id, this.messageToPatient);
      if (response.status == 200) {
        this.$root.$emit('raise_alert', {
          color: 'success',
          text: 'Message sent',
        });

        this.showSmsDialog = false;
        this.messageToPatient = '';

        await this.loadSmsLog();
      } else {
        this.$root.$emit('raise_alert', {
          color: 'info',
          text: response.data || 'Something went wrong',
        });
      }

      this.sendingSms = false;
    },
    async savePatient() {
      if (!this.form.id) {
        const created = await DataService.new_patient(this.form);
        if (created.status == 200) {
          this.$root.$emit('raise_alert', {
            color: 'success',
            text: 'Saved!',
          });
        } else {
          this.$root.$emit('raise_alert', {
            color: 'info',
            text: created.data || 'Something went wrong',
          });
        }
      } else {
        const updated = await DataService.updateClientPatientInfo(this.form.id, this.form);
        if (updated.status == 200) {
          this.$root.$emit('raise_alert', {
            color: 'success',
            text: 'Saved!',
          });
        } else  {
          this.$root.$emit('raise_alert', {
            color: 'info',
            text: updated.data || 'Something went wrong',
          });
        }
      }
    },
    async getPatientById(patientId) {
      const patientResponse = await DataService.getPatientById(patientId);
      if (patientResponse.status === 200) {
        await this.selectPatient(patientResponse.data);
      }
    },
    cancel() {
      this.form = Object.assign({}, this.originalForm);
    },
    async selectPatient(item) {
      this.form = item;
      this.originalForm = Object.assign({}, this.form);
      this.foundUser = false;

      if (item.email) {
        const patientUserResponse = await DataService.getUserByEmail(item.email);
        const foundUser = patientUserResponse.status === 200;
        if (foundUser) {
          this.selectedUser = patientUserResponse.data.id;
        }

        this.isVerifiedUser = foundUser;
      }
    },
    async getPatients() {
      this.form = {};
      let params = `?page=${this.page - 1}&size=${this.pageSize}`;
      if (this.search) {
        params = `?page=${this.page - 1}&searchTerm=${this.search}`;
      }
      const response = await DataService.getPatients(params);
      this.totalPages = Math.ceil(response.data.recordCount[0].count / this.pageSize) || 0;
      if (response.data.error) {
        this.$root.$emit('raise_alert', {
          color: 'info',
          text: response.data.error,
        });
      }

      this.patients = response.data.data;
    },
    async getClientForms() {
      const response = await DataService.getClientForms();
      if (response.status === 200) {
        this.clientForms = response.data;
      }
    },
    async showPatientForms() {
      this.showFormAssignment = true;
      this.loadingPatientForms = true;

      const userFormsResponse = await DataService.getFormsForUser(this.selectedUser);
      if (userFormsResponse.status == 200) {
        const userForms = userFormsResponse.data;

        this.currentUserForms = [];
        for (const clientForm of this.clientForms) {
          const assignedForms = userForms.filter((form) => form.formId === clientForm.id);

          this.currentUserForms.push({
            ...clientForm,
            assigned: assignedForms.length > 0,
            completed: assignedForms.some((form) => !!form.completedAt),
            assignedForms,
          });
        }
      } else {
        this.$root.$emit('raise_alert', { text: 'Failed to load patient forms' });
      }

      this.loadingPatientForms = false;
    },
    async updatePatientFormAssignment() {
      this.updatingForms = true;

      const formsToSend = this.currentUserForms.filter((form) => form.assigned).map((form) => form.id);

      const userFormsResponse = await DataService.updateFormsForUser(this.selectedUser, formsToSend);
      if (userFormsResponse.status == 200) {
        this.$root.$emit('raise_alert', { text: 'Updated patient form assignment' });
      } else {
        this.$root.$emit('raise_alert', { text: 'Failed to update patient forms' });
      }

      this.updatingForms = false;
    },
    async reassignPatientFormAssignment() {
      this.updatingForms = true;

      const assignFormResponse = await DataService.assignFormForUser(this.selectedUser, this.formIdToAssign);
      if (assignFormResponse.status == 201) {
        const formMapping = this.currentUserForms.find((form) => form.id === this.formIdToAssign);
        formMapping.assignedForms.push(assignFormResponse.data);

        this.formIdToAssign = null;
        this.showReassignForm = false;

        this.$root.$emit('raise_alert', { text: 'Assigned form to patient again' });
      } else {
        this.$root.$emit('raise_alert', { text: 'Failed to update patient forms' });
      }

      this.updatingForms = false;
    },
  },
  async mounted() {
    this.loading = true;

    await Promise.all([
      this.getPatients(),
      this.getClientForms(),
    ]);

    this.loading = false;

    if (this.$route.query.patientId) {
      await this.getPatientById(this.$route.query.patientId);
    } else {
      // Simulate a click so that first record is selected automatically
      setTimeout(() => document.getElementById('patientList').firstChild.click(), 100);
    }
  },
};
</script>
<style scoped>
.pageNum {
  color: white;
}

.theme--light.v-sheet--outlined {
  border: thin solid #414a5b;
}
.buttons {
  color: #414a5b;
  /* background-color: #b0d35e; */
  font-family: 'Inter', sans-serif;
  font-size: 12px !important;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: normal;
  padding: 20px;
}
.deleteBtn {
  color: white;
}
.selectedItemClass button {
  background-color: #414a5b;
  color: white;
}
.v-list-item__title {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  text-transform: capitalize;
}

#sms-log {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.clinic-message, .patient-message {
  width: 200px;
  border-radius: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.clinic-message {
  margin-left: 350px;
  background-color: aqua;
}

.patient-message {
  background-color: greenyellow;
}

</style>

<template>
  <v-layout row wrap class="no-toppad" no-gutters text-xs-right>
    <v-container class="">
      <v-row noGutters>
        <v-col cols="12" md="8" sm="4">
          <h1 class="dashtitle">
            <span>{{ this.pageName }}</span>
          </h1>
        </v-col>
      </v-row>

      <v-row class="px-4">
        <PageTabs :tabs="tabs" :startTab="tabFromQuery" />
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
import PageTabs from '../../components/PageTabs';
import ManagePatient from './ManagePatient';
import ManageAdmin from './ManageAdmin';
import ManageStaff from './ManageStaff';
import Settings from './Settings';
import AppointmentTypes from './AppointmentTypes'
import CompanyInformation from './CompanyInformation';
import FormManagement from './FormManagement';
import DataService from '../../../_services/DataService';

export default {
  name: 'AdminUserManagement',
  props: ['template_props'],

  components: {
    PageTabs,
    ManageAdmin,
    ManagePatient,
    ManageStaff,
    Settings,
  },
  data() {
    return {
      pageName: 'User Management',
      pageIcon: ' ',
      tabFromQuery: null,
      tabs: [
        {label: 'Patient Management', section: ManagePatient},
        {label: 'Appointment Types', section: AppointmentTypes},
      ],
      admins: [],
      form: {},
    };
  },
  methods: {
    addView(label, component) {
      this.tabs.push({
        label: label,
        section: component,
      });
    },
    setAdminTabs() {
      this.addView('Client User Management', ManageAdmin);
      this.addView('Company Information', CompanyInformation);
      this.addView('Form Management', FormManagement);
      this.addView('Settings', Settings);
    },
    setPageData() {
      this.setPageName();
      if (DataService.getRole() === 'clientAdmin') {
        this.setAdminTabs();
      }
    },
    setPageName() {
      this.pageName = 'User Management';
    },
  },
  mounted() {
    this.setPageData();

    if (this.$route.query.tab) {
      const lowerRoute = this.$route.query.tab.toLowerCase();
      const tabIndex = this.tabs.findIndex((tab) => tab.label.toLowerCase() === lowerRoute);
      if (tabIndex !== -1) {
        this.tabFromQuery = tabIndex;
      }
    }
  },
};
</script>
<style></style>

<template>
  <v-layout row wrap class="no-toppad" no-gutters text-xs-right>
    <v-container class="">
      <v-row noGutters>
        <v-col cols="12" md="8" sm="4">
          <h1 class="dashtitle">
            <v-icon>{{ this.pageIcon }}</v-icon>
            <span>{{ this.pageName }}</span>
          </h1>
        </v-col>
      </v-row>
      <v-row class="px-4">
        <PageTabs :tabs="tabs" :startTab="tabFromQuery" :showArrows="$vuetify.breakpoint.xs" />
      </v-row>
    </v-container>
  </v-layout>
</template>
<script>
import DataService from '../../../_services/DataService';
import PageTabs from '../../components/PageTabs.vue';
import UserInfo from './UserInfo.vue';
import AccountInfo from './AccountInfo.vue';
import ManageFamily from './ManageFamily.vue';
import ContactPrefs from './ContactPrefs.vue';
import PatientForm from '../PatientForm.vue';
import PrivacyConsent from './PrivacyConsent.vue';

export default {
  name: 'UserDashboard',
  components: {
    PageTabs,
    UserInfo,
    AccountInfo,
    ManageFamily,
    ContactPrefs,
    PatientForm,
    PrivacyConsent,
  },
  data() {
    return {
      pageName: 'Account Settings',
      pageIcon: 'mdi-monitor-dashboard',
      tabFromQuery: null,
      loading: true,
      tabs: [],
      patient: [
        {label: 'Update Info', section: UserInfo},
        {label: 'Account Info', section: AccountInfo},
        {label: 'Contact Preferences', section: ContactPrefs},
        {label: 'Forms', section: PatientForm},
      ],
      clientAdmin: [
        {label: 'Account Info', section: AccountInfo},
        {label: 'Contact Preferences', section: ContactPrefs},
      ],
      clientUser: [
        {label: 'Account Info', section: AccountInfo},
        {label: 'Contact Preferences', section: ContactPrefs},
      ],
    };
  },
  methods: {
    async loadPatient() {
      const patientResp = await DataService.getPatientInfo();
      if (patientResp.status === 200) {
        if (!patientResp.data.isSubAccount) {
          this.tabs.push({label: 'Manage Family', section: ManageFamily});
        }

        if (patientResp.data.guarantor) {
          this.tabs.push({label: 'Privacy & Consent', section: PrivacyConsent})
        } 
      }
    },
  },
  async mounted() {
    switch (DataService.getRole()) {
      case 'clientAdmin':
        this.tabs = this.clientAdmin;
        break;
      case 'patient':
        this.tabs = this.patient;
        await this.loadPatient();
        break;
      case 'clientUser':
        this.tabs = this.clientUser;
        break;
      default:
        this.tabs = [];
    }

    if (this.$route.query.tab) {
      const lowerRoute = this.$route.query.tab.toLowerCase();
      const tabIndex = this.tabs.findIndex((tab) => tab.label.toLowerCase() === lowerRoute);
      if (tabIndex !== -1) {
        this.tabFromQuery = tabIndex;
      }
    }
  },
};
</script>

<template>
  <v-container class="d-flex justify-center" width="100%" style="z-index:9999">
    <v-alert
      class="alert"
      v-for="(alert, i) in alertArray"
      :width="isMobile ? '300' : '400'"
      :min-width="isMobile ? '300' : '400'"
      :key="i"
      density="comfortable"
      :type="alert.color"
      variant="elevated"
      :dismissible="!isMobile"
      @change="change_alert">
      {{ alert.text }}
    </v-alert>
    <v-alert
      v-for="(alert, i) in alert_list"
      :key="i"
      min-width="400"
      border="top"
      density="comfortable"
      :type="alert.color"
      variant="elevated"
      dismissible
      @change="change_alert">
      {{ alert.text }}
    </v-alert>
  </v-container>
</template>
<script>
export default {
  props: ['alertArray'],
  data() {
    return {
      alert_list: [],
      isMobile: this.$vuetify.breakpoint.width < 420,
    };
  },
  methods: {
    change_alert() {},
    raise_alert(alert) {
      this.alert_list = [];
      this.alert_list.push(alert);
      setTimeout(() => (this.alert_list = []), 1000 * 3);
    },
    dismiss_alert() {
      this.alert = false;
    },
  },
  mounted() {},
  // Leaving this as an example of another way to update the alerts.
  // May need this in the future and it'll save time having it here.

  // updated() {
  //   setTimeout(() => {
  //     this.alertArray.shift();
  //   }, 5000);
  // },
};
</script>
<style scoped>
.alert {
  position: fixed;
  top: 50% !important;
  z-index: 99;
}
</style>

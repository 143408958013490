<template>
  <v-container>
    <v-layout row>
      <v-flex class="mb-6">
        <v-card class="login-card text-center" tile>
          <img
            v-if="!clientColor"
            src="@/../public/static/img/acrew-sec-logo.png"
            alt="Logo"
            width="400px"
          />
          <v-card-title v-if="clientColor" class="client-banner mb-2">
            <img
              cover
              :width="150"
              :src="clientLogo"
              v-if="clientLogo" />
          </v-card-title>
          <v-card-text>
            <v-stepper v-model="currentStep">
              <v-stepper-items>
                <v-stepper-content step="1">
                  <h3 class="mb-4">To create an appointment, let us know if you're an existing patient, or if you're new to {{ clientName }}</h3>
                  <v-btn block dense color="grey" @click="currentStep = 3">Existing Patient</v-btn>
                  <v-btn block dense color="light-blue" class="mt-4" @click="currentStep = 2">New Patient</v-btn>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-form
                    ref="form"
                    @submit.prevent="submitForm"
                    lazy-validation>
                    <v-text-field
                      v-model="form.email"
                      :rules="[rules.email]"
                      label="Email"
                      placeholder="Email"
                      type="email"
                      required
                      outlined
                      dense
                      rounded />
                    <v-text-field
                      v-model="form.firstName"
                      :rules="[rules.name]"
                      label="First Name"
                      placeholder="First Name"
                      type="name"
                      required
                      outlined
                      dense
                      rounded />
                    <v-text-field
                      v-model="form.lastName"
                      :rules="[rules.name]"
                      label="Last Name"
                      placeholder="Last Name"
                      type="name"
                      required
                      outlined
                      dense
                      rounded />
                    <v-text-field
                      v-model="form.birthDate"
                      label="Birth Date"
                      placeholder="Birth Date"
                      type="date"
                      required
                      outlined
                      dense
                      rounded />
                    <v-text-field
                      v-model="form.phone"
                      :rules="[rules.phone]"
                      label="Phone Number"
                      placeholder="Phone Number"
                      type="tel"
                      @keyup="numDash"
                      maxlength="12"
                      required
                      outlined
                      dense
                      rounded />
                    <v-text-field
                      v-model="form.address1"
                      label="Address Line 1"
                      placeholder="Address Line 1"
                      type="address"
                      required
                      outlined
                      dense
                      rounded />
                    <v-text-field
                      v-model="form.address2"
                      label="Address Line 2"
                      placeholder="Address Line 2"
                      type="address"
                      outlined
                      dense
                      rounded />
                    <v-text-field
                      v-model="form.city"
                      label="City"
                      placeholder="City"
                      type="address"
                      required
                      outlined
                      dense
                      rounded />
                    <v-text-field
                      v-model="form.state"
                      label="State"
                      placeholder="State"
                      type="address"
                      required
                      outlined
                      dense
                      rounded />
                    <v-text-field
                      v-model="form.postalCode"
                      :rules="[rules.zip]"
                      label="Postal Code"
                      placeholder="postalCode"
                      type="address"
                      required
                      outlined
                      dense
                      rounded />
                    <v-select
                      v-model="form.referralSource"
                      :items="referralList"
                      item-text="name"
                      item-value="id"
                      label="Where did you hear of us?"
                      dense
                      outlined
                      rounded
                      :disabled="!clientId" />
                    <v-text-field
                      v-if="otherSelected"
                      v-model="form.referralNote"
                      label="Enter your referrer here"
                      placeholder="Other"
                      required
                      outlined
                      dense
                      rounded />
                    <v-btn block dense rounded text @click="checkForm()" class="primary">
                      Submit
                    </v-btn>
                  </v-form>
                  <v-divider />
                  <v-row class="mt-2">
                    <v-col cols="12" class="d-flex justify-center">
                      <p>
                        Already have an account?
                        <span
                          @click="goToLogin()"
                          style="text-decoration: underline; cursor: pointer">
                          Sign in
                        </span
                        >
                      </p>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content step="3">
                  <h2 class="mb-2">Let's find your account with {{ clientName }}...</h2>
                  <h4 class="mb-2">Please use the information you have on file with your dentist</h4>
                  <v-progress-circular
                    v-if="searchingForPatient"
                    indeterminate
                    color="primary"
                    style="
                      position: fixed;
                      top: 50%;
                      left: 0%;
                      height: 50px !important;
                      margin: auto;
                      width: 100%;
                      z-index: 99;" />

                  <v-text-field
                    v-model="form.firstName"
                    :rules="[rules.name]"
                    label="First Name"
                    placeholder="First Name"
                    type="name"
                    required
                    outlined
                    dense
                    rounded />
                  <v-text-field
                    v-model="form.lastName"
                    :rules="[rules.name]"
                    label="Last Name"
                    placeholder="Last Name"
                    type="name"
                    required
                    outlined
                    dense
                    rounded />
                  <v-text-field
                    v-model="form.birthDate"
                    label="Birth Date"
                    placeholder="Birth Date"
                    type="date"
                    required
                    outlined
                    dense
                    rounded />
                  <v-text-field
                    v-model="form.email"
                    :rules="[rules.email]"
                    label="Email"
                    placeholder="Email"
                    type="email"
                    required
                    outlined
                    dense
                    rounded />
                  <v-text-field
                    v-model="form.phone"
                    :rules="[rules.phone]"
                    label="Phone Number"
                    placeholder="Phone Number"
                    type="tel"
                    @keyup="numDash"
                    maxlength="12"
                    required
                    outlined
                    dense
                    rounded />
                  <v-btn block dense color="light-blue" class="mt-4" @click="searchForPatient" :disabled="searchingForPatient">Find Patient</v-btn>
                </v-stepper-content>
                <v-stepper-content step="4">
                  <h2 class="mb-2">We found several possible matches...</h2>
                  <div class="mb-2" style="display: flex; justify-content: center;" v-for="(patient, i) in potentialPatientMatches" :key="i">
                    <v-card
                      @click="selectPatient(patient)"
                      outlined
                      class="py-8"
                      style="width: 300px; cursor: pointer; border-width: 4px;">
                      <h3>{{ patient.firstName }} {{ patient.lastName }}</h3>
                      {{ patient.age }}
                    </v-card>
                  </div>
                  <h4>Don't see your account? Try entering your information again, or call the {{ clientName }} office</h4>
                  <v-btn class="mt-2" @click="currentStep = 3">Re-enter information</v-btn>
                </v-stepper-content>
                <v-stepper-content step="5">
                  <v-progress-circular
                    v-if="sendingMultiFactor"
                    indeterminate
                    color="primary"
                    style="
                      position: fixed;
                      top: 50%;
                      left: 0%;
                      height: 50px !important;
                      margin: auto;
                      width: 100%;
                      z-index: 99;" />
                  <div v-else>
                    <h2>Please check your {{ contactMethod }} for a verification code</h2>
                    <div style="display: flex; justify-content: center;">
                      <v-otp-input
                        style="max-width: 400px"
                        v-model="otpInput"
                        length="4"
                        @finish="completePatientValidation" />
                    </div>
                  </div>
                </v-stepper-content>
                <v-stepper-content step="6">
                  <h4>Great, we've found your patient record. You may now create your appointment.</h4>
                  <h4>Would you like to add an additional comment for appointment?</h4>
                  <v-text-field v-model="form.additionalNote" />
                  <v-btn
                    block 
                    dense
                    color="light-blue"
                    class="mt-4"
                    @click="submitForm"
                    :disabled="apptSchedulingLoading">Create Appointment</v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
          <div v-if="clientColor">
            <span style="color: #aaa">Powered by</span>
            <br />
            <img
              src="@/../public/static/img/acrew-sec-logo.png"
              alt="Logo"
              width="150px" />
          </div>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-if="apptSchedulingLoading || appointmentScheduled" persistent v-model="dialog" width="400">
      <v-card class="d-flex justify-center flex-row my-6">
        <div v-if="apptSchedulingLoading" class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
            style="height: 50%; margin: auto; padding-top: 50%;" />
        </div>
        <div v-if="appointmentScheduled" class="justify-center align-center" style="width: 50%">
          <h4 class="mb-3">Your appointment has been scheduled!<span v-if="!isAccountLinked">&nbsp;Follow this link to complete your sign up.</span></h4>
          <v-card-actions>
            <v-btn
              color="#414a5b"
              style="background-color: #b0d35e"
              text
              elevation="3"
              @click="acknowledgeConfirm()">
              Ok
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import DataService from '../../_services/DataService';
import StateService from '../../_services/StateService';
import ValidateService from '../../_services/ValidateService';

export default {
  components: {},
  data() {
    return {
      currentStep: 1,
      clientId: 0,
      clientLogo: '',
      clientColor: '',
      clientName: '',
      searchingForPatient: false,
      sendingMultiFactor: false,
      creatingAppointment: false,
      patientValidationToken: null,
      contactMethod: null,
      otpInput: '',
      potentialPatientMatches: [],
      patientIdToLink: null,
      dialog: false,
      selectedDentist: {},
      form: {
        patientId: 0,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        birthDate: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        referralSource: null,
        referralNote: '',
        additionalNote: '',
      },
      rules: ValidateService.validators,
      errors: [],

      patientId: null,
      isNewPatient: false,
      isAccountLinked: false,

      referralList: [],
      otherSelected: false,
      apptSchedulingLoading: false,
      appointmentScheduled: false,

      states: [],
    };
  },
  watch: {
    ['form.referralSource']() {
      // todo this doesn't work
      if (!this.form.referralSource) {
        this.otherSelected = false;
      } else {
        const selectedItem = this.referralList.find((item) => item.id === this.form.referralSource);
        this.otherSelected = selectedItem.name.match(/\bother\b/i);
      }
    },
  },
  methods: {
    async acknowledgeConfirm() {
      this.dialog = false;

      if (!this.isAccountLinked) {
        this.$router.push({
          name: 'PatientSignup',
          query: {
            email: this.form.email,
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            patientId: this.patientId,
          },
        });
      } else {
        this.$router.push('/login');
      }
    },
    async searchForPatient() {
      this.searchingForPatient = true;

      const response = await DataService.searchForPatient(this.clientId, {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email,
        phoneNumber: this.form.phone,
        birthDate: this.form.birthDate,
      });

      if (response.status === 200) {
        if (response.data.length === 1) {
          this.currentStep = 5;
          this.patientIdToLink = response.data[0].patientId
          await this.beginPatientValidation();
        } else if (response.data.length > 0) {
          this.currentStep = 4;
          this.potentialPatientMatches = response.data;
        } else {
          this.$root.$emit('raise_alert', {
            color: 'error',
            text: `We could not find a matching patient record; try again or contact the ${this.clientName} office`,
          });
        }
      } else {
        this.$root.$emit('raise_alert', {
          color: 'error',
          text: response.data ?? 'An error occurred trying to search for a patient; please try again',
        });
      }

      this.searchingForPatient = false;
    },
    async selectPatient(patient) {
      this.currentStep = 5;
      this.patientIdToLink = patient.patientId
      await this.beginPatientValidation();
    },
    async beginPatientValidation() {
      this.sendingMultiFactor = true;
      const response = await DataService.getPatientValidationToken(this.clientId, this.patientIdToLink);
      if (response.status === 200) {
        this.patientValidationToken = response.data.token;
        this.contactMethod = response.data.sentSms === true ? 'text messages' : 'email';
      } else {
        this.$root.$emit('raise_alert', {
          color: 'error',
          text: 'An error occurred trying to send a validation request',
        });
      }
      this.sendingMultiFactor = false;
    },
    async completePatientValidation() {
      this.sendingMultiFactor = true;
      const response = await DataService.verifyPatientValidationToken(this.clientId, this.patientIdToLink, this.patientValidationToken, this.otpInput);
      if (response.status === 200) {
        this.form.patientId = response.data.patientId;
        this.currentStep = 6;
      } else {
        this.$root.$emit('raise_alert', {
          color: 'error',
          text: 'Failed to validate your request',
        });
      }
      this.sendingMultiFactor = false;
    },
    async submitForm() {
      this.dialog = true;
      this.apptSchedulingLoading = true;

      const body = {
        patientId: this.form.patientId,
        providerId: this.$route.query.providerId,
        operatoryId: this.$route.query.operatoryId,
        appointmentDate: this.$route.query.appointmentDate,
        appointmentTypeId: this.$route.query.appointmentType,
        email: this.form.email,
        firstName: this.form.firstName,
        middleName: this.form.middleName,
        lastName: this.form.lastName,
        address1: this.form.address1,
        address2: this.form.address2,
        birthDate: this.form.birthDate,
        city: this.form.city,
        state: this.form.state,
        postalCode: this.form.postalCode,
        phone: this.form.phone,
        referralSource: this.form.referralItem,
        referralNote: this.form.referralNote,
        additionalNote: this.form.additionalNote,
      };

      const resp = await DataService.scheduleAppointment(body);
      if (resp.status === 201) {
        this.patientId = resp.data.patientId;
        this.isNewPatient = resp.data.isNewPatient;
        this.isAccountLinked = resp.data.isAccountLinked;

        this.appointmentScheduled = true;
      } else {
        this.$root.$emit('raise_alert', {
          color: 'info',
          text: 'There was an error: ' + resp.data,
        });
      }

      this.apptSchedulingLoading = false;
    },
    async checkForm() {
      this.errors = [];
      Object.entries(this.form).forEach(([key, val]) => {
        if (
          key != 'additionalNote' &&
          key != 'address2' &&
          (
            key != 'referralNote' ||
            (
              key === 'referralNote' &&
              this.otherSelected
            )
          )
        ) {
          if (val === '' || val === null) {
            console.log(key)
            this.errors.push(`${key} cannot be empty.`);
          }
        }
      });

      if (!this.errors.length) {
        await this.submitForm();
      }
    },
    numDash(e) {
      var num = this.form.phone;
      if (e.key != 'Backspace' && (num.length === 3 || num.length === 7)) {
        this.form.phone += '-';
      }
    },
    async getClientPreferences() {
      const response = await DataService.getClientPreferences(this.clientId);
      if (response.status === 200) {
        this.clientLogo = response.data.clientLogo;
        this.clientColor = response.data.clientColor;
        this.clientName = response.data.clientName;
      }
    },
    async getClientReferralMethods() {
      const referralMethods = await DataService.getClientReferralMethods(this.clientId);
      this.referralList = referralMethods.data;
    },
  },
  async mounted() {
    if (this.$route.query.clientId) {
      DataService.updateClientId(this.$route.query.clientId);
    }

    this.states = StateService.getStates().map((state) => state.name);

    this.clientId = DataService.getClientId();
    await this.getClientPreferences();
    await this.getClientReferralMethods();
  },
};
</script>
<style scoped>
.theme--light.v-card.login-card {
  max-width: 800px;
  background-color: #ffffff !important;
  margin: 0 auto;
  margin-top: 10vh;
  box-shadow: 0 !important;
}

.center-all {
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-center {
  margin-left: auto;
  margin-right: auto;
}

.client-banner {
  background-color: v-bind(clientColor) !important;
}

.client-banner img {
  margin: auto;
}
</style>

<template>
    <v-container wrap class="no-toppad" text-xs-right>
        <PageTabs v-bind:tabs="tabs" />
    </v-container>
</template>
<script>

import Email from './Messaging/Email.vue';
import SMS from './Messaging/SMS.vue';
import PageTabs from '../components/PageTabs.vue';

export default {
  name: 'MessageLog',
  components: {
    SMS,
    PageTabs,
    Email,
  },
  data() {
    return {
      pageName: "MessageLog",
      pageIcon: "mdi-message-text",
      tabs: [
        { label: 'Email', section: Email, access: 'user' },
        { label: 'SMS', section: SMS, access: 'admin' }
      ],
      form: {
      },
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
  created() {
  },
  mounted() {
  }
};
</script>
<style>
</style>
<template>
  <v-container class="pa-0">
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
      style="
        position: fixed;
        top: 50%;
        left: 0%;
        height: 50px !important;
        margin: auto;
        width: 100%;
        z-index: 99;" />
    <div v-else>
      <v-row>
        <v-col class="d-flex justify-center">
          <h2 class="secondary--text">{{ form.name }}</h2>
        </v-col>
      </v-row>

      <iframe
        ref="formIFrame"
        style="border: none; width: 100%;"
        :srcdoc="form.body" />

      <Disclaimer v-if="includeSignature" ref="disclaimer" agreement-text="true" />
    </div>
  </v-container>
</template>

<script>
import { jsPDF } from 'jspdf';

import DataService from '../../../_services/DataService';
import Disclaimer from './Disclaimer.vue';

export default {
  name: 'CustomForm',
  props: ['userForm', 'patient'],
  components: {
    Disclaimer,
  },
  data() {
    return {
      loading: true,
      includeSignature: false,
      form: {},
    };
  },
  methods: {
    async getFormContent() {
      this.loading = true;

      const formContentResponse = await DataService.getFormById(this.userForm.formId);
      if (formContentResponse.status === 200) {
        this.form = formContentResponse.data;
        if (this.form.body.toLowerCase().includes('{{signature}}')) {
          this.form.body = this.form.body.replace(/\{\{signature\}\}/gi, '');
          this.includeSignature = true;
        }

        // Are there custom inputs?
        if (this.form.body.toLowerCase().includes('{{textfield')) {
          // inject form styles...
          const headerCloseIndex = this.form.body.indexOf('</head>');
          this.form.body =
            this.form.body.slice(0, headerCloseIndex) +
            `<style>
              .textfield {
                border-width: 1px;
                border-style: solid;
                border-color: rgb(204, 204, 204);
                border-radius: 0px;
                padding: 10px;
                color: rgb(0, 0, 0);
                background-color: rgb(255, 255, 255);
                font-size: 12px;
                width: 100%;
              }
            </style>\n` +
            this.form.body.slice(headerCloseIndex);

          const textfieldRegex = /\{\{textfield.*?\}\}/gimu;
          const nameRegex = /name="(.*?)"/i;
          const placeholderRegex = /placeholder="(.*?)"/i;
          let match = textfieldRegex.exec(this.form.body);
          while (match) {
            const [wholeMatch] = match;

            const nameMatch = nameRegex.exec(wholeMatch);
            const name = nameMatch ? nameMatch[1] : '';

            const placeholderMatch = placeholderRegex.exec(wholeMatch);
            const placeholder = placeholderMatch ? placeholderMatch[1] : '';

            this.form.body =
              this.form.body.slice(0, match.index) +
              `<div class="form-group">
                <div class="v-label-align-text-align v-label-font-size-font-size"><label>${name}</label></div>
                <input class="textfield" name="${name}" placeholder="${placeholder ?? name}" />
              </div>` +
              this.form.body.slice(match.index + wholeMatch.length);

            match = textfieldRegex.exec(this.form.body)
          }
        }

        // hack to fix forced full height of form
        this.form.body = this.form.body.replace(/style="min-height: 100vh;"/, '');
      }

      setTimeout(() => {
        const { body, documentElement } = this.$refs.formIFrame.contentWindow.document;
        const height = Math.max( body.scrollHeight, body.offsetHeight, documentElement.clientHeight, documentElement.scrollHeight, documentElement.offsetHeight );

        this.$refs.formIFrame.style.height = `${height}px`;
      }, 100);

      this.loading = false;
    },
    async validate() {
      if (this.includeSignature) {
        return this.$refs.disclaimer.validate();
      }

      return true; // todo? this.$refs.formIFrame.validate();
    },
    async sendForm(base64PDF) {
      const maybeId = this.patient.status === 'Self' ? undefined : this.patient.id;
      const response = await DataService.saveUserFormInput(this.userForm.id, maybeId, base64PDF);
      if (response.status === 200) {
        this.$emit('goToNextForm');
        this.$root.$emit('raise_alert', { color: 'success', text: 'Your entry has been recorded.' });
      } else {
        this.$emit('submitFailure');
        this.$root.$emit('raise_alert', { color: 'error', text: 'We were unable to save your insurance info, please try again or contact our team if the issue persists' });
      }
    },
    async generatePDF() {
      const doc = new jsPDF('p', 'pt', 'a4');
      doc.setFont(undefined, 'normal').setFontSize(10);

      await doc.html(this.$refs.formIFrame.contentDocument.body, { width: 600, windowWidth: 600 });

      if (this.includeSignature) {
        // todo someday - these should get rendered at the end of the form for some reason
        // when the html function is called, it seems like take over the whole page
        doc.addPage();
        doc.text(10, 25, `Date Signed: ${new Date().toISOString()}`);
        doc.addImage(this.$refs.disclaimer.$refs.signaturePad._data.signatureData, 'JPEG', 10, 30, 200, 150, undefined, 'FAST');
      }

      await this.sendForm(doc.output('datauristring').split(',')[1])
    },
  },
  async mounted() {
    await this.getFormContent();
  },
};
</script>

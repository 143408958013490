<template>
  <v-card outlined>
    <v-row class="ml-0 mt-0">
      <v-col cols="2" md="2" lg="2" class="title pl-4 pr-0 mr-0">
        Template Type
      </v-col>

      <v-col cols="6" class="pl-0">
        <v-chip-group mandatory active-class="primary">
          <v-chip v-for="(templateType, i) in messageTypes" v-model="templateType.valid" :key="i" label
            @click="setSelectedMessageType(templateType)">
            {{ templateType.label }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-row class="ml-0" v-if="!isSmsActive">
      <v-col cols="9" class="pl-4">
        <v-text-field
          v-model="form.subjectLine"
          validate-on-blur label="Subject"
          :rules="[rules.required, rules.max255]" />
      </v-col>
    </v-row>
    <v-row class="ml-0 pt-0 mt-0">
      <v-col v-if="!isSmsActive" @click="goToEmailEditor">
        <span
          style="
            display: inline-block;
            height: 35vh;
            overflow-x: overlay;
            padding-bottom: 0px;"
          v-html="form.body" />
      </v-col>
      <v-col v-if="isSmsActive" class="mh-0">
        <v-textarea
          outlined
          height="35vh"
          name="smsBody"
          label="Sms Message Body"
          v-model="form.body" />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import ValidateService from '../../../../_services/ValidateService';

export default {
  name: 'MessageContent',
  props: ['pageData'],
  components: {},
  data() {
    return {
      form: {
        messageType: 'email/html',
        subjectLine: '',
        enabled: true,
        body: '&lt;Click here to go to email editor&gt;',
      },
      isSmsActive: false,
      messageTypes: [
        {
          label: 'Email',
          type: 'email/html',
          valid: true,
        },
        {
          label: 'SMS',
          type: 'sms/text',
          valid: false,
        },
      ],
      rules: ValidateService.validators,
    };
  },
  watch: {
    ['pageData.parent']: {
      handler() {
        this.setupParentRelationship();
      },
      deep: false,
    },
    ['pageData.parent.selectedTemplate']: {
      handler() {
        this.setSelectedTemplate();
      },
      deep: false,
    },
  },
  methods: {
    setupParentRelationship() {
      // eslint-disable-next-line
      this.pageData.parent.contentTab = this; // hack
    },
    setSelectedTemplate() {
      const { selectedTemplate } = this.pageData.parent;
      this.updateMessageTypeValidity(selectedTemplate.type);
      this.form.messageType = selectedTemplate.type;
      this.form.subjectLine = selectedTemplate.subjectLine;
      this.form.body = selectedTemplate.body || '&lt;Click here to go to email editor&gt;';
    },
    setSelectedMessageType(messageType) {
      this.updateMessageTypeValidity(messageType.type);
      this.form.messageType = messageType.type;

      if (messageType.type === 'sms/text' && !this.isValidSmsBody(messageType.type)) {
        this.responseDialogText = 'This template contains html and cannot be converted into an sms.';
        this.isResponseDialogOpen = true;
      }
    },
    isValidSmsBody() {
      const regex = /<[a-z][\s\S]*>/i;
      return regex.test(this.form.body);
    },
    updateMessageTypeValidity(type) {
      this.messageTypes[0].valid = type === 'email/html' ? true : false;
      this.messageTypes[1].valid = type === 'sms/text' ? true : false;

      this.isSmsActive = type === 'sms/text' ? true : false;
    },
    goToEmailEditor() {
      this.pageData.parent.goToEmailEditor();
    },
    extractKeys(subjectLine, body) {
      const regex = /{{\s*(\w+)\s*}}/g;
      const keys = [];
      let match;

      while ((match = regex.exec(subjectLine)) !== null) {
        if (match[1] !== 'clientUID') {
          keys.push(match[1]);
        }
      }

      while ((match = regex.exec(body)) !== null) {
        if (match[1] !== 'clientUID') {
          keys.push(match[1]);
        }
      }

      return keys;
    },
  },
  mounted() {
    if (this.pageData?.parent) {
      this.setupParentRelationship();

      if (this.pageData.parent.selectedTemplate) {
        this.setSelectedTemplate();
      }
    }
  }
};
</script>
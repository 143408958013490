<template>
  <v-layout row wrap class="no-toppad" no-gutters text-xs-right>
    <v-container class="">
      <v-row class="mt-0">
        <v-col cols="12" sm="6" lg="4">
          <v-card flat cols="12" md="8" sm="4">
            <v-list>
              <v-list-item>
                <v-checkbox label="Marketing Text" v-model="smsMarketingOptIn" class="py-0 my-0"></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox label="Reminder Text" v-model="smsReminderOptIn" class="py-0 my-0"></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox label="Marketing Emails" v-model="emailMarketingOptIn" class="py-0 my-0"></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox label="Reminder Emails" v-model="emailReminderOptIn" class="py-0 my-0"></v-checkbox>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-card-actions class="mt-3 ml-0 pl-0">
        <v-btn
          color="appPrimaryBtnColor"
          style="font-weight: 800; width: 100px"
          @click="saveCommPreference()"
          class="mb-3 white--text">
          Save
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-layout>
</template>
<script>
import DataService from '../../../_services/DataService';
export default {
  name: 'contact-preferences',
  data() {
    return {
      pageName: 'Contact Preferences',
      smsMarketingOptIn: true,
      smsReminderOptIn: true,
      emailMarketingOptIn: true,
      emailReminderOptIn: true,
    };
  },
  methods: {
    async getData() {
      const response = await DataService.getPatientContactPreferences();
      if (response.status === 200) {
        const { sms, email } = response.data;
        this.smsMarketingOptIn = !sms.marketing.optOut;
        this.smsReminderOptIn = !sms.reminders.optOut;
        this.emailMarketingOptIn = !email.marketing.optOut;
        this.emailReminderOptIn = !email.reminders.optOut;
      }
    },

    async saveCommPreference() {
      const response = await DataService.setPatientContactPreferences({
        sms: {
          marketing: { optOut: !this.smsMarketingOptIn },
          reminders: { optOut: !this.smsReminderOptIn },
        },
        email: {
          marketing: { optOut: !this.emailMarketingOptIn },
          reminders: { optOut: !this.emailReminderOptIn },
        },
      });

      if (response.status === 200) {
        this.$root.$emit('raise_alert', {
          color: 'success',
          text: 'Saved Successfully',
        });
      } else {
        this.$root.$emit('raise_alert', {
          color: 'info',
          text: 'Something went wrong',
        });
      }
    },
  },
  async mounted() {
    await this.getData();
  },
};
</script>
import http from '../http-common';

const clientInStorage = localStorage.getItem('selectedClientId');
let sessionClientId = clientInStorage ? clientInStorage : '00000000-0000-0000-0000-000000000000';

const userInStorage = localStorage.getItem('user');
let sessionUser = userInStorage ? JSON.parse(userInStorage) : {};

const roleInStorage = localStorage.getItem('role');
let sessionRole = roleInStorage ?? '';

let client;

class DataService {
  getClientId() {
    return sessionClientId;
  }

  updateClientId(clientId) {
    localStorage.setItem('selectedClientId', clientId);
    sessionClientId = clientId;
  }

  getSessionUser() {
    return sessionUser;
  }

  updateSessionUser(user) {
    sessionUser = user;
  }

  getRole() {
    return sessionRole;
  }

  updateSessionRole(role) {
    sessionRole = role;
  }

  async getClientDetails() {
    if (!client) {
      const response = await http.get(`/clients/${sessionClientId}`);
      if (response.status === 200) {
        client = response.data;
      }
    }

    return client
  }

  async setTokenHeader() {
    const token = localStorage.getItem('token');
    const response = await this.validateToken({ 'token': token });

    if (!response.data.valid_token) {
      this.logout();
      return;
    } else {
      localStorage.setItem('token', response.data.newToken);
    } 

    // hack to fix the headers when the app first loads.
    // https://stackoverflow.com/questions/47715451/localstorage-item-not-updating-in-axios-headers
    if (localStorage.getItem('token')) {
      http.defaults.headers['x-access-token'] = localStorage.getItem('token');
    }
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('clientIds');
    localStorage.removeItem('role');
    sessionUser = {};
    sessionRole = '';

    window.location.href = `/#/login`;
  }

  // ========== Auth Functions =================

  validateToken(body) {
    if (localStorage.getItem('token')) {
      http.defaults.headers['x-access-token'] = localStorage.getItem('token');
    }
    return http.post('/auth/validateToken', body);
  }

  validate2FA(body) {
    if (localStorage.getItem('token')) {
      http.defaults.headers['x-access-token'] = localStorage.getItem('token');
    }
    return http.post('/auth/validate2FA', body);
  }

  async changeClientSelection(clientId) {
    this.setTokenHeader();
    const response = await http.post(`/auth/changeClient`, { clientId });
    if (response.status !== 200) {
      throw new Error('Failed to change client');
    } else {
      localStorage.setItem('token', response.data.token);
      this.updateClientId(clientId);
    } 

    // hack to fix the headers when the app first loads.
    // https://stackoverflow.com/questions/47715451/localstorage-item-not-updating-in-axios-headers
    if (localStorage.getItem('token')) {
      http.defaults.headers['x-access-token'] = localStorage.getItem('token');
    }
  }

  // ========== Real User Functions ================

  createClientUserAccount(body) {
    this.setTokenHeader();
    return http.post(`/${sessionClientId}/users`, body);
  }

  getClientUserAccounts(params) {
    this.setTokenHeader();
    return http.get(`/${sessionClientId}/users/${params}`);
  }

  deleteClientUserAccount(userId) {
    this.setTokenHeader();
    return http.delete(`/${sessionClientId}/users/${userId}`)
  }

  updateUser(userId, body) {
    this.setTokenHeader();
    return http.put(`/${sessionClientId}/users/${userId}`, body)
  }

  // ========== End Auth Functions =================

  // users functions
  getAllUsers(params) {
    // hack to fix the headers when the app first loads.
    // https://stackoverflow.com/questions/47715451/localstorage-item-not-updating-in-axios-headers
    if (localStorage.getItem('token'))
      http.defaults.headers['x-access-token'] = localStorage.getItem('token');
    return http.get('/users', {params});
  }

  getUserById(id) {
    this.setTokenHeader();
    return http.get(`/user/${id}`);
  }

  getUserByEmail(email) {
    this.setTokenHeader();
    return http.get(`/patient_user/${email}`);
  }

  updateUserPassword(params) {
    this.setTokenHeader();
    return http.patch(`/update_password`, params);
  }

  deleteUser(id) {
    this.setTokenHeader();
    return http.delete(`/users/${id}`);
  }

  addUser(body) {
    this.setTokenHeader();
    return http.post('/users/', {body});
  }

  addPatientUser(body) {
    return http.post(`/clients/${sessionClientId}/patientUser`, body);
  }

  createInvitation(body) {
    this.setTokenHeader();
    return http.post(`/auth/${sessionClientId}/invite_user`, body);
  }

  connectClientUserToPatient(body) {
    this.setTokenHeader();
    return http.post('/connect_user_patient', body);
  }

  send_sms(body) {
    this.setTokenHeader();
    return http.post(`/clients/${sessionClientId}/sms`, body);
  }

  fetchMessages(params) {
    this.setTokenHeader();
    return http.get(`/clients/${sessionClientId}/sms`, { params });
  }

  getUnreadMessageCount() {
    this.setTokenHeader();
    return http.get(`/clients/${sessionClientId}/sms/unread/count`);
  }

  markSmsAsRead(messageId) {
    this.setTokenHeader();
    return http.post(`/clients/${sessionClientId}/messages/${messageId}/read`);
  }

  markSmsAsUnread(messageId) {
    this.setTokenHeader();
    return http.post(`/clients/${sessionClientId}/messages/${messageId}/unread`);
  }

  getUserClients() {
    return http.get(`/user/clients`);
  }

  getConfigInfoById(id) {
    this.setTokenHeader();
    return http.get(`/config_get/${id}`);
  }

  getClientPreferences(clientId) {
    return http.get(`/config_get/${clientId || sessionClientId}/preferences`);
  }

  getClientReferralMethods(clientId) {
    return http.get(`/clients/${clientId || sessionClientId}/referralMethods`);
  }

  getConfigLogo(clientId) {
    this.setTokenHeader();
    return http.get(`/config_get/${clientId ?? sessionClientId}/logo`);
  }

  updateConfigInfoById(id, body) {
    this.setTokenHeader();
    return http.put(`/config_update/${id}`, body);
  }

  updateClientInfo(id, body) {
    this.setTokenHeader();

    return http.put(`/clients/${id}`, body);
  }

  checkAuthKeys(params) {
    this.setTokenHeader();
    return http.get(`/check_auth_keys`, {params});
  }

  getServiceMessages() {
    return http.get(`/clients/${sessionClientId}/serviceMessages`);
  }

  updateServiceMessages(body) {
    this.setTokenHeader();
    return http.put(`/clients/${sessionClientId}/serviceMessages`, body);
  }

  //==========Admin==============================

  getAllAdmins(params) {
    this.setTokenHeader();
    return http.get(`/${sessionClientId}/all_admins`, {params});
  }

  getAdminById(id) {
    this.setTokenHeader();
    return http.get(`/${sessionClientId}/admin_by_id/${id}`);
  }

  deleteAdminById(params) {
    this.setTokenHeader();
    return http.get('/delete_admin', {params});
  }

  updatePermissions(params) {
    this.setTokenHeader();
    return http.get('/update_permissions', {params});
  }

  getClientQueries() {
    this.setTokenHeader();
    return http.get(`/clients/${sessionClientId}/queries`);
  }

  getClientQueryResult(id) {
    this.setTokenHeader();
    return http.get(`/clients/${sessionClientId}/queries/${id}/result`);
  }

  //==========Appointments=======================

  getAppointmentTypes() {
    this.setTokenHeader();
    return http.get(`/clients/${sessionClientId}/appointmentTypes`);
  }

  updateAppointmentType(appointmentType) {
    this.setTokenHeader();
    return http.post(`/clients/${sessionClientId}/appointmentTypes/${appointmentType.id}`, appointmentType);
  }

  createAppointmentType(appointmentType) {
    this.setTokenHeader();
    return http.post(`/clients/${sessionClientId}/appointmentTypes`, appointmentType);
  }

  deleteAppointmentType(appointmentTypeId) {
    this.setTokenHeader();
    return http.delete(`/clients/${sessionClientId}/appointmentTypes/${appointmentTypeId}`);
  }

  getSchedulableAppointmentTypes(preferredClientId, excludeMinors) {
    return http.get(`/clients/${preferredClientId ?? sessionClientId}/schedulableAppointmentTypes?excludeMinors=${!!excludeMinors}`);
  }

  getAvailabilityForAppointmentType(preferredClientId, appointmentTypeId, startDate, endDate) {
    return http.get(`/clients/${preferredClientId ?? sessionClientId}/appointmentTypes/${appointmentTypeId}/availability?startDate=${startDate}&endDate=${endDate}`);
  }

  getAvailabilityForCustomAppointment(preferredClientId, providerId, duration, startDate, endDate, pattern) {
    return http.get(`/clients/${preferredClientId ?? sessionClientId}/providers/${providerId}/availability/${duration}?startDate=${startDate}&endDate=${endDate}&pattern=${pattern}`);
  }

  scheduleAppointment(body) {
    return http.post(`/clients/${sessionClientId}/appointments`, body);
  }

  //==========Documents==========================

  getAllDocuments(params) {
    this.setTokenHeader();
    return http.get('/all_documents', {params});
  }

  updateFilename(params) {
    this.setTokenHeader();
    return http.get('/update_filename', {params});
  }

  getDocumentsById(params) {
    this.setTokenHeader();
    return http.get('/documents_by_id', {params});
  }

  getAllUploads() {
    this.setTokenHeader();
    return http.get(`/all_uploads`);
  }

  createFile(params) {
    this.setTokenHeader();
    return http.get(`/create_file`, {params});
  }

  removeFile(params) {
    this.setTokenHeader();
    return http.get(`/remove_file`, {params});
  }

  deleteFile(params) {
    this.setTokenHeader();
    return http.post(`/delete_file`, params);
  }

  downloadFile(params) {
    this.setTokenHeader();
    return http.post(`/download_file`, params);
  }

  uploadDocumentAdmin(file, directory) {
    this.setTokenHeader();
    return http.post(`/document/upload/${directory}`, file);
  }

  //========== Employees ========================

  getAllEmployees() {
    this.setTokenHeader();
    return http.get('/all_employees');
  }

  getEmails(params) {
    this.setTokenHeader();
    return http.get(`/clients/${sessionClientId}/emails`, { params });
  }

  // ========= Template Functions =========================

  sendTemplateImmediate(id) {
    this.setTokenHeader();
    return http.post(`/clients/${sessionClientId}/templates/${id}/send`);
  }

  createTemplate(params) {
    this.setTokenHeader();
    return http.post(`/clients/${sessionClientId}/templates`, params);
  }

  getGenericTemplate(params) {
    this.setTokenHeader();
    return http.get(`/clients/${sessionClientId}/templates/generic`, params);
  }

  getTemplates(params) {
    this.setTokenHeader();
    return http.get(`/clients/${sessionClientId}/templates`, params);
  }

  getTemplateById(id) {
    this.setTokenHeader();
    return http.get(`/clients/${sessionClientId}/templates/${id}`);
  }

  updateTemplate(params) {
    this.setTokenHeader();
    return http.put(`/clients/${sessionClientId}/templates/${params.id}`, params);
  }

  deleteTemplate(id) {
    this.setTokenHeader();
    return http.delete(`/clients/${sessionClientId}/templates/${id}`);
  }

  // ========= Form Functions =========================

  createForm(params) {
    this.setTokenHeader();
    return http.post(`/clients/${sessionClientId}/forms`, params);
  }

  getClientForms() {
    this.setTokenHeader();
    return http.get(`/clients/${sessionClientId}/forms`);
  }

  getGenericForm(params) {
    this.setTokenHeader();
    return http.get(`/clients/${sessionClientId}/forms/generic`, params);
  }

  getFormById(id) {
    this.setTokenHeader();
    return http.get(`/clients/${sessionClientId}/forms/${id}`);
  }

  updateForm(id, params) {
    this.setTokenHeader();
    return http.put(`/clients/${sessionClientId}/forms/${id}`, params);
  }

  deleteForm(id) {
    this.setTokenHeader();
    return http.delete(`/clients/${sessionClientId}/forms/${id}`);
  }

  // ========= Patient Form Functions =========================

  searchForPatient(clientId, params) {
    return http.post(`/clients/${clientId}/patients/search`, params);
  }

  createPatientForm(params) {
    this.setTokenHeader();
    return http.post(`/${sessionClientId}/create_patient_form/`, params);
  }

  getPatientFormById(id) {
    this.setTokenHeader();
    return http.get(`/${sessionClientId}/get_patient_form/${id}`);
  }

  getPatientFormUserData(params) {
    this.setTokenHeader();
    return http.put(`/${sessionClientId}/get_patient_form_user_data/`, params);
  }

  updatePatientForm(params) {
    this.setTokenHeader();
    return http.put(`/${sessionClientId}/update_patient_form/`, params);
  }

  deletePatientForm(id) {
    this.setTokenHeader();
    return http.delete(`/${sessionClientId}/delete_patient_form/${id}`);
  }

  getUser(id) {
    this.setTokenHeader();
    return http.get(`/user/${id}`);
  }

  getFormsForUser(id) {
    this.setTokenHeader();
    return http.get(`/clients/${sessionClientId}/users/${id}/forms`);
  }

  updateFormsForUser(id, params) {
    this.setTokenHeader();
    return http.put(`/clients/${sessionClientId}/users/${id}/forms`, params);
  }

  assignFormForUser(id, formId) {
    this.setTokenHeader();
    return http.post(`/clients/${sessionClientId}/users/${id}/forms/${formId}`);
  }

  // ========= CONTACT PREFERENCES FUNCTIONS ===============

  getPatientContactPreferences() {
    this.setTokenHeader();
    return http.get(`/patient/contactPreferences`);
  }

  getFormsForPatient(id) {
    this.setTokenHeader();

    let route = `/patient/forms`;
    if (typeof id === 'number' || typeof id === 'string') {
      route = `/patient/dependents/${id}/forms`;
    }

    return http.get(route);
  }

  getTreatmentPlansForPatient(id) {
    this.setTokenHeader();

    let route = `/patient/treatmentPlans`;
    if (typeof id === 'number' || typeof id === 'string') {
      route = `/patient/dependents/${id}/treatmentPlans`;
    }

    return http.get(route);
  }

  saveUserFormInput(userFormId, id, pdfContent) {
    this.setTokenHeader();

    let route = `/patient/forms/${userFormId}`;
    if (typeof id === 'number' || typeof id === 'string') {
      route = `/patient/dependents/${id}/forms/${userFormId}`;
    }

    return http.post(route, { base64PdfContent: pdfContent });
  }

  setPatientContactPreferences(body) {
    return http.put(`/patient/contactPreferences`, body);
  }

  getEmailContactPreferences(clientId, userEmail) {
    return http.get(`/clients/${clientId}/email/${userEmail}/contactPreferences`);
  }

  setEmailContactPreferences(clientId, userEmail, body) {
    return http.put(`/clients/${clientId}/email/${userEmail}/contactPreferences`, body);
  }

  // ========= MANAGE INSURANCE FUNCTIONS ===============

  addInsuranceInfo(params) {
    this.setTokenHeader();
    return http.post('/insurance/', {params});
  }
  getInsuranceById(id) {
    this.setTokenHeader();
    return http.get(`/insurance/${id}`);
  }
  updateInsuranceInfo(params) {
    this.setTokenHeader();
    return http.put('/insurance/', {params});
  }


  getOpenDentalOperatories() {
    this.setTokenHeader();
    return http.get(`/od_api/${sessionClientId}/operatories`);
  }

   // ========= NEW PATIENT REGISTRATION (OPENDENTAL API FUNCTIONS) ===============

  getLastPatientForm(patientId) {
    this.setTokenHeader();
    return http.get(
      `/od_api/${sessionClientId}/new_patient_registration/last_form/${patientId}`
    );
  }

  postNewPatRegistration(params, userId) {
    this.setTokenHeader();
    return http.post(
      `/od_api/${sessionClientId}/new_patient_registration/${userId}`,
      params
    );
  }

  getAllergyDefinitions() {
    this.setTokenHeader();
    return http.get(
      `/od_api/${sessionClientId}/new_patient_registration/get_allergies`
    );
  }

  getMedicationDefinitions() {
    this.setTokenHeader();
    return http.get(
      `/od_api/${sessionClientId}/new_patient_registration/get_medications`
    );
  }

  getClientDiseaseCategories() {
    this.setTokenHeader();
    return http.get(`/clients/${sessionClientId}/diseaseCategories`);
  }

  createClientDiseaseCategory(category) {
    this.setTokenHeader();
    return http.post(`/clients/${sessionClientId}/diseaseCategories`, category);
  }

  updateClientDiseaseCategory(id, category) {
    this.setTokenHeader();
    return http.put(`/clients/${sessionClientId}/diseaseCategories/${id}`, category);
  }

  deleteClientDiseaseCategory(id) {
    this.setTokenHeader();
    return http.delete(`/clients/${sessionClientId}/diseaseCategories/${id}`);
  }

  getDiseaseDefinitions() {
    this.setTokenHeader();
    return http.get(
      `/od_api/${sessionClientId}/new_patient_registration/get_diseases`
    );
  }

  getOtherConditions() {
    this.setTokenHeader();
    return http.get(
      `/od_api/${sessionClientId}/new_patient_registration/get_other_conditions`
    );
  }

  getQuestions() {
    this.setTokenHeader();
    return http.get(
      `/od_api/${sessionClientId}/new_patient_registration/get_questions`
    );
  }

  getPatientDiseases(userId) {
    this.setTokenHeader();
    return http.get(
      `/od_api/${sessionClientId}/new_patient_registration/get_patient_diseases/${userId}`
    );
  }

  getPatientAllergies(userId) {
    this.setTokenHeader();
    return http.get(
      `/od_api/${sessionClientId}/new_patient_registration/get_patient_allergies/${userId}`
    );
  }
  getPatientMedications(userId) {
    this.setTokenHeader();
    return http.get(
      `/od_api/${sessionClientId}/new_patient_registration/get_patient_medications/${userId}`
    );
  }

  getPatients(params) {
    this.setTokenHeader();
    return http.get(`/clients/${sessionClientId}/patients${params}`);
  }

  get_patients_by_guarantor_id(guarantorId, params) {
    this.setTokenHeader();
    return http.get(
      `/od_api/${sessionClientId}/get_patients_by_guarantor_id/${guarantorId}`,
      {params}
    );
  }

  find_patient(body) {
    this.setTokenHeader();
    return http.post(`/od_api/${sessionClientId}/find_patient`, body);
  }

  new_patient(body) {
    this.setTokenHeader();
    return http.post(`/od_api/${sessionClientId}/new_patient`, body);
  }

  getPatientById(patientId) {
    this.setTokenHeader();
    return http.get(`/clients/${sessionClientId}/patients/${patientId}`);
  }

  updateClientPatientInfo(patientId, data) {
    this.setTokenHeader();
    return http.put(`/clients/${sessionClientId}/patients/${patientId}`, data);
  }
  
  getPatientSmsLog(patientId) {
    return http.get(`/clients/${sessionClientId}/patients/${patientId}/messages`);
  }
  
  sendPatientSms(patientId, message) {
    return http.post(`/clients/${sessionClientId}/patients/${patientId}/messages`, { message });
  }

  getPatientAccountBalance() {
    this.setTokenHeader();
    return http.get(`/patient/accountBalance`);
  }

  getPatientAccountBreakdown() {
    this.setTokenHeader();
    return http.get(`/patient/accountBreakdown`);
  }

  getPatientAppointments() {
    this.setTokenHeader();
    return http.get(`/patient/appointments`);
  }

  scheduleAppointmentForPatient(body) {
    this.setTokenHeader();
    return http.post(`/patient/appointments`, body);
  }

  getPatientInfo() {
    this.setTokenHeader();
    return http.get(`/patient`);
  }

  updatePatientInfo(id, data) {
    this.setTokenHeader();

    let route = `/patient`;
    if (typeof id === 'number' || typeof id === 'string') {
      route = `/patient/dependents/${id}`;
    } else {
      data = id;
    }

    return http.put(route, data);
  }

  getPatientFamily() {
    this.setTokenHeader();
    return http.get(`/patient/family`);
  }

  updatePatientConsent(body) {
    this.setTokenHeader();
    return http.put(`/patient/consent`, body);
  }

  getPaymentUrl(amount) {
    this.setTokenHeader();
    return http.get(`/patient/paymentUrl`, { amount });
  }

  savePayment({ referenceId }) {
    this.setTokenHeader();
    return http.post(`/patient/payment`, { referenceId });
  }

  getLastFormForCurrentPatient() {
    this.setTokenHeader();
    return http.get(`/od_api/new_patient_registration/last_form`);
  }

  // ========= OPENDENTAL APPOINTMENT API FUNCTIONS ===============

  get_appointment_by_id(id) {
    this.setTokenHeader();
    return http.get(`/od_api/${sessionClientId}/get_appointment_by_id/${id}`);
  }

  find_appointment(body) {
    this.setTokenHeader();
    return http.post(`/od_api/${sessionClientId}/find_appointment`, body);
  }

  new_appointment(body) {
    this.setTokenHeader();
    return http.post(`/od_api/${sessionClientId}/new_appointment`, body);
  }

  // =========PROVIDER (OPENDENTAL API FUNCTIONS) ===============

  get_providers(params) {
    this.setTokenHeader();
    return http.get(`/od_api/${sessionClientId}/get_providers/${params}`);
  }

  get_provider_by_id(id) {
    this.setTokenHeader();
    return http.get(`/od_api/${sessionClientId}/get_provider_by_id/${id}`);
  }

  find_provider(body) {
    this.setTokenHeader();
    return http.post(`/od_api/${sessionClientId}/find_provider`, body);
  }

  new_provider(body) {
    this.setTokenHeader();
    return http.post(`/od_api/${sessionClientId}/new_provider`, body);
  }

  update_provider(id, body) {
    this.setTokenHeader();
    return http.post(`/od_api/${sessionClientId}/update_provider/${id}`, body);
  }

  async getAvailableClients() {
   return http.get('/clients');
  }

  // =========USER (OPENDENTAL API FUNCTIONS)===============

  get_users(params) {
    this.setTokenHeader();
    return http.get(`/od_api/get_users`, params);
  }

  get_user_by_id(id) {
    this.setTokenHeader();
    return http.get(`/od_api/get_user_by_id/${id}`);
  }

  find_user(body) {
    this.setTokenHeader();
    return http.post(`/od_api/find_user`, body);
  }

  new_user(body) {
    this.setTokenHeader();
    return http.post(`/od_api/new_user`, body);
  }

  update_user(id, body) {
    this.setTokenHeader();
    return http.post(`/od_api/update_user/${id}`, body);
  }

  // ========= END OPENDENTAL API FUNCTIONS ===============

  sendPasswordResetEmail(username) {
    return http.post(`/auth/send_password_reset_email`, username);
  }

  sendNewUserRequestEmail(body) {
    return http.post(`/auth/send_new_user_req_email`, body);
  }

  checkPasswordResetToken(token) {
    return http.post(`/auth/check_reset_token`, token);
  }

  changePassword(body) {
    return http.post(`/auth/change_password`, body);
  }

  changeAndSendPassword(body) {
    return http.post(`/auth/admin_change_and_send_password`, body);
  }

  createAccount(clientId, body) {
    return http.post(`/auth/${clientId || sessionClientId}/create_account`, body);
  }

  updateAccountInfo(body) {
    return http.put(`/auth/account`, body);
  }

  forgot_password(email) {
    return http.post(`/auth/forgot_password`, { email });
  }

  // ========= END AUTH FUNCTIONS ================

  signin(email, password) {
    return http.post(`/auth/signin`, { email, password });
  }

  regenerate2FA(token, forceEmail) {
    return http.post(`/auth/regenerate2FA`, { token, forceEmail });
  }

  verifyOTP(body) {
    return http.post(`/auth/validate2FA`, body);
  }

  getPatientValidationToken(clientId, patientId) {
    return http.get(`/auth/clients/${clientId}/patients/${patientId}/validate`);
  }

  verifyPatientValidationToken(clientId, patientId, token, otp) {
    return http.post(`/auth/clients/${clientId}/patients/${patientId}/validate`, { token, otp });
  }
}

export default new DataService();

<template>
  <v-layout row wrap class="no-toppad" no-gutters text-xs-right>
    <v-container class="">
      <v-row noGutters>
        <v-col lg="12" md="12" sm="12">
          <h1 class="dashtitle">
            <v-icon class="mx-4">{{ this.pageIcon }}</v-icon>
            <span>{{ this.pageName }}</span>
            <v-icon @click="toggleUserData" class="mx-4">{{this.editIcon}}</v-icon>
          </h1>
        </v-col>
      </v-row>
      <v-row noGutters>
        <v-col cosl="12" lg="12" md="12" sm="12">
          <UserData v-bind:userInfo="userInfo" v-if="showUserData" />
          <UserInfoForm v-bind:userInfo="userInfo" v-if="showUserInfoForm" />
          <v-btn
            color="error"
            class=" mt-10 mr-5"
            @click="toggleUserData"
            dense
            v-if="showUserInfoForm">
            Cancel
          </v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>
<script>

import UserInfoForm from '../../components/Forms/UserInfoForm';
import UserData from '../../components/DataDisplay/UserData';
import DataService from '../../../_services/DataService';

export default {
  props: [],
  name: 'UserInfo',
  components: { UserInfoForm, UserData },
  data() {
    return {
      showUserData: true,
      showUserInfoForm: false,
      pageName: 'User Profile',
      pageIcon: 'mdi-account-circle-outline',
      editIcon: 'mdi-pencil-outline',
      userInfo: '',
    };
  },
  methods: {
    async toggleUserData() {
      this.showUserData = !this.showUserData;
      this.showUserInfoForm = !this.showUserInfoForm;

      await this.getData();
    },
    async getData(){
      const response = await DataService.getPatientInfo();

      this.userInfo = response.data;
    },
  },
  async created() {
    await this.getData();
  },
  mounted() {},
};
</script>

<template>
  <v-app>
    <v-banner
      class="client-banner"
      lines="one"
      text="For best experience login to your account here or create an account here."
      :stacked="false">
      <v-row>
        <v-col :cols="1">
          <v-img
            cover
            :width="80"
            :src="clientLogo"
            v-if="clientLogo" />
        </v-col>
        <v-col class="d-flex flex-column">
          <h1 style="padding-top: 20px; margin-left: -40px">Schedule Appointment</h1>
        </v-col>
      </v-row>
    </v-banner>
    <v-container class="ma-3" style="height: 100%">
      <v-row>
        <ServiceMessage page="scheduling" />
      </v-row>
      <!-- Start Vertical Stepper Scheduler -->
      <template>
        <v-stepper v-model="currentStep" vertical>
          <v-stepper-step
            v-if="showClientSelector"
            :complete="currentStep > 0"
            step="0"
            @click="setStepIfBeyond(0)">
            Select Dentist
          </v-stepper-step>
          <v-stepper-content v-if="showClientSelector" step="0">
            <v-card outlined style="border: 2px solid #333; border-radius: 15px; padding: 24px">
              <v-row class="mb-4">
                <h2>Select a dental facility</h2>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select
                    :items="clientList"
                    :item-text="client => client.name"
                    :item-value="client => client.id"
                    label="Select Dental Provider"
                    @input="selectClient($event)"
                    outlined
                    dense />
                </v-col>
              </v-row>
            </v-card>
          </v-stepper-content>

          <!-- Start Stepper Step 1 -->
          <v-stepper-step
            :complete="currentStep > 1"
            step="1"
            @click="setStepIfBeyond(1)">
            Login or Signup
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-row>
              <v-col>
                <v-card outlined style="border: 2px solid #333; border-radius: 15px;">
                  <v-col class="mx-3 my-3" cols="8">
                    <v-row class="mb-5">
                      <h2>Have you created an appointment within Acrew before?</h2>
                    </v-row>
                    <v-row>
                      <p>If you have an account, or are scheduling an appointment for one of your dependents, please login to your account now to schedule your appointment, view bills, and more.</p>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row class="justify-end mx-3 mb-3">
                      <v-btn
                        class="sign-up appPrimaryBtnColor btns"
                        @click="$router.push(`/login?clientId=${clientId}`)">
                        Go To Login
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-card>
              </v-col>
              <v-col>
                <v-card outlined style="border: 2px solid #333; border-radius: 15px;">
                  <v-col class="mx-3 my-3" cols="8">
                    <v-row class="mb-5">
                      <h2>Are you new to Acrew?</h2>
                    </v-row>
                    <v-row>
                      <p>You can also schedule an appointment now and sign up for Acrew later.</p>
                      <b>Note: Appointments for minors can only be scheduled after signing in.</b>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row class="justify-end mx-3 mb-3">
                      <v-btn
                        class="appSecondaryBtnColor buttons mr-3 mb-md-0 mb-3"
                        @click="getAppointmentTypes()">
                        Sign up later
                      </v-btn>
                      <v-btn
                        class="sign-up appPrimaryBtnColor btns"
                        @click="$router.push(`/new_account?clientId=${clientId}`)">
                        Create an account
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-card>
              </v-col>
            </v-row>
          </v-stepper-content>
          <!-- End Stepper Step 1 -->

          <!-- Start Stepper Step 2 -->
          <v-stepper-step
            :complete="currentStep > 2"
            step="2"
            @click="setStepIfBeyond(2)">
            What is the reason for the appointment?
            <span v-if="currentStep > 2"> - {{ selectedAppointmentType.name }}</span>
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-card>
              <v-progress-circular
                v-if="apptTypeLoading"
                indeterminate
                color="primary"
                style="height: 50px !important; margin: auto; width: 100%" />
              <div v-if="!apptTypeLoading">
                <v-list flat>
                  <v-list-item-group >
                    <v-list-item
                      v-for="(item, i) in appointmentTypes"
                      :key="i"
                      @click="selectAppointmentType(item)"
                      class="pb-2">
                      <v-btn
                        class="scheduleApptListItem"
                        x-large
                        outlined
                        style="color: #0079c6"
                        width="100%"
                        elevation="3">
                        <v-list-item-title style="color: #0079c6">
                          {{ item.name }} ({{ item.appointmentLength }} minutes)
                        </v-list-item-title>
                      </v-btn>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-card>
          </v-stepper-content>
          <!-- End Stepper Step 2 -->

          <!-- Start Stepper Step 3 -->
          <v-stepper-step
            :complete="currentStep > 3"
            step="3"
            @click="setStepIfBeyond(3)">
            Find an appointment
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-card>
              <v-progress-circular
                v-if="availabilityLoading"
                indeterminate
                color="primary"
                style="height: 50px !important; margin: auto; width: 100%" />
            </v-card>
            <v-card v-if="!availabilityLoading">
              <v-row>
                <v-col lg="2" cols="12">
                  Preferred Provider
                  <v-list flat>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(item, i) in providers"
                        :key="i"
                        @click="selectProvider(item)"
                        class="pb-2">
                        <v-btn
                          class="scheduleApptListItem"
                          x-large
                          outlined
                          :style="{
                            color: '#0079c6',
                            padding: '10px',
                            height: 'auto',
                            'border-width': item.id === selectedProvider?.id ? '3px' : '1px',
                          }"
                          width="100%"
                          elevation="3">
                          <v-list-item-title style="color: #0079c6">
                            <v-avatar style="display: inline-flex">
                              <img :src="item.imageUrl" alt="Avatar">
                            </v-avatar>
                          <div style="display: block; padding-top: 10px;">{{item.firstName}} {{item.lastName}}</div>
                          </v-list-item-title>
                        </v-btn>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
                <v-col lg="3" cols="12">
                  <v-date-picker
                    v-model="datePickerSelection"
                    style="border: 1px solid #414a5b; margin-top:2px; height: auto !important;"
                    class="pb-3"
                    :allowed-dates="isDateAllowed"
                    @change="dateSelected"
                    @click:month="getAvailabilityForAppointmentType"
                    ref="datepicker" />
                </v-col>
                <v-col lg="6" cols="12">
                  <span v-if="slotsForDate">Select a time slot for your appointment</span>
                  <v-row class="mt-1" v-if="slotsForDate">
                    <v-col
                      v-for="(item, i) in slotsForDate"
                      :key="i"
                      cols="4"
                      lg="2">
                      <v-btn
                        outlined
                        style="color: #0079c6"
                        @click="selectTime(item)">
                        {{item.timeString}}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-stepper-content>
          <!-- End Stepper Step 3 -->

          <!-- Start Stepper Step 4 -->
          <v-stepper-step step="4">Book your appointment</v-stepper-step>
          <v-stepper-content step="4">
            <v-card v-if="currentStep >= 4">
              <h4>Appointment Type</h4>
              <span>{{ selectedAppointmentType.name }}</span>

              <h4>Date &amp; Time</h4>
              <span>{{ selectedDate.toDateString() }} at {{ selectedTime }}</span>

              <h4>Provider</h4>
              <span>{{selectedProvider.firstName}} {{selectedProvider.lastName}}</span>

              <br />

              <v-btn
                outlined
                x-large
                class="mt-2"
                style="color: #0079c6"
                @click="bookAppointment()">
                Book!
              </v-btn>
            </v-card>
          </v-stepper-content>
          <!-- End Stepper Step 4 -->
        </v-stepper>
      </template>
    </v-container>
  </v-app>
</template>

<script>
import ServiceMessage from '@/components/ServiceMessage.vue';
import DataService from '../../../_services/DataService';

export default {
  name: 'ScheduleApptNoLogin',
  components: {
    ServiceMessage
  },
  data() {
    return {
      pageName: 'Schedule Appointment',
      showClientSelector: false,
      currentStep: -1,
      dialog: false,
      clientList: [],
      clientId: null,
      clientColor: '#b0d35e',
      clientLogo: null,
      apptTypeLoading: false,
      availabilityLoading: false,
      appointmentTypes: [],
      selectedAppointmentType: '',
      attemptedAppointmentLoad: false,
      availableAppointmentSlots: [],
      slotsForDate: [],
      providers: [],
      selectedProvider: null,
      datePickerSelection: new Date().toISOString().substring(0, 7),
      selectedDate: null,
      selectedTime: '',
      selectedOperatory: null,
      timeIncrementPreference: 10,
    };
  },
  methods: {
    setStepIfBeyond(step) {
      if (this.currentStep > step) {
        this.currentStep = step;
      }
    },
    async loadClients() {
      const clients = await DataService.getAvailableClients();
      this.clientList = clients.data;

      this.currentStep = 0;
    },
    async selectClient(item) {
      this.clientId = item;
      this.currentStep = 1;

      await this.getClientPreferences();
    },
    async selectProvider(item) {
      this.selectedProvider = item;
      this.getAppointmentSlotsForCurrentDateSelection();
    },
    selectTime(item) {
      this.selectedTime = item.timeString;
      this.selectedProvider = this.providers.find((prov) => prov.id === item.providerId);
      this.selectedOperatory = item.operatoryId;

      this.currentStep = 4;
    },
    isDateAllowed(value) {
      if (!this.availableAppointmentSlots.length) {
        return false;
      }

      const [year, month, day] = value.split('-');
      const date = new Date(Number(year), Number(month) - 1, Number(day));
      return this.availableAppointmentSlots.some((slot) => {
        if (this.selectedProvider?.id > 0 && slot.providerId !== this.selectedProvider.id) {
          return false;
        }

        const [loopYear, loopMonth, loopDay] = slot.start.substring(0, 10).split('-');
        const slotDate = new Date(Number(loopYear), Number(loopMonth) - 1, Number(loopDay));
        return slotDate.getTime() === date.getTime();
      });
    },
    async getClientPreferences() {
      const response = await DataService.getClientPreferences(this.clientId);
      if (response.status === 200) {
        this.clientColor = response.data.clientColor;
        this.clientLogo = response.data.clientLogo;
      } else if (response.status === 404) {
        this.clientId = '';
        this.showClientSelector = true;

        await this.loadClients();
      }
    },
    async getAvailabilityForAppointmentType() {
      this.availabilityLoading = true;

      let startDate = new Date();
      // we've already loaded data; this load is being caused by a time selection change
      if (this.attemptedAppointmentLoad) {
        startDate = new Date(this.$refs.datepicker.tableYear, this.$refs.datepicker.tableMonth, 1);
      }

      this.attemptedAppointmentLoad = true;

      const endDate = new Date(startDate);
      endDate.setMonth(startDate.getMonth() + 1);

      const response = await DataService.getAvailabilityForAppointmentType(this.clientId, this.selectedAppointmentType.id, startDate.toISOString().substring(0, 10), endDate.toISOString().substring(0, 10));

      this.timeIncrementPreference = response.data.timeIncrementPreference;
      this.providers = response.data.providers;

      for (const provider of this.providers) {
        provider.imageUrl = `${window.acrew.apiUrl}/clients/${this.clientId}/providers/${provider.id}/image`;
      }

      if (this.providers.length > 1) {
        this.providers.unshift({ id: -1, firstName: 'Any', imageUrl: '/static/img/generic_person.png' });
      }

      this.selectedProvider = this.providers[0];

      this.availableAppointmentSlots = response.data.slots;

      if (this.availableAppointmentSlots.length) {
        // set to the first date (today typically)
        const firstDateString = this.availableAppointmentSlots[0].start.substring(0, 10);
        const [year, month, day] = firstDateString.split('-');
        this.selectedDate = new Date(Number(year), Number(month) - 1, Number(day));
        this.datePickerSelection = firstDateString;
        this.getAppointmentSlotsForCurrentDateSelection();
      } else {
        this.selectedDate = startDate;
        this.datePickerSelection = startDate.toISOString().substring(0, 10);
      }

      this.availabilityLoading = false;

      this.bindMonthClickHandlers();
    },
    async getAppointmentTypes() {
      this.currentStep = 2;
      this.apptTypeLoading = true;

      const response = await DataService.getSchedulableAppointmentTypes(this.clientId, true);

      this.appointmentTypes = response.data;
      this.apptTypeLoading = false;
    },
    async selectAppointmentType(item) {
      this.currentStep = 3;
      this.selectedAppointmentType = item;
      await this.getAvailabilityForAppointmentType();
    },
    getAppointmentSlotsForCurrentDateSelection() {
      const appointmentSlotsForDate = this.availableAppointmentSlots.filter((slot) => {
        if (this.selectedProvider?.id > 0 && slot.providerId !== this.selectedProvider.id) {
          return false;
        }

        const [year, month, day] = slot.start.substring(0, 10).split('-');
        const slotDate = new Date(Number(year), Number(month) - 1, Number(day));
        return slotDate.getTime() === this.selectedDate.getTime();
      });

      const appointmentTypeLength = this.selectedAppointmentType.appointmentLength;

      const uniqueSlots = {};
      for (const slot of appointmentSlotsForDate) {
        const endDate = new Date(slot.end);

        let dateCopy;
        let loop = 0; // track position and also prevent overflow

        do {
          dateCopy = new Date(slot.start);
          dateCopy.setMinutes(dateCopy.getMinutes() + this.timeIncrementPreference * loop);
          
          const slotTime = dateCopy.getTime();
          if (!uniqueSlots[slotTime]) {
            uniqueSlots[slotTime] = {
              time: slotTime,
              providerId: slot.providerId,
              operatoryId: slot.operatoryId,
            };
          }
        } while (++loop < 50 && dateCopy.setMinutes(dateCopy.getMinutes() + appointmentTypeLength) < endDate);
      }

      const anHourFromNow = new Date();
      anHourFromNow.setMinutes(anHourFromNow.getMinutes() + 60);

      this.slotsForDate = [];
      const timeSortedKeys = Object.keys(uniqueSlots).sort();
      for (const key of timeSortedKeys) {
        const slot = new Date(uniqueSlots[key].time);

        // don't allow past scheduling, and no sooner than an hour from now
        if (slot.getTime() < anHourFromNow) {
          continue;
        }

        const hourPiece = slot.getHours() % 12 || '12';
        const minutePiece = slot.getMinutes().toString().padStart(2, '0');
        const amPm = slot.getHours() >= 12 ? 'PM' : 'AM';

        this.slotsForDate.push({
          ...uniqueSlots[key],
          timeString: `${hourPiece}:${minutePiece} ${amPm}`,
        });
      }
    },
    dateSelected(date) {
      const [year, month, day] = date.split('-');
      this.selectedDate = new Date(Number(year), Number(month) - 1, Number(day));
      this.getAppointmentSlotsForCurrentDateSelection();
    },
    async bookAppointment() {
      const [, hour, minute, amPm] = this.selectedTime.match(/(\d+):(\d+) ([AP]M)/);
      let normalizedHour = Number(hour) + (amPm === 'PM' ? 12 : 0);
      if (!(normalizedHour % 12)) {
        normalizedHour -= 12;
      }

      const appointmentDateString = `${this.selectedDate.getFullYear()}-${(this.selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${(this.selectedDate.getDate()).toString().padStart(2, '0')}T${normalizedHour.toString().padStart(2, '0')}:${minute.padStart(2, '0')}:00.000`; 

      this.$router.push({
        name: 'NewAccountAppointment',
        query: {
          clientId: this.clientId,
          appointmentType: this.selectedAppointmentType.id,
          appointmentDate: appointmentDateString,
          providerId: this.selectedProvider.id,
          operatoryId: this.selectedOperatory,
        },
      });
    },
    bindMonthClickHandlers() {
      // vuetify 2 doesn't offer us a way to capture month change via the prev/next button so... guess we're doing that ourselves
      // have to do wait for render and redo this on each load too weeeee
      setTimeout(() => {
        const prevBtn = this.$refs.datepicker.$el.querySelector('.v-btn[aria-label="Previous month"]')
        prevBtn.addEventListener('click', this.getAvailabilityForAppointmentType);
        const nextBtn = this.$refs.datepicker.$el.querySelector('.v-btn[aria-label="Next month"]')
        nextBtn.addEventListener('click', this.getAvailabilityForAppointmentType);
      }, 200);
    },
  },
  async mounted() {
    if (this.$route.query.clientId) {
      DataService.updateClientId(this.$route.query.clientId);
      this.clientId = this.$route.query.clientId;
      this.currentStep = 1;

      await this.getClientPreferences();
    } else if (DataService.getClientId()) {
      this.clientId = DataService.getClientId();
      this.currentStep = 1;

      await this.getClientPreferences();
    } else {
      this.showClientSelector = true;

      await this.loadClients();
    }
  },
};
</script>

<!-- Cannot set style on v-stepper__label if style is scoped -->
<style>

.client-banner {
  background-color: v-bind(clientColor) !important;
  margin-top: -25px;
}

.v-date-picker-table {
    height: auto !important;
}

.scheduleApptListItem {
  display: unset;
  margin-top: 0px;
  margin-bottom: 0px;
}
.v-stepper--vertical .v-stepper__step {
    padding: 24px 24px 16px 0px;
}
div .v-stepper__label {
  width: -webkit-fill-available !important;
  margin-right: 36px;
  min-width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  min-width: -moz-available;
}
.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
    margin-left: 12px;
}
div .v-list .v-sheet .theme--light .v-list--flat {
    margin-right: 36px;
}
div .v-picker__body {
  width: unset !important;
}
.mb-12 .v-card .v-sheet .theme--light {
  margin-bottom:0px;
}

</style>

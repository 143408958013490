<template>
  <v-layout row wrap class="no-toppad" no-gutters text-xs-right>
    <v-container class="">
      <v-row noGutters>
        <v-col cols="12" md="12" sm="12">
          <h3>Email Notification Preferences for {{ userEmail }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <v-card flat cols="12" md="8" sm="4">
            <v-list>
              <v-list-item>
                <v-checkbox label="Marketing Emails" v-model="marketingOptIn" class="py-0 my-0"></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox label="Reminder Emails" v-model="reminderOptIn" class="py-0 my-0"></v-checkbox>
              </v-list-item>
            </v-list>
          </v-card>
          <v-row class="ml-3" style="margin-top: 20px;">
            <v-btn class="appPrimaryBtnColor" style="margin-right:10px;" @click="saveCommPreference()" :disabled="error">Save</v-btn>
            <v-btn class="appSecondaryBtnColor" style="margin-right:10px;" @click="cancelChanges()">Cancel</v-btn>
            <v-btn color="primary" style="margin-right:10px;" @click="selectAll()">Select All</v-btn>
            <v-btn @click="unselectAll()">Deselect All</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
import DataService from '../../../_services/DataService';
export default {
  name: 'Unsubscribe',
  data() {
    return {
      pageName: 'Unsubscribe',
      userEmail: '',
      clientId: '',
      marketingOptIn: true,
      reminderOptIn: true,
      error: false,
    };
  },
  methods: {
    async getData() {
      const response = await DataService.getEmailContactPreferences(this.clientId, this.userEmail);
      if (response.status === 200) {
        const { reminders, marketing } = response.data;
        this.marketingOptIn = !marketing.optOut;
        this.reminderOptIn = !reminders.optOut;
      } else {
        //Disable the save button
        this.error = true;

        this.$root.$emit('raise_alert', {
          color: 'warning',
          text: 'Something went wrong',
        });
      }
    },
    async saveCommPreference() {
      const response = await DataService.setEmailContactPreferences(
        this.clientId,
        this.userEmail,
        {
          marketing: { optOut: !this.marketingOptIn },
          reminders: { optOut: !this.reminderOptIn },
        },
      );

      if (response.status === 200) {
        this.$root.$emit('raise_alert', {
          color: 'success',
          text: 'Saved Successfully',
        });
      } else {
        this.$root.$emit('raise_alert', {
          color: 'info',
          text: 'Something went wrong',
        });
      }
    },
    cancelChanges() {
      this.getData();
    },
    async unselectAll() {
      this.contactPreferences.forEach((el) => { el.enabled = 0; });
    },
    selectAll() {
      this.contactPreferences.forEach((el) => { el.enabled = 1; });
    },
  },
  async mounted() {
    // hack around missing encoded plus in email from query
    this.userEmail = this.$route.query.userEmail.replace(/ /g, '+');
    this.clientId = this.$route.query.clientId;

    if (!this.clientId || !this.userEmail) {
      this.$root.$emit('raise_alert', {
        color: 'warning',
        text: 'Unable to load contact preferences; missing email or client id',
      });
    } else {
      await this.getData();
    }
  },
};
</script>
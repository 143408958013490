<template>
  <v-container>
    <div v-if="loading" class="text-center">
      <v-progress-circular
        indeterminate
        color="primary"
        style="height: 50%; margin: auto; padding-top: 50%;" />
    </div>
    <v-row v-if="!loading" class="mt-3">
      <v-col cols="5">
        <v-row class="mx-0 mb-1">
          <h1 class="dashtitle">Appointment Types</h1>
        </v-row>
        <v-card class="justify-center" outlined>
          <div v-if="!loading" class="py-2">
            <v-list class="py-0">
              <v-list-item-group active-class="selectedItemClass" id="usersList">
                <v-list-item v-for="(appointmentType, i) in acrewAppointmentTypes" :key="i" @click="selectAppointmentType(appointmentType)" class="my-n1">
                  <v-btn class="py-2" width="100%" outlined>
                    <v-list-item-content>
                      <v-list-item-title>{{ appointmentType.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-btn>
                </v-list-item>
              </v-list-item-group>
              <span class="pa-4" v-if="!acrewAppointmentTypes.length">No appointment types have been configured yet.</span>
            </v-list>
          </div>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-row class="mx-0">
          <h1 class="dashtitle">
            <span>Appointment Type Settings</span>
          </h1>
        </v-row>
        <v-row class="mx-0 mt-6">
          <v-btn @click="showOpenDentalTypes = true" class="appSecondaryBtnColor buttons mr-5">Import from Open Dental</v-btn>
        </v-row>
        <v-card class="mt-6">
          <v-row class="my-1">
            <v-col cols="6">
              <h2>Appointment Configuration</h2>
            </v-col>
          </v-row>
          <v-row class="my-0" no-gutters>
            <v-col cols="6">
              <v-text-field v-model="form.name" label="Appointment Type Name" outlined dense class="mr-3" />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form.codes" label="Billing Codes (Comma Separated)" outlined dense class="mr-3" />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form.pattern" label="Time Pattern" outlined dense class="mr-3" />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form.appointmentLength" label="Appointment Length (Minutes)" outlined dense class="mr-3" />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form.rank" label="Display Order" outlined dense class="mr-3" />
            </v-col>
            <v-col cols="6">
              <v-checkbox v-model="form.isMinor" label="Is for minors (<18)?" outlined dense class="mr-3" />
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mt-6">
          <v-row class="my-1">
            <v-col cols="6">
              <h2>Operatories Available for Type</h2>
            </v-col>
          </v-row>
          <v-row class="my-0" no-gutters>
            <v-autocomplete
              class="pt-3"
              v-model="form.operatories"
              :items="allOperatories"
              item-text="OpName"
              item-value="OperatoryNum"
              label="Select Operatories"
              multiple
              outlined
              dense />
          </v-row>
        </v-card>
        <v-row class="mx-0 my-1">
          <v-btn class="appPrimaryBtnColor buttons mr-3" @click="saveAppointmentType()">
            Save
          </v-btn>
          <v-btn class="appSecondaryBtnColor buttons mr-3" @click="clearForm()">Cancel</v-btn>
          <v-btn class="deleteBtnColor buttons" :disabled="!form.id" @click="deleteAppointmentType()">
            Delete
          </v-btn>
        </v-row>
      </v-col>
      <v-dialog v-model="showOpenDentalTypes" max-width="600px">
        <v-card outlined class="pb-2">
          <v-card-title>Appointment Types Available from Open Dental</v-card-title>
          <v-list-item-group>
            <v-list-item v-for="(appointmentType, i) in openDentalAppointmentTypes" :key="i" @click="useOpenDentalAppointmentType(appointmentType)">
              <v-btn class="py-2" width="100%" outlined>
                <v-list-item-content>
                  <v-list-item-title>{{ appointmentType.AppointmentTypeName }}</v-list-item-title>
                </v-list-item-content>
              </v-btn>
            </v-list-item>
          </v-list-item-group>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import DataService from '../../../_services/DataService';

export default {
  name: 'AppointmentTypes',
  props: [],
  components: {},
  data() {
    return {
      form: {
        id: '',
        name: '',
        pattern: '',
        appointmentLength: 0,
        codes: '',
        isMinor: false,
        rank: 0,
        openDentalAppointmentTypeNumber: '',
        operatories: [],
      },
      acrewAppointmentTypes: [],
      openDentalAppointmentTypes: [],
      loading: true,
      showOpenDentalTypes: false,
      allOperatories: [],
    };
  },
  methods: {
    async getOpenDentalOperatories() {
      const operatoriesResponse = await DataService.getOpenDentalOperatories();
      this.allOperatories = operatoriesResponse.data;
    },
    async getAppointmentTypes() {
      const appointmentTypes = await DataService.getAppointmentTypes();
      this.acrewAppointmentTypes = appointmentTypes.data.acrewAppointmentTypes;
      this.openDentalAppointmentTypes = appointmentTypes.data.openDentalAppointmentTypes;
      this.timeIncrementPreference = appointmentTypes.data.timeIncrementPreference;
    },
    selectAppointmentType(appt) {
      this.form.id = appt.id;
      this.form.name = appt.name;
      this.form.pattern = this.createPatternBasedOnViewPreference(appt.pattern);
      this.form.appointmentLength = appt.appointmentLength;
      this.form.openDentalAppointmentTypeNumber = appt.openDentalAppointmentTypeNumber;
      this.form.codes = appt.codes;
      console.log('isminor', appt.isMinor)
      this.form.isMinor = appt.isMinor;
      this.form.rank = appt.rank;
      this.form.operatories = appt.operatories.map((op) => op.openDentalOperatoryNumber);
    },
    useOpenDentalAppointmentType(appt) {
      this.form.name = appt.AppointmentTypeName;
      this.form.pattern = appt.Pattern;
      this.form.pattern = this.createPatternBasedOnViewPreference(appt.Pattern);
      this.form.appointmentLength = `${appt.Pattern.length * 5}`;
      this.form.codes = appt.CodeStr;
      this.form.openDentalAppointmentTypeNumber = appt.AppointmentTypeNum;
      this.form.rank = this.form.rank || `${this.acrewAppointmentTypes.length + 1}`;

      this.showOpenDentalTypes = false;
    },
    clearForm() {
      this.form = {
        id: '',
        name: '',
        pattern: '',
        appointmentLength: 0,
        codes: '',
        isMinor: false,
        rank: 0,
        openDentalAppointmentTypeNumber: '',
        operatories: [],
      };
    },
    createPatternBasedOnViewPreference(pattern) {
      // display pattern based on client "time interval" preference (5/10/15)
      // 5min we show XXXXXX as XXXXXX
      // 10min we show XXXXXX as XXX
      // 15min we show XXXXXX as XX
      let builtPattern = '';
      for (let i = 0; i < pattern.length; i += this.timeIncrementPreference / 5) {
        builtPattern += pattern[i];
      }

      return builtPattern;
    },
    turnViewPatternToSystemPattern(pattern) {
      // reverse pattern from display back to 5 minute intervals
      // 5min we show XXXXXX as XXXXXX
      // 10min we show XXX as XXXXXX
      // 15min we show XX as XXXXXX
      let builtPattern = '';
      const repeatLength = this.timeIncrementPreference / 5;
      for (let i = 0; i < pattern.length; i++) {
        builtPattern += pattern[i].repeat(repeatLength);
      }

      return builtPattern;
    },
    async deleteAppointmentType() {
        await DataService.deleteAppointmentType(this.form.id);

        this.loading = true;

        this.clearForm();
        await this.getAppointmentTypes();

        this.loading = false;
    },
    async saveAppointmentType() {
      const formDataWithNormalizedPattern = {
        ...this.form,
        pattern: this.turnViewPatternToSystemPattern(this.form.pattern),
      };

      if (this.form.id) {
        await DataService.updateAppointmentType(formDataWithNormalizedPattern);
      } else {
        await DataService.createAppointmentType(formDataWithNormalizedPattern);
      }

      this.loading = true;

      this.clearForm();
      await this.getAppointmentTypes();

      this.loading = false;
    },
  },
  async mounted() {
    this.loading = true;

    await Promise.all([
      this.getOpenDentalOperatories(),
      this.getAppointmentTypes(),
    ]);

    this.loading = false;
  },
};

</script>

<style>

</style>

<template>
  <div>
    <component v-bind:is="layout"></component>
  </div>
</template>
<script>
import LayoutDefault from './layouts/Default';
import LayoutBlank from './layouts/Blank';
export default {
  components: {
    'layout-default': LayoutDefault,
    'layout-blank': LayoutBlank,
  },
  data() {
    return {};
  },
  computed: {
    layout() {
      if (
        this.$route.path === '/login' ||
        this.$route.path === '/forgot_password' ||
        this.$route.path === '/reset_password' ||
        this.$route.path === '/patient_signup' ||
        this.$route.path === '/new_account' ||
        this.$route.path === '/two_factor_authentication' ||
        this.$route.path === '/unsubscribe' ||
        this.$route.path === '/patientForms' ||
        this.$route.path === '/new_appointment' ||
        this.$route.path === '/new_account/appointment'
      ) {
        return LayoutBlank;
      } else {
        return LayoutDefault;
      }
    },
  },
};
</script>

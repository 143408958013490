<template>
  <v-container>
    <!--lists loader -->
    <div v-if="loading" class="text-center">
      <v-progress-circular
        indeterminate
        color="primary"
        style="
          height: 50%;
          margin: auto;
          padding-top: 50%;
        "></v-progress-circular>
    </div>
    <v-row v-if="!loading">
      <v-col cols="12" sm="6" md="6">
        <v-row class="mx-3">
          <h1 class="dashtitle">Settings</h1>
        </v-row>
        <v-row no-gutters class="mx-3">
          <v-col cols="12">
            <v-col @click="triggerPrompt" cols="12">
              <v-row class="my-1"> </v-row>
              <v-subheader class="pa-0">
                Payment Processing Information
              </v-subheader>
              <v-text-field
                label="Payconnect API Key"
                v-model="form.payconnectApiKey"
                outlined
                dense
                :disabled="!allowUpdate"></v-text-field>
              <v-text-field
                label="Payconnect Secret"
                v-model="form.payconnectSecret"
                outlined
                dense
                :disabled="!allowUpdate"></v-text-field>
              <v-subheader class="pa-0"> Open Dental Information </v-subheader>
              <v-text-field
                label="Open Dental dev Key"
                v-model="form.devKey"
                outlined
                dense
                :disabled="!allowUpdate">
              </v-text-field>
              <v-text-field
                label="Open Dental Api Key"
                v-model="form.apiKey"
                outlined
                dense
                :disabled="!allowUpdate"></v-text-field>
              <v-subheader class="pa-0"> Mailgun Information </v-subheader>
              <v-text-field
                label="Mailgun Api Key"
                v-model="form.mailgunApiKey"
                outlined
                dense
                :disabled="!allowUpdate"></v-text-field>
              <v-text-field
                label="Mailgun Domain"
                v-model="form.mailgunDomain"
                outlined
                dense
                :disabled="!allowUpdate"></v-text-field>
              <v-text-field
                label="Mailgun Send As"
                v-model="form.mailgunSendAs"
                outlined
                dense
                :disabled="!allowUpdate">
              </v-text-field>
              <v-subheader class="pa-0"> Twilio Information </v-subheader>
              <v-text-field
                label="Twilio Account SID"
                v-model="form.twilioSID"
                outlined
                dense
                :disabled="!allowUpdate">
              </v-text-field>
              <v-text-field
                label="Twillio Auth Token"
                v-model="form.twilioAuthToken"
                outlined
                dense
                :disabled="!allowUpdate">
              </v-text-field>
              <v-text-field
                label="Twilio Send From"
                v-model="form.twilioSendFrom"
                outlined
                dense
                :disabled="!allowUpdate">
              </v-text-field>
            </v-col>

            <v-row class="mx-0 my-1">
              <v-btn
                class="appPrimaryBtnColor buttons mr-3"
                :disabled="!allowUpdate"
                @click="updateClientInfo"
                >Update</v-btn
              >
              <v-btn class="appSecondaryBtnColor buttons" @click="cancelUpdate"
                >Cancel</v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      
      <v-col cols="12" sm="6" md="6">
        <v-row>
          <h1 class="dashtitle">Service Messages</h1>
        </v-row>
        <v-row class="mt-8">
          <v-text-field
            label="Global Service Message"
            v-model="globalServiceMessage"
            outlined
            dense />
        </v-row>
        <v-row>
          <v-text-field
            label="Appointment Scheduling Message"
            v-model="schedulingMessage"
            outlined
            dense />
        </v-row>

        <v-row>
          <v-btn class="appPrimaryBtnColor buttons mr-3" @click="updateServiceMessages">Save</v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-col cols="auto">
      <v-dialog v-model="updateDialog" max-width="400">
        <template>
          <v-card class="pt-4">
            <v-card-text
              >Changing these values could result in data not being properly
              transmitted to these providers. Do not proceed unless you have
              been advised to do so.</v-card-text
            >
            <v-card-actions>
              <v-btn color="green darken-1" text @click="updateDialog = false">
                Cancel
              </v-btn>
              <v-btn color="green darken-1" text @click="allowEdit">
                Acknowledge
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
    <v-col cols="auto">
      <v-dialog v-model="confirmUpdate" max-width="300">
        <template>
          <v-card class="pt-4">
            <v-card-text
              >Are you sure you want to submit these changes?</v-card-text
            >
            <v-card-actions>
              <v-btn color="green darken-1" text @click="updateClientInfo">
                Yes
              </v-btn>
              <v-btn color="green darken-1" text @click="rejectChanges">
                No
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
    <v-col cols="auto">
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="dialogFail">
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="error" dark>ERROR</v-toolbar>
            <v-card-text>
              <div class="text-p1 pa-12">
                Open Dental API Configurations test failed. Your changes will
                not be saved.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
    <v-col cols="auto">
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="dialogSuccess">
        <template>
          <v-card>
            <v-toolbar color="success" dark>SUCCESS</v-toolbar>
            <v-card-text>
              <div class="text-p1 pa-12">
                Open Dental API Configurations test successful! Your changes
                have been saved.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="closeAndReload()">Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-container>
</template>
<script>
import DataService from '../../../_services/DataService';
import ValidateService from '../../../_services/ValidateService';

export default {
  name: 'CompanyConfig',
  data() {
    return {
      // form fields
      form: {
        payconnectApiKey: '',
        payconnectSecret: '',
        devKey: '',
        apiKey: '',
        mailgunApiKey: '',
        mailgunDomain: '',
        mailgunSendAs: '',
        twilioSID: '',
        twilioAuthToken: '',
        twilioSendFrom: '',
      },
      globalServiceMessage: '',
      schedulingMessage: '',
      dialogSuccess: false,
      dialogFail: false,
      allowUpdate: false,
      updateDialog: false,
      confirmUpdate: false,
      loading: true,
      rules: ValidateService.validators,
    };
  },
  methods: {
    closeDialog() {
      this.dialogSuccess = false;
      this.dialogFail = false;
    },
    cancelUpdate() {
      location.reload();
    },
    async closeAndReload() {
      await this.getClientInfo();
      this.dialogSuccess = false;
    },
    async getClientInfo() {
      this.loading = true;

      const response = await DataService.getConfigInfoById(DataService.getClientId());

      if (response.status == 200) {
        if (response.data) {
          this.convertedLogo = response.data.clientLogo;
          this.form = {
            payconnectApiKey: response.data.payconnectApiKey,
            payconnectSecret: response.data.payconnectSecret,
            gateWay: response.data.authNetGatewayId,
            devKey: response.data.openDentalDevKey,
            apiKey: response.data.openDentalApiKey,
            mailgunApiKey: response.data.mailgunApiKey,
            mailgunDomain: response.data.mailgunDomain,
            mailgunSendAs: response.data.mailgunSendAs,
            twilioSID: response.data.twilioAccountSid,
            twilioAuthToken: response.data.twilioAuthToken,
            twilioSendFrom: response.data.twilioSendFrom,
          };
        }
        this.allowUpdate = false;
        this.loading = false;
      } else {
        this.displayError();
      }
    },
    displayError(msg) {
      let str = 'Something went wrong';

      if (msg !== undefined) str = msg;
      this.$root.$emit('raise_alert', {
        color: 'info',
        text: str,
      });
    },
    async updateClientInfo() {
      const body = {
        payconnectApiKey: this.form.payconnectApiKey,
        payconnectSecret: this.form.payconnectSecret,
        openDentalDevKey: this.form.devKey,
        openDentalApiKey: this.form.apiKey,
        mailgunApiKey: this.form.mailgunApiKey,
        mailgunDomain: this.form.mailgunDomain,
        mailgunSendAs: this.form.mailgunSendAs,
        twilioAccountSid: this.form.twilioSID,
        twilioAuthToken: this.form.twilioAuthToken,
        twilioSendFrom: this.form.twilioSendFrom,
      };
      DataService.checkAuthKeys(body)
        .then(async (response) => {
          if (response.status === 200) {
            await DataService.updateConfigInfoById(DataService.getClientId(), body);
            this.confirmUpdate = false;
            this.dialogSuccess = true;
          } else {
            this.displayError();
          }
        })
        .catch((e) => {
          if (e.response.status === 500) {
            this.dialogFail = true;
          }
          if (e.response.status === 413) {
            this.dialogFail = true;
          }
        });
    },
    async getServiceMessages() {
      const response = await DataService.getServiceMessages();

      if (response.status === 200) {
        const serviceMessages = response.data;
console.log('why')
        this.globalServiceMessage = serviceMessages.find((message) => !message.page)?.message;
        this.schedulingMessage = serviceMessages.find((message) => message.page === 'scheduling')?.message;
      }
    },
    async updateServiceMessages() {
      const messages = [];
      if (this.globalServiceMessage) {
        messages.push({ message: this.globalServiceMessage });
      }

      if (this.schedulingMessage) {
        messages.push({
          message: this.schedulingMessage,
          page: 'scheduling',
        });
      }

      const response = await DataService.updateServiceMessages(messages);
      if (response.status === 200) {
        this.$root.$emit('raise_alert', {
          color: 'info',
          text: 'Saved service messages',
        });
      } else {
        this.$root.$emit('raise_alert', {
          color: 'error',
          text: 'Failed to save service messages',
        });
      }
    },

    allowEdit() {
      this.allowUpdate = true;
      this.updateDialog = false;
    },
    triggerPrompt() {
      if (!this.allowUpdate) {
        this.updateDialog = true;
      }
    },
    getConfirmation() {
      if (this.allowUpdate) {
        this.confirmUpdate = true;
      } else {
        this.updateDialog = true;
      }
    },
    rejectChanges() {
      this.updateDialog = false;
      this.allowUpdate = false;
      this.confirmUpdate = false;
    },
  },
  async mounted() {
    await Promise.all([
      this.getClientInfo(),
      this.getServiceMessages(),
    ]);
  },
};
</script>
<style>
.buttons {
  color: #414a5b;
  font-family: 'Inter', sans-serif;
  font-size: 12px !important;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: normal;
  padding: 20px;
}
</style>

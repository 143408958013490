<template>
  <v-navigation-drawer
    v-model="sidebarMenu"
    app
    floating
    color="navcolor"
    :permanent="sidebarMenu"
    :width="100">
    <v-list-item>
      <v-list-item class="py-3">
      </v-list-item>
    </v-list-item>
    <v-list v-for="menuItem in menuArray" :key="menuItem.title" dark>
      <!-- Not currently using sub-links, so this section is commented out -->
      <!--<v-list-group no-action v-if="menuItem.subLinks" class="nav-sublink">
        <v-list-item
          slot="activator"
          :to="menuItem.link"
          :href="menuItem.href"
          :target="menuItem.target"
        >
          <v-list-item-icon>
            <v-icon>{{ menuItem.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="sub in menuItem.subLinks"
          :key="sub.title"
          :to="sub.link"
          :href="menuItem.href"
          :target="sub.target"
        >
          <v-list-item-title>{{ sub.title }}</v-list-item-title>
        </v-list-item>
      </v-list-group> -->
      <!-- Not currently using sub-links, so this section is commented out -->
      <v-list-item
        :to="menuItem.link"
        :href="menuItem.href"
        :target="menuItem.target"
        active-class="highlighted"
        class="justify-center">
        <v-btn x-large color="primary" class="px-0 py-8" max-width="93px">
          <div class="d-flex flex-column">
            <v-icon large>{{ menuItem.icon }}</v-icon>
            <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
          </div>
        </v-btn>
      </v-list-item>
    </v-list>
    <div class="justify-center">
      <v-btn
        x-large
        color="logout-btn"
        class="py-8 mb-1"
        max-width="93px"
        @click="onLogout">
        <div class="d-flex flex-column">
          <v-icon large>mdi-logout</v-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </div>
      </v-btn>
    </div>
  </v-navigation-drawer>
</template>
<script>
import DataService from '../../_services/DataService';
export default {
  data() {
    return {
      sidebarMenu: true,
      drawer: true,
      permission: '',
      menuArray: [],
      page: '/dashboard',
      patient: [
        {
          icon: 'mdi-home-outline',
          title: 'Dashboard',
          link: '/dashboard',
        },
        {
          icon: 'mdi-calendar-month',
          title: 'Schedule',
          link: '/patient_schedule',

          // link below is left for reference:
          // link: '/scheduled_appointments',
        },
        {
          icon: 'mdi-account-circle-outline',
          title: 'Account',
          link: '/account',
        },
      ],
      clientAdmin: [
        {
          icon: 'mdi-home-outline',
          title: 'Home',
          link: '/dashboard',
        },
        {
          icon: 'mdi-email',
          title: 'Messaging',
          link: '/messaging',
        },
        {
          icon: 'mdi-shield-account-outline',
          title: 'Admin',
          link: '/admin',
        },
        {
          icon: 'mdi-account-circle-outline',
          title: 'Account',
          link: '/account',
        },
      ],
      clientUser: [
        {
          icon: 'mdi-home-outline',
          title: 'Home',
          link: '/dashboard',
        },
        {
          icon: 'mdi-email',
          title: 'Messaging',
          link: '/messaging',
        },
        {
          icon: 'mdi-shield-account-outline',
          title: 'Admin',
          link: '/admin',
        },
        {
          icon: 'mdi-account-circle-outline',
          title: 'Account',
          link: '/account',
        },
      ],
      mini: true,
    };
  },
  mounted() {
    switch (DataService.getRole()) {
      case 'clientAdmin':
        this.menuArray = this.clientAdmin;
        break;
      case 'patient':
        this.menuArray = this.patient;
        break;
      case 'clientUser':
        this.menuArray = this.clientUser;
        break;
      default:
        this.menuArray = [];
    }
  },
  watch: {
    $route() {
      this.page = this.$route.path
      this.loggedin = this.userIsAuthenticated();
    },
  },
  methods: {
    userIsAuthenticated() {
      let isAuthenticated = false;
      if (localStorage.getItem('user')) isAuthenticated = true;
      return isAuthenticated;
    },
    onLogout() {
      this.$root.$emit('logout');
      DataService.logout();
    },
  },
};
</script>
<style scoped>
.highlighted button {
  background-color: #b0d35e !important;
  color: #414a5b !important;
  box-shadow: 20px;
  /* padding: 30px 0 !important; */
}
.logout-btn {
  background-color: #b0d35e !important;
  color: #414a5b !important;
  box-shadow: 20px;
  position: absolute;
  bottom: 0;
  margin-left: 3px;
}
.v-navigation-drawer
  .v-list-item--active.v-list-item.v-list-item--link::before {
  opacity: 0;
}
.custom-border {
  border: yellow 5px dotted;
}
</style>

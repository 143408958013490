<template>
  <v-layout row wrap class="no-toppad" no-gutters text-xs-right>
    <v-container class="">
      <v-row noGutters>
        <v-col cols="4" sm="4" md="4">
          <v-text-field
            v-on:keyup="ReportFilterChange"
            v-model="search"
            label="Search"></v-text-field>
        </v-col>
      </v-row>
      <v-row noGutters>
        <v-col cols="12" sm="12">
          <v-card class="mx-auto" tile elevation="0">
            <!-- datagrid loader -->
            <v-skeleton-loader
              v-if="loading"
              type="table-heading"
              :loading="loading"></v-skeleton-loader>
            <!-- datagrid -->
            <v-data-table
              :headers="activeCols"
              :items="rows"
              :sort-by.sync="orderkey"
              :sort-desc.sync="orderkeyDir"
              :hide-default-footer="true"
              disable-pagination
              must-sort
              dense
              @click:row="handleRowClick"
              v-if="!loading">
              <!-- format rows in datagrid -->
              <template v-slot:[`item.actions`]="{item}">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-icon
                    title="Edit User"
                    style="margin-right: 10px"
                    color="success"
                    slot="activator"
                    small
                    @click="openEditForm(item.id)"
                    >mdi-pencil</v-icon
                  >
                  <v-icon
                    title="Delete User"
                    color="error"
                    small
                    @click="deleteForm(item.id)"
                    >mdi-delete</v-icon
                  >
                </v-card-actions>
              </template>
              <template v-slot:[`item.dateSent`]="{item}">
                {{ formatDate(item.dateSent) + ' ' + formatTimeFromDate(item.dateSent) }}
              </template>

              <!-- end format rows in datagrid -->
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" sm="2" md="1" justify="start">
          <v-select
            class="dg-page-size"
            v-model="pageSize"
            :items="pageSizes"
            label="Items per Page"
            @change="handlePageSizeChange"></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2" sm="2" md="2" offset="1" justify="end">
          <v-pagination
            v-model="page"
            :length="totalPages"
            total-visible="10"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @input="handlePageChange"></v-pagination>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>
<script>
import Utils from '../../../src/utils.js';
const {jsPDF} = require('jspdf');

export default {
  name: 'Email',
  props: ['headers', 'defOrderkeyDefault', 'defOrderkey', 'rowClick'],
  components: {},
  data() {
    return {
      search: '',
      loading: true,
      // form control
      dialogEditColumns: false,
      dialogCreate: false,
      dialogEdit: false,
      menu: false,
      clickable: false,
      rows: [],
      pdf: [],
      csv: [],
      orderkeyDefault: this.defOrderkeyDefault,
      orderkey: this.defOrderkey,
      orderkeyDir: true,
      orderkeyVal: 'DESC',
      page: 1,
      totalPages: 0,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100, 200],
      formatDate: Utils.formatDate,
      formatTimeFromDate: Utils.formatTimeFromDate,
    };
  },
  computed: {
    activeCols: function () {
      return this.headers.filter(function (u) {
        return u.active == 1;
      });
    },
  },
  watch: {
    orderkey: {
      handler() {
        this.handleSortChange();
      },
      deep: false,
    },
    orderkeyDir: {
      handler() {
        this.handleSortChange();
      },
      deep: false,
    },
  },
  methods: {
    // Datagrid Functions
    setActiveCols: function (d, item) {
      //Billy: Commented out because "No Manipulating of Props"
      //let n = 1;
      //if (item.active == 1) n = 0;
      //this.headers[parseInt(d)].active = parseInt(n);
    },
    async refreshList() {
      await this.getData();
    },
    async handlePageChange(value) {
      this.page = value;
      await this.getData();
    },
    handleRowClick(value) {
      if (this.clickable == true) {
        this.$parent.handleRowClick(value);
      }
    },
    async handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      await this.getData();
    },
    async ReportFilterChange() {
      this.page = 1;
      await this.getData();
    },
    async handleSortChange() {
      if (this.orderkey) this.orderkeyDefault = this.orderkey;
      if (this.orderkeyDir) this.orderkeyVal = 'DESC';
      else this.orderkeyVal = 'ASC';
      await this.getData();
    },
    async getData() {
      let params = {};
      this.loading = true;
      params['orderkey'] = this.orderkey[0]
        ? this.orderkey[0]
        : this.orderkeyDefault;
      if (this.orderkey[0].length == 0)
        params['orderkey'] = this.orderkeyDefault;
      params['orderkeyVal'] = this.orderkeyVal;
      params['page'] = this.page - 1;
      params['size'] = this.pageSize;
      params['search'] = this.search;
      this.$parent.getData(params);
      setTimeout(() => (this.loading = false), 100);
    },
    getDisplayData(d) {
      return {
        id: d.id,
        from: d.from,
        subject: d.subject,
        sent: d.sent,
      };
    },
    openEditColumns() {
      this.dialogEditColumns = true;
    },
  },
  created() {},
  async mounted() {
    if (localStorage.getItem('token')) {
      this.rowClick ? (this.clickable = true) : null;
      await this.getData();
    }
  },
};
</script>
<style></style>

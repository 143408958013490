<template>
  <v-card
    :key="componentKey"
    height="300"
    class="text-center rounded-lg"
    dark
    flat
    color="cardcolor"
    @click="goToPage()">
    <v-row class="pt-2">
      <v-col cols="12">
        <v-icon large class="iconAndBackg">
          {{ pageIcon }}
        </v-icon>
      </v-col>
      <v-col class="pt-0">
        <h2 class="cardTitle mx-5 white--text">
          {{ pageName }}
        </h2>
      </v-col>
    </v-row>
    <div class="d-flex flex-column justify-center px-6">
      <div v-for="(item, i) in this.data" :key="i" class="pt-2">
        <template v-if="!item.loading">
          <div
            v-if="item.date"
            style="font-size: 0.9rem"
            class="grey--text text--lighten-2">
            {{ item.date }}
          </div>
          <div
            v-if="item.desc"
            style="font-size: 0.9rem"
            class="grey--text text--lighten-2 pb-3">
            {{ userInfo.firstName }}, {{ item.desc }}
          </div>
          <div
            v-if="item.desc2"
            style="font-size: 0.9rem"
            class="grey--text text--lighten-2">
            {{ item.desc2 }}
            <h4 v-if="item.amount">{{ item.amount }}</h4>
          </div>
          <div
            v-if="item.time"
            style="font-size: 0.9rem"
            class="grey--text text--lighten-2">
            {{ item.time }}
          </div>
          <div
            v-if="item.from"
            style="font-size: 0.9rem"
            class="grey--text text--lighten-2 pt-2">
            {{ item.from }}
          </div>
          <h4 v-if="item.doctor" class="grey--text text--lighten-1 pt-1">
            {{ item.doctor }}
          </h4>
          <div
            v-if="item.officeDesc"
            style="font-size: 0.9rem"
            class="grey--text text--lighten-2">
            {{ item.officeDesc }}
          </div>
          <div
            v-if="item.location"
            style="font-size: 0.9rem"
            class="grey--text text--lighten-2">
            {{ item.location }}
          </div>
          <div
            v-if="item.location2"
            style="font-size: 0.9rem"
            class="grey--text text--lighten-2">
            {{ item.location2 }}
          </div>
          <div
            v-if="item.phone"
            style="font-size: 0.9rem"
            class="grey--text text--lighten-2">
            {{ item.phone }}
          </div>
          <h3 v-if="item.subject" class="grey--text text--lighten-1 pt-2">
            {{ item.subject }}
          </h3>
          <h4
            v-if="item.patient"
            class="grey--text text--lighten-1 mt-3=2 pb-2">
            {{ item.patient }}
          </h4>
          <h4 v-if="item.alert">
            <v-icon>mdi-alert</v-icon>
            {{ item.alert }}
          </h4>
          <div class="px-3">{{ item.body }}</div>
          <div style="display: flex; justify-content: center">
            <v-row style="bottom: 0; position: absolute">
              <v-col class="ma-0 pa-0 pt-3">
                <span v-if="item.amount">
                  <v-btn
                    color="#B0D35E"
                    href="#"
                    class="ma-3 mb-8 tileBtns"
                    elevation="5"
                    >Pay Now</v-btn
                  >
                </span>
                <span v-if="item.schedule">
                  <v-btn
                    color="#B0D35E"
                    href="#"
                    class="ma-3 mb-8 tileBtns"
                    elevation="5"
                    >Schedule Now</v-btn
                  >
                </span>
                <span v-if="item.print">
                  <v-btn
                    color="#B0D35E"
                    href="#"
                    class="ma-3 mb-8 tileBtns"
                    elevation="5"
                    >Print</v-btn
                  >
                </span>
                <span v-if="item.btn">
                  <v-btn
                    color="#B0D35E"
                    href="#"
                    class="ma-3 mb-8 tileBtns"
                    elevation="5"
                    >{{ item.btn }}</v-btn
                  >
                </span>
              </v-col>
            </v-row>
          </div>
        </template>
        <template v-else>
          <div class="text-center spinner">
            <v-progress-circular
              indeterminate
              color="secondary"
              style="
                height: 50%;
                margin: auto;
                padding-top: 20%;
              "></v-progress-circular>
          </div>
        </template>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  name: 'Notification',
  props: ['tile_data'],
  components: {},
  data() {
    return {
      userInfo: '',
      tabSelect: '',
      componentKey: 0,
    };
  },
  computed: {},
  watch: {
    tile_data: {
      handler() {
        this.setTileData();
        this.componentKey += 1;
      },
      deep: true,
    },
  },
  methods: {
    setTileData() {
      this.pageName = this.tile_data.title;
      this.pageIcon = this.tile_data.icon;
      this.data = this.tile_data.data;
      this.loading = this.tile_data.data.loading;
      this.userInfo = JSON.parse(localStorage.getItem('user'));
    },
    goToPage() {
      if (this.data[0].action) {
        this.data[0].action();
      } else {
        this.$router.push({
          name: this.data[0].page,
          query: { tab: this.data[0].tab },
        });
      }
    },
  },
  created() {
    this.setTileData();
  },
  mounted() {
    this.setTileData();
  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');
@media (min-width: 600px) {
  .cardTitle {
    font-size: 22px !important;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
  }
}
@media screen and (max-width: 600px) {
  .text-center.spinner {
    height: 50%;
    margin: auto;
    padding-top: 15%;
  }
}

.iconAndBackg {
  font-size: 40px;
  font-weight: lighter;
  background-color: #6bb2f4;
  padding: 5px;
  border-radius: 5px;
  color: #414a5b;
}
.tileBtns {
  color: #414a5b;
  background-color: #b0d35e;
  font-family: 'Inter', sans-serif;
  font-size: 12px !important;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: normal;
}
</style>

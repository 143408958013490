<template>
  <v-form @submit.prevent="submit" v-model="isFormValid" lazy-validation>
    <v-container>
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="4">
          <v-text-field
            v-model="form.email"
            label="Email"
            :rules="[rules.required, rules.email]"
            outlined
            dense
            required
            type="email" />

          <v-text-field
            v-model="form.firstName"
            label="First Name"
            :rules="[rules.required, rules.name]"
            outlined
            dense
            type="text" />

            <v-text-field
            v-model="form.lastName"
            label="Last Name"
            :rules="[rules.required, rules.name]"
            outlined
            dense
            type="text" />

          <v-text-field
            v-model="form.phone"
            label="Phone Number"
            :rules="[rules.phone]"
            outlined
            dense
            type="phone" />

          <v-btn
            color="#B0D35E"
            class="mr-5"
            @click="submitForm"
            dense
            :disabled="!isFormValid">
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import DataService from '../../../_services/DataService';
import ValidateService from '../../../_services/ValidateService';

export default {
  data() {
    return {
      isFormValid: false,
      form: {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
      },
      rules: ValidateService.validators,
    }
  },
  computed: {
    passwordConfirmationRule() {
      return () => (this.form.new_password === this.form.repeat_password) || 'Passwords must match'
    }
  },
  methods: {
    async submitForm() {
      const response = await DataService.updateAccountInfo(this.form);
      if (response.status === 200) {
        localStorage.setItem('user', JSON.stringify(this.form));
        this.$root.$emit('raise_alert', { color: 'success', text: 'Account updated' });
      } else {
        this.$root.$emit('raise_alert', { color: 'error', text: response.data || 'Something went wrong.' });
      }
    }
  },
  mounted() {
    this.form = JSON.parse(localStorage.getItem('user'));
  },
}
</script>
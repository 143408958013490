<template>
  <v-card class="pb-5 mx-3 mx-sm-5">
    <v-row>
      <v-col class="pt-5">
        <h1 class="dashtitle">{{ this.pageName }}</h1>
      </v-col>
      <v-col class="mt-1 text-right"> </v-col>
    </v-row>
    <h2 v-if="family.length > 1">View Plan for For</h2>
    <v-select
      v-if="family.length > 1"
      v-model="selectedPatient"
      :items="family"
      :item-text="item => `${item.firstName} ${item.lastName}`" 
      item-value="item => item.id"
      label="Patient"
      return-object
      outlined
      dense
      style="width: 400px"
      @change="handlePatientSelect" />

    <v-card>
      <v-skeleton-loader
        v-if="loading"
        type="table-heading"
        :loading="loading" />

      <v-card
        v-for="(plan, i) in plannedAppointments"
        :key="i"
        class="my-2 pa-4"
        elevation="0"
        outlined
        rounded>
        <v-btn @click="navigateToBookAppointment(plan)" outlined class="float-right mt-3">Book Appointment</v-btn>
        <h3>{{ plan.description }}</h3>

        <h4>Provider</h4>
        <span class="mb-2">{{ plan.items[0].providerFirstName }} {{ plan.items[0].providerLastName }}</span>

        <h4>Duration</h4>
        <span class="mb-4">{{ formatDuration(determineProcedureLength(plan.items, plan.pattern)) }}</span>

        <h4>Cost Estimate Info</h4>
        <div v-if="plan.fee > 0" class="mb-2">
          Overall Fee: {{ formatCurrency(plan.fee) }}<br />
          Patient Portion: {{ formatCurrency(plan.patientPortion) }}
        </div>
        <div v-if="plan.fee <= 0" class="mb-2">We weren't able to estimate the fee for this procedure</div>

        <v-data-table
          class="elevation-1"
          v-if="!loading"
          item-key="name"
          :headers="treatmentItemHeaders"
          :items="plan.items"
          hide-default-footer
          disable-pagination>
          
          <template v-slot:[`item.fee`]="{item}">{{ item.fee > 0 ? formatCurrency(item.fee) : '' }}</template>
          <template v-slot:[`item.insuranceAllowedAmount`]="{item}">{{ item.fee > 0 ? formatCurrency(item.insuranceAllowedAmount) : '' }}</template>
          <template v-slot:[`item.primaryInsuranceAmount`]="{item}">{{ item.fee > 0 ? formatCurrency(item.primaryInsuranceAmount) : '' }}</template>
          <template v-slot:[`item.patientPortion`]="{item}">{{ item.fee > 0 ? formatCurrency(item.patientPortion) : '' }}</template>
        </v-data-table>
      </v-card>

      <div v-if="!loading && !plannedAppointments.length">
        There are no current treatment plans for this patient
      </div>
    </v-card>
  </v-card>
</template>

<script>
import DataService from '../../_services/DataService';
import Utils from '../utils';

export default {
  name: 'PatientTreatmentPlans',
  data() {
    return {
      pageName: 'Treatment Plan',
      page: 1,
      loading: true,
      selectedPatient: {},
      family: [],
      treatmentPlans: [],
      plannedAppointments: [],
      timeIncrementPreference: 10,
      treatmentItemHeaders: [
        {
          text: 'Procedure Code',
          value: 'procedureCode',
          sortable: false,
          active: true,
          align: 'start',
        },
        {
          text: 'Tooth #',
          value: 'toothNumber',
          sortable: false,
          active: true,
          align: 'start',
        },
        {
          text: 'Description',
          value: 'procedureCodeDescription',
          sortable: false,
          active: true,
          align: 'start',
        },
        {
          text: 'Procedure Fee',
          value: 'fee',
          sortable: false,
          active: true,
          align: 'end',
        },
        {
          text: 'Allowed Amount',
          value: 'insuranceAllowedAmount',
          sortable: false,
          active: true,
          align: 'end',
        },
        {
          text: 'Primary Insurance',
          value: 'primaryInsuranceAmount',
          sortable: false,
          active: true,
          align: 'end',
        },
        {
          text: 'Patient Portion',
          value: 'patientPortion',
          sortable: false,
          active: true,
          align: 'end',
        },
      ],
      formatCurrency: Utils.formatCurrency,
      formatDuration: Utils.formatDuration,
    };
  },
  methods: {
    async getPatientFamily() {
      const response = await DataService.getPatientFamily();
      if (response.status === 200) {
        this.selectedPatient = response.data.find((patient) => patient.status === 'Self');
        const rest = response.data.filter((patient) => patient.status !== 'Self');
        rest.sort((a, b) => a.firstName > b.firstName ? 1 : -1); // first name sort because family. diff last names are shrug
        this.family = [this.selectedPatient, ...rest];
      }
    },
    async getPatientTreatmentPlan() {
      const response = await DataService.getTreatmentPlansForPatient(this.selectedPatient.status === 'Self' ? undefined : this.selectedPatient.id);
      if (response.status === 200) {
        this.treatmentPlans = response.data.treatmentPlans;
        this.plannedAppointments = response.data.plannedAppointments;
      }
    },
    async handlePatientSelect(item) {
      this.loading = true;

      this.selectedPatient = item;
      await this.getPatientTreatmentPlan();

      this.loading = false;
    },
    getProcedureLengthDisplay(items) {
      let minutes = this.determineProcedureLength(items);
      const hours = Math.floor(minutes / 60);
      if (hours > 0) {
        minutes = minutes % 60;
        return `${hours}hr ${minutes}min`;
      }

      return `${minutes} min`;
    },
    determineProcedureLength(items, pattern) {
      if (pattern) {
        return pattern.length * 5;
      }

      let xCount = 0;
      let isAllHygiene = true;
      for (const item of items) {
      if (item.isHygiene) {
          xCount += item.procedureCodePattern.length;
        } else {
          isAllHygiene = false;
          for (const char of item.procedureCodePattern) {
            if (char.toLowerCase() === 'x') {
              xCount++;
            }
          }
        }
      }

      // For normal appointments, provider time bracketed is by
      // hygienist time (two slashes), times five minutes per item
      if (!isAllHygiene) {
        xCount += 2;
      }

      return xCount * 5;
    },
    navigateToBookAppointment(plan) {
      this.$router.push({
        name: 'PatientSchedule',
        query: {
          patientId: this.selectedPatient.id,
          providerId: plan.items[0].providerId,
          pattern: plan.pattern,
          plannedAppointmentId: plan.plannedAppointmentId,
          duration: this.determineProcedureLength(plan.items, plan.pattern),
          description: `Treatment of ${plan.description} plan`,
        },
      });
    },
  },
  async mounted() {
    this.loading = true;

    await this.getPatientFamily();
    await this.getPatientTreatmentPlan();

    this.loading = false;
  },
};
</script>
<style scoped></style>

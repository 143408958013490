<template>
  <v-container>
    <v-layout row>
      <v-flex class="mb-6">
        <v-card class="login-card text-center" tile>
          <img
            v-if="!clientColor"
            src="@/../public/static/img/acrew-sec-logo.png"
            alt="Logo"
            width="400px"
          />
          <v-card-title v-if="clientColor" class="client-banner mb-2">
            <img
              cover
              :width="150"
              :src="clientLogo"
              v-if="clientLogo" />
          </v-card-title>
          <v-card-text>
            <v-container class="login-inner-form">
              <v-form 
                ref="form"
                @submit.prevent="login"
                lazy-validation
                >
                <v-layout row wrap>
                  <v-flex xs12>
                    <h3 class="logn-header">Sign In</h3>
                    <h4 v-if="error" class="logn-header" style="color: red">
                      Wrong Email/Password combination
                    </h4>
                    <p v-if="!!error">{{ error.message }}</p>
                    <v-text-field
                      background-color="white"
                      label="Email"
                      v-model="username"
                      placeholder=" "
                      :rules="[rules.required, rules.loginEmail]"
                      type="email"
                      outlined
                      dense />
                    <v-text-field
                      background-color="white"
                      label="Password"
                      v-model="password"
                      placeholder=""
                      :rules="[rules.required]"
                      type="password"
                      outlined
                      dense />
                    <v-container class="form-bottom">
                      <v-row no-gutters style="padding-bottom: 15px">
                        <v-col md="12" class="text-center">
                          <v-btn
                            class="sign-in primary btns"
                            type="submit"
                            style="width: 200px">
                            Sign in
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                </v-layout>
              </v-form>
              <div class="mt-2">
                <v-btn
                  class="sign-up appPrimaryBtnColor btns mr-2"
                  style="width: 160px"
                  @click="goToSignUp()">
                  Sign Up
                </v-btn>
                <v-btn
                  class="btns"
                  style="background-color: #74c9cc !important"
                  @click="goToForgotPassword()">
                  Forgot Password?
                </v-btn>
              </div>
            </v-container>
          </v-card-text>
          <div v-if="clientColor">
            <span style="color: #aaa">Powered by</span>
            <br />
            <img
              src="@/../public/static/img/acrew-sec-logo.png"
              alt="Logo"
              width="150px" />
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<style scoped>
@media screen and (max-width: 450px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.center-all {
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-center {
  margin-left: auto;
  margin-right: auto;
}

.theme--light.v-card.login-card {
  max-width: 500px;
  background-color: #ffffff !important;
  margin: 0 auto;
  margin-top: 10vh;
  box-shadow: 0 !important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.login-inner-form {
  background-color: #ffffff !important;
  padding: 1rem 2.5rem 0 2.5rem;
  /* font-family: 'Inter', sans-serif; */
}

.logn-header {
  font-family: 'Inter', sans-serif;
  /* font-weight: normal; */
  margin-bottom: 1rem;
  color: #68b4c9;
}

.v-input--selection-controls {
  margin-top: 0;
}

.form-bottom {
  padding: 0;
}
.btns {
  color: #414a5b;
  /* background-color: #b0d35e; */
  font-family: 'Inter', sans-serif;
  font-size: 12px !important;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: normal;
}
.client-banner {
  background-color: v-bind(clientColor) !important;
}

.client-banner img {
  margin: auto;
}
</style>
<script>
import DataService from '../../_services/DataService';
import ValidateService from '../../_services/ValidateService';
export default {
  name: 'Login',
  data() {
    return {
      clientLogo: null,
      clientColor: null,
      rules: ValidateService.validators,
      username: '',
      password: '',
      form: {},
      permissionLevel: '',
      error: false,
      redirectURI: "",
      isEnabled: process.env.VUE_APP_2FA_ENABLED === 'true' ? true : false,
    };
  },
  methods: {
    goToSignUp() {
      this.$router.push('new_account');
    },
    goToForgotPassword() {
      this.$router.push({
        name: 'ForgotPassword',
        query: {
          username: this.username,
        },
      });
    },
    async login() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const response = await DataService.signin(this.username, this.password);
      if (response.status === 200) {
        localStorage.setItem('twoFactorToken', response.data.token); // hang onto our auth token
        this.$router.push({
          name: 'TwoFactorAuthentication',
          query: {
            sentSms: response.data.sentSms,
            redirectURI: this.redirectURI,
            username: this.username,
          },
        });
      } else {
        this.error = true;
      }
    },
    getPermission(userGroup) {
      switch (userGroup) {
        case 1:
          this.permissionLevel = 'Admin';
          break;
        case 7:
          this.permissionLevel = 'Patient';
          break;
        case 2:
          this.permissionLevel = 'Staff';
          break;
      }
    },
    async getClientPreferences() {
      const response = await DataService.getClientPreferences();
      if (response.status === 200) {
        this.clientLogo = response.data.clientLogo;
        this.clientColor = response.data.clientColor;
      }
    },
  },
  created() {
    if(localStorage.getItem('token') != null) {
      if(this.$route.query.redirectURI != undefined) {
        window.location.href = this.$route.query.redirectURI;
      } else {
        this.$router.push(`/dashboard`);
      }
    }
    // check for redirect
    if(this.$route.query.redirectURI != undefined) {
      // URI is automatically decoded, so re-encode to pass on to 2FA page
      this.redirectURI = encodeURIComponent(this.$route.query.redirectURI);
    }
  },
  async mounted() {
    if (this.$route.query.clientId) {
      DataService.updateClientId(this.$route.query.clientId);
    }

    await this.getClientPreferences();
  },
};
</script>

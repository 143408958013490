<template>
  <v-container class="px-0">
    <v-app-bar color="white mb-1">
      <v-toolbar-title class="ml-4">
        <h3>Template Editor</h3>
      </v-toolbar-title>
      <v-spacer />
      <v-btn class="app-bar-btn deleteBtnColor mr-3" @click="goToTemplateManager()">
        Cancel
      </v-btn>
      <v-btn class="app-bar-btn appPrimaryBtnColor" @click="prepareSubmission()">
        Save
      </v-btn>
    </v-app-bar>
    <v-row>
      <v-col>
        <v-card class="mx-auto" tile elevation="0">
          <v-row class="d-flex justify-center">
            <div id="editor-container" style="min-height: 90vh; flex: auto" />
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="displayDialog" persistent width="290" max-height="290">
      <v-card class="pt-4">
        <v-card-text>Template succesfully updated</v-card-text>
        <v-card-actions>
          <v-btn outlined color="green darken-1" text @click="goToTemplateManager()">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DataService from '../../../_services/DataService';
import { EmailEditor } from 'vue-email-editor';
import Utils from '../../utils.js';
import ValidateService from '../../../_services/ValidateService';

export default {
  name: 'EmailTemplateEditor',
  components: {
    EmailEditor,
  },
  data() {
    return {
      pageName: 'EmailTemplateEditor',
      displayMode: 'web',
      genericTemplate: {},
      displayDialog: false,
      loadingEditor: true,
      editor: null,
      html: '',
      json: '',
      exportHTML: '',
      exportJSON: '',
      id: '',
      returnId: '',
      subject: '',
      title: '',
      rules: ValidateService.validators,
      setAlert: Utils.setAlert,
    };
  },
  methods: {
    async getBodyFromId(id) {
      // if template cleared or no id present display sample template
      if (typeof id !== 'number') {
        this.json = { ...this.genericTemplate };
        return;
      }

      let bodyResponse;
      if (this.$route.query.type === 'form') {
        bodyResponse = await DataService.getFormById(id);
      } else {
        bodyResponse = await DataService.getTemplateById(id);
      }

      if (bodyResponse.status == 200) {
        const { json } = bodyResponse.data;
        if (json) {
          if (json instanceof Object) {
            this.json = json;
          } else {
            this.json = JSON.parse(json);
          }
        }

        if (!Object.keys(this.json).length || typeof this.json === 'string') {
          this.json = { ...this.genericTemplate };
        }

        if (!this.loadingEditor) {
          this.setEditorContent();
        }
      }
    },
    goToTemplateManager() {
      if (this.$route.query.type === 'form') {
        this.$router.push({
          name: 'Admin',
          query: { tab: 'form management', id: this.$route.query.id },
        });
      } else {
        this.$router.push({
          name: 'TemplateManager',
          query: {
            id: this.$route.query.id,
          },
        });
      }
    },
    setEditorContent() {
      this.editor.loadDesign(this.json);
    },
    async prepareSubmission() {
      this.exportHTML = null;
      this.exportJSON = null;
      
      this.editor.exportHtml((html) => {
        this.exportHTML = html;
        if (this.exportJSON) {
          this.save();
        }
      });

      this.editor.saveDesign((json) => {
        this.exportJSON = json;
        if (this.exportHTML) {
          this.save();
        }
      });
    },
    async save() {
      const params = {
        id: this.id,
        body: this.exportHTML.html,
        json: this.exportJSON,
      };
      await this.updateTemplateBody(params);
    },
    async updateTemplateBody(params) {
      if (this.$route.query.type === 'form') {
        const response = await DataService.updateForm(this.id, params);
        if (response.status === 200) {
          this.displayDialog = true;
          return;
        }
      } else {
        const response = await DataService.updateTemplate(params);
        if (response.status === 200) {
          this.displayDialog = true;
          return;
        }
      }

      this.$root.$emit('raise_alert', this.setAlert('fail'));
    },
    async loadGenericJson() {
      let response;
      if (this.$route.query.type === 'form') {
        response = await DataService.getGenericForm();
      } else {
        response = await DataService.getGenericTemplate();
      }

      if (response.status === 200) {
        this.genericTemplate = response.data;
      }
    },
    initializeEditorElement() {
      this.editor = global.unlayer.createEditor({
        id: 'editor-container',
        projectId: 162345,
        displayMode: this.displayMode,
      });

      this.editor.addEventListener('editor:ready', () => {
        this.loadingEditor = false;
        if (this.json) {
          this.setEditorContent();
        }
      });
    },
    initializeScriptElement() {
      const scriptTag = document.createElement('script');
      scriptTag.setAttribute('src', '//editor.unlayer.com/embed.js');
      scriptTag.onload = () => {
        this.initializeEditorElement();
      };
      document.head.appendChild(scriptTag);
    },
  },
  async mounted() {
    if (!global.unlayer) {
      this.initializeScriptElement();
    } else {
      this.initializeEditorElement();
    }

    await this.loadGenericJson();

    this.id = Number(this.$route.query.id);
    await this.getBodyFromId(this.id);
  },
};
</script>
<style>
.app-bar-btn {
  color: #ffffff !important;
  font-weight: 700
}
</style>
<template>
  <v-container class="pa-0">
    <!-- Dental Title -->
    <v-row>
      <v-col class="d-flex justify-center">
        <h2 class="secondary--text">Dental</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="secondary white--text d-flex justify-center">
        {{ patient.lastName }}, {{ patient.firstName }} ({{ patient.birthDate }})
      </v-col>
    </v-row>

    <v-form ref="form">
      <v-row>
        <v-col cols="12">
          <v-card>
          <!-- Patient Information Card title -->
            <v-card-title class="px-0">
              Dental Form
            </v-card-title>
            <v-row>

              <v-col cols="12" md="12" lg="6" class="py-0">
                <v-layout class="my-3">Reason for your visit today</v-layout>
                <!-- First name required text-field -->
                <v-text-field v-model="form.reasonForVisit" outlined dense></v-text-field>
              </v-col>

              <v-col cols="12" md="12" lg="6" class="py-0">
                <v-layout class="my-3">Are you in pain?</v-layout>
                <!-- First name required text-field -->
                <v-text-field v-model="form.areYouInPain" outlined dense></v-text-field>
              </v-col>

              <v-col cols="12" md="12" lg="6" class="py-0">
                <v-layout class="my-3">Date of last cleaning and exam</v-layout>
                <!-- First name required text-field -->
                <v-text-field v-model="form.lastCleaningExamDate" type="date" outlined dense></v-text-field>
              </v-col>
              
            </v-row>
          </v-card>
          <v-card>
          <!-- Patient Information Card title -->
            <v-card-title class="px-0">
              Do you have any of the following?
            </v-card-title>
            <v-row>

              <v-col cols="12" md="12">
                <v-card>
                  <v-row>
                    <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0" v-for="item in dentalHabits" :key="item.key">
                      <v-checkbox 
                        v-model="item.selected"
                        class="ma-0"
                        :label="item.label"
                        :value="item.id">
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              
            </v-row>
          </v-card>

          <v-card>
          <!-- Patient Information Card title -->
            <v-card-title class="px-0">
              Previous Dental History?
            </v-card-title>
            <v-row>

              <v-col cols="12" md="12">
                <v-card>
                  <v-row>
                    <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0" v-for="item in dentalHistory" :key="item.key">
                      <v-checkbox 
                        v-model="item.selected"
                        class="ma-0"
                        :label="item.label"
                        :value="item.id">
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              
            </v-row>
          </v-card>

        </v-col>
      </v-row>
    </v-form>

    <Disclaimer ref="disclaimer"/>
  </v-container>
</template>

<script>
import DataService from '../../../_services/DataService';
import Disclaimer from './Disclaimer.vue';
import ValidateService from '../../../_services/ValidateService';
import { jsPDF } from "jspdf";

export default {
  name: 'DentalForm',
  props: ['patient'],
  components: {
    Disclaimer,
  },
  data() {
    return {
      dentalHabits: [],
      dentalHistory: [],
      form: {
        id: 4,
        lastName: '',
        firstName: '',
        dateOfBirth: '',
        reasonForVisit: '',
        areYouInPain: '',
        lastCleaningExamDate: '',
      },
      relationshipToPatientItems: ['Self', 'Spouse', 'Child'],
      rules: ValidateService.validators,
    };
  },
  methods: {
    async validate() {
      return this.$refs.disclaimer.validate();
    },
    async generatePDF() {
      let y = 20;
      const doc = new jsPDF();
      doc.setFontSize(10).setFont(undefined, 'bold').text(10, y, `Dental`).setFont(undefined, 'normal');
      doc.text(10, y+=5, `Reason for your visit today: ${this.form.reasonForVisit || ''}`);
      doc.text(10, y+=5, `Are you in pain?: ${this.form.areYouInPain || ''}`);
      doc.text(10, y+=5, `Date of last cleaning and exam: ${this.form.lastCleaningExamDate || ''}`);
      doc.setFont(undefined, 'bold').text(10, y+=5, `Dental Habits`).setFont(undefined, 'normal');

      y = this.generateLinesForSection(doc, y, 'Habits', this.dentalHabits);
      y = this.generateLinesForSection(doc, y, 'Dental History', this.dentalHistory);

      doc.addPage();
      y = 20;

      doc.text(10, y+=5, `Relationship to Patient: ${this.$refs.disclaimer.relationshipToPatient}`);

      if (this.$refs.disclaimer.relationshipToPatient !== 'Self') {
        doc.text(10, y+=5, `Name if not Patient: ${this.$refs.disclaimer.nameIfNotPatient}`);
      }

      doc.text(10, y+=5, `Date Signed: ${new Date().toISOString()}`);

      doc.addImage(this.$refs.disclaimer.$refs.signaturePad._data.signatureData, 'JPEG', 10, y+=10, 100, 70, undefined, 'FAST');

      await this.sendForm(doc.output('datauristring').split(',')[1]);
    },
    async sendForm(base64PDF) {
      this.form.selectedHabits = this.getSelectedNames(this.dentalHabits);
      this.form.selectedHistory = this.getSelectedNames(this.dentalHistory);

      this.form.base64PDF = base64PDF;

      const response = await DataService.postNewPatRegistration(this.form, this.patient.id);
      if (response.status === 200) {
        this.$emit('goToNextForm');
        this.$root.$emit('raise_alert', { color: 'success', text: 'Your entry has been recorded.' });
      } else {
        this.$emit('submitFailure');
        this.$root.$emit('raise_alert', { color: 'error', text: 'We were unable to save your dental info, please try again or contact our team if the issue persists' });
      }
    },
    generateLinesForSection(doc, yStart, sectionName, sectionItems) {
      let updatedY = yStart;

      if (updatedY > 285) {
        doc.addPage();
        updatedY = 20;
      }

      doc.setFont(undefined, 'bold').text(10, updatedY += 5, sectionName).setFont(undefined, 'normal')

      for (const item of sectionItems) {
        if (updatedY > 285) {
          doc.addPage();
          updatedY = 20;
        }
        
        doc.text(10, updatedY += 5, `- ${item.label}: ${item.selected ? 'Yes' : 'No'}`)
      }

      return updatedY;
    },
    getSelectedNames(array) {
      return array.filter(item => item.selected).map(item => item.label);
    },
    async getData() {
      const response = await DataService.getClientDiseaseCategories();
      const habits = response.data.find((cat) => cat.category === 'Habits');
      const history = response.data.find((cat) => cat.category === 'History');
      let habitIds = habits.openDentalDiseaseIds;
      let historyIds = history.openDentalDiseaseIds;

      // get all questions
      let questions = await DataService.getDiseaseDefinitions().then(res => {
        return res.data.map(item => ({id: item.DiseaseDefNum, label: item.DiseaseName, selected: false}))
      })

      // place items in correct categories
      this.dentalHabits = questions.filter(item => habitIds.includes(item.id));
      this.dentalHistory = questions.filter(item => historyIds.includes(item.id));
    },
  },
  async mounted() {
    await this.getData();
  },
};
</script>
<style scoped>
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 1;
  color: #1182d5;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  background-color: white;
}
.v-sheet.v-list:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%),
    0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
}
</style>

import axios from 'axios';

const normalizedPort = !process.env.VUE_APP_API_PORT || process.env.VUE_APP_API_PORT === 80 || process.env.VUE_APP_API_PORT === 443 ? '' : `:${process.env.VUE_APP_API_PORT}`;
const apiUrl = `https://${process.env.VUE_APP_API_NAME}${normalizedPort}/api`;

window.acrew = window.acrew || {};
window.acrew.apiUrl = apiUrl;

export default axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-type': 'application/json',
    'x-access-token': localStorage.getItem('token'),
  },
  validateStatus: () => true, // 404/500 shouldn't throw
});

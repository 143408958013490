<template>
  <v-layout row wrap class="no-toppad" no-gutters text-xs-right>
    <v-container class="">
      
      <v-row noGutters>
        <v-col cols="12" md="8" sm="4">
          <h1 class="dashtitle">
            <span>{{ this.pageName }}</span>
          </h1>
        </v-col>
      </v-row>

      <v-row class="px-4">
        <PageTabs :tabs="tabs" :startTab="tabFromQuery" />
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
import MessageLog from '../MessageLog.vue';
import PageTabs from '../../components/PageTabs.vue';
import CreateEditMessage from './CreateEditMessage.vue';

export default {
  name: 'Messaging',
  components: {
    CreateEditMessage,
    MessageLog,
    PageTabs,
  },
  data() {
    return {
      pageName: 'Messaging',
      pageIcon: 'mdi-message-text',
      tabFromQuery: null,
      tabs: [
        {
          label: 'Manage Templates',
          section: CreateEditMessage,
          access: 'admin',
        },
        {label: 'Message Logs', section: MessageLog, access: 'admin'},
      ],
      form: {},
    };
  },
  mounted() {
    if (this.$route.query.tab) {
      const lowerRoute = this.$route.query.tab.toLowerCase();
      const tabIndex = this.tabs.findIndex((tab) => tab.label.toLowerCase() === lowerRoute);
      if (tabIndex !== -1) {
        this.tabFromQuery = tabIndex;
      }
    }
  },
};
</script>
<style></style>

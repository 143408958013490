<template>
  <v-layout row wrap class="no-toppad" no-gutters text-xs-right>
    <v-container class="">
      <v-row noGutters>
        <v-col>
          <h1 class="dashtitle">
            <v-icon>{{ this.pageIcon }}</v-icon>
            <span>{{ this.pageName }}</span>
          </h1>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="mt-1 text-right"> </v-col>
      </v-row>
      <v-row noGutters>
        <EmailList
          :headers="headers"
          :defOrderkeyDefault="defOrderkeyDefault"
          :defOrderkey="defOrderkey"
          :rowClick="true"
          ref="emailList">
        </EmailList>
      </v-row>
      <!-- <v-data-table
        :headers="headers"
        :items="this.emails" 
        :defOrderkeyDefault="defOrderkeyDefault"
        :defOrderkey="defOrderkey">
        <template v-slot:item="{ item }">
            <tr :class="item.read == false ?'secondary':''" @click="openDetails(item.id)" >
                <td style=" font-weight:bold">{{item.from}}</td>
                <td>{{item.message}}</td>
                <td>{{item.received}}</td>
            </tr>
        </template>
      </v-data-table> -->
    </v-container>
  </v-layout>
</template>
<script>
import DataService from '../../../_services/DataService';
import EmailList from '../../components/DataDisplay/DataTable.vue';
import EmailBox from '../../components/DialogBox.vue';
import Utils from '../../../src/utils.js';
const {jsPDF} = require('jspdf');

export default {
  name: 'Email',
  components: {
    EmailList,
    EmailBox,
  },
  data() {
    return {
      pageName: 'Email',
      pageIcon: 'mdi-email',
      exportFileName: 'Email',
      dialog_btn_text: 'Compose Email',
      exportPDFCols: ['From', 'Subject', 'Sent'],
      search: '',
      loading: true,
      email_id: '',
      emails: [],
      dialogEditColumns: false,
      dialogCreate: false,
      dialogEdit: false,
      menu: false,
      pdf: [],
      csv: [],
      defOrderkeyDefault: 'dateSent',
      defOrderkey: ['dateSent'],
      orderkeyDir: true,
      orderkeyVal: 'DESC',
      headers: [
        {
          text: 'To',
          align: 'start',
          sortable: true,
          value: 'to',
          active: true,
        },
        {
          text: 'Subject',
          value: 'subject',
          sortable: true,
          active: true,
        },
        {
          text: 'Date/Time',
          value: 'dateSent',
          sortable: true,
          active: true,
        },
      ],
    };
  },
  computed: {
    activeCols: function () {
      return this.headers.filter(function (u) {
        return u.active == 1;
      });
    },
  },
  watch: {},
  methods: {
    setActiveCols: function (d, item) {
      let n = 1;
      if (item.active == 1) n = 0;
      this.headers[parseInt(d)].active = parseInt(n);
    },
    async getData(params) {
      this.loading = true;
      await DataService.getEmails(params)
        .then((response) => {
          const {rows, count} = response.data;
          this.$refs.emailList.rows = rows.map(this.getDisplayData);
          this.emails = rows.map(this.getDisplayData);
          this.$refs.emailList.totalPages = Math.ceil(
            count / this.$refs.emailList.pageSize
          );
          setTimeout(() => (this.loading = false), 100);
          return rows;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDisplayData(d) {
      return {
        id: d.id,
        from: d.from,
        to: d.to,
        subject: d.subject,
        dateSent: d.dateSent,
      };
    },
    openEditColumns() {
      this.dialogEditColumns = true;
    },
    test_info() {
      let alert = {
        color: 'info',
        text: 'This is a test of the info alert',
      };
      this.raise_alert(alert);
    },
    raise_alert(alert) {
      this.$root.$emit('raise_alert', alert);
    },
  },
  created() {},
  mounted() {
    // if (localStorage.getItem('token')) {}
  },
};
</script>
<style></style>

<template>
  <v-row>
    <v-col>
    <v-dialog
      v-model="dialog"
      width="80%"
      max-height="80%"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          class="ma-1"
          elevation="0"          
        >
          {{ dialog_open }}
        </v-btn>
      </template>
      <v-card outlined>
        <v-col class="text-right px-4 pb-0">
          <v-btn @click="dialog=false" icon><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
        <v-card-text>
          <!-- <CreateEditMessage /> -->
          <component :is="dialog_component" @closeDialog="closeDialog" />
        </v-card-text>
          <v-card-actions v-if="buttons_on">
            <v-spacer></v-spacer>
            <span v-for="(btn, i) in dialog_buttons" :key="i">
              <v-btn
                color="green darken-1"
                text
                @click="btn.action"
              >
                {{ btn.text }}
              </v-btn>
            </span>
          </v-card-actions>
      </v-card>
    </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
// import CreateEditMessage from '../pages/Messaging/CreateEditMessage.vue'
  export default {
    props: [
      'dialog_component',
      'dialog_props',
      'dialog_open',
      'buttons_on',
      'dialog_buttons',
    ],
    data() {
      return {
        dialog: false,
        
      };
    },
    components: { 
      // CreateEditMessage 
    },
    methods: {
      closeDialog() {
        this.dialog = false;
      }
    },
    mounted() {
  }
}
</script>
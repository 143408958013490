<template>
  <v-app>
    <Alerts :alertArray="alertArray"></Alerts>
    <v-main>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
  </v-app>
</template>
<script>
import Alerts from '../components/Alerts.vue';
export default {
  components: {
    Alerts,
  },
  data() {
    return {
      alertArray: [],
    };
  },
  setup() {},
  created() {},
  mounted() {
    document.title = 'Acrew';
    this.$root.$on('raise_alert', this.raise_alert);
    this.$root.$on('dismiss_alert', this.dismiss_alert);
  },
  destroy() {
    clearInterval(this.timerId);
  },
  computed: {},
  watch: {},
  methods: {
    raise_alert(alert) {
      this.alertArray = [];
      this.alertArray.push(alert);
      setTimeout(() => (this.alertArray = []), 1000 * 3);
    },
    dismiss_alert() {
      this.alert = false;
    },
  },
};
</script>
<style>
h1,
h2,
.v-card__title > span > h3,
.dashtitle > span,
.dashtitle > .v-icon {
  color: #414a5b;
}

/* This will remove the box-shodow/drop-shadow from everything in an app */
body * {
  box-shadow: none !important;
}

.navcolor {
  border-color: #68b4c9;
}

.v-dialog {
  background-color: #ffffff !important;
}

.v-dialog > .v-card > .v-card__text {
  padding: 0 24px 1px 24px !important;
}

.v-input.dg-page-size {
  font-size: 14px !important;
}

.v-list-item__title {
  align-self: center;
  font-size: 0.9rem !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #202020 !important;
  font-size: 0.85em;
}

html {
  font-size: 14px !important;
  text-rendering: opti mizeLegibility;
}

h1.dashtitle {
  color: #202020 !important;
  font-size: 1.75em;
}

.v-data-table-header tr {
  background-color: #eaf7fa !important;
  color: #202020 !important;
}

.theme--light.v-data-table .v-data-table-header th.sortable,
.theme--light.v-data-table .v-data-table-header th.sortable.active {
  color: #202020 !important;
}

.theme--light.v-data-table .v-data-table-header th.sortable:hover,
.theme--light.v-data-table .v-data-table-header th.sortable.active {
  color: #202020 !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable.active
  .v-data-table-header__icon {
  color: #202020 !important;
}

.v-navigation-drawer
  .v-list-item--active.v-list-item.v-list-item--link.theme--dark {
  color: #ffffff !important;
  caret-color: #ffffff !important;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 24px !important;
}

.v-card__title.headline.primary {
  color: #ffffff !important;
}

.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 64px !important;
}

.v-navigation-drawer__content
  > .v-list-group__items
  > .v-list-item
  > .v-list-item__title {
  color: #e0b993 !important;
}

.v-list-item__icon.v-list-group__header__append-icon {
  color: #181818 !important;
}

.v-list-item.v-list-group__header {
  padding-left: 0;
}

.v-navigation-drawer__content > .v-list-item__title {
  color: #ffffff;
}

.container {
  width: 100%;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;
}

.theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.nobr {
  white-space: nowrap;
}

.v-btn.search-toolbuttons.active {
  background: #39b54a;
  background-color: #39b54a !important;
  border-color: #39b54a;
  color: #ffffff;
}

.v-btn.search-toolbuttons {
  margin-left: 5px;
  background-color: #ffffff;
  border-color: #006fba;
  border-width: 1px;
  color: #006fba;
  margin-bottom: 5px;
}

.v-input {
  color: #ffffff;
}

.v-dialog {
  color: #ffffff;
}

.v-pagination {
  display: inline-flex;
  list-style-type: none;
  justify-content: right !important;
  margin: 0;
  max-width: 100%;
  width: 100%;
}

.nav-sublink .v-list-group__items .v-list-item__title {
  color: #d2d2d2 !important;
}

.theme--light.v-pagination .v-pagination__item--active {
  color: #ffffff !important;
}

@media (min-width: 960px) {
  .container {
    max-width: 1100px !important;
  }
}

@media (min-width: 1264px) {
  .container {
    max-width: none !important;
  }
}

@media (min-width: 1904px) {
  .container {
    max-width: none !important;
  }
}

.topbar-text {
  font-size: 1rem;
  padding: 0;
}

.tiny {
  font-size: 0.7rem;
}

.v-toolbar__content {
  max-width: none !important;
}

.container .nopadd {
  padding: 0 0;
  padding-left: 1rem;
}

footer.theme--light.v-footer {
  background-color: #1d2c39;
  color: #ffffff;
}

div.no-gutters .footerlinks-wrapper {
  padding-top: 0.7rem;
}

.v-application a.footerlinks {
  color: #ffffff;
  text-decoration: underline;
  font-size: 0.75rem;
}

.v-application a.footerlinks:hover {
  color: #ffffff;
}

.v-btn--contained::after {
  box-shadow: 0;
}

@media (max-width: 960px) {
  .topbar-text {
    display: none;
  }

  .topbar-search {
    display: none;
  }
}
</style>

<template>
  <v-card outlined>
    <v-row class="px-1">
      <v-col cols="7">
        <VueCronEditorBuefy
          v-model="form.cronSchedule"
          :visibleTabs="['hourly', 'daily', 'weekly', 'monthly']"
          class="mx-1" />
      </v-col>
      <v-col cols="5" lg="4" class="pl-0 pb-0 mt-10">
        <span style="color: #a0a0a0">Timezone: {{ timezoneDisplayText }}</span>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>

import VueCronEditorBuefy from '../../../plugins/vue-cron-editor-buefy';
import DataService from '../../../../_services/DataService';

export default {
  name: 'MessageScheduling',
  props: ['pageData'],
  components: {
    VueCronEditorBuefy,
  },
  data() {
    return {
      timezoneDisplayText: '',
      defaultCron: '0 12 * * 1',
      form: {
        cronSchedule: '',
      },
    };
  },
  watch: {
    ['pageData.parent']: {
      handler() {
        this.setupParentRelationship();
      },
      deep: false,
    },
    ['pageData.parent.selectedTemplate']: {
      handler() {
        this.setScheduleFromTemplate();
      },
      deep: false,
    },
  },
  methods: {
    setupParentRelationship() {
      // eslint-disable-next-line
      this.pageData.parent.scheduleTab = this; // hack
    },
    setScheduleFromTemplate() {
        this.form.cronSchedule = this.pageData.parent.selectedTemplate.cronSchedule || this.defaultCron;
    },
    async loadTimezone() {
      const configResponse = await DataService.getConfigInfoById(DataService.getClientId())
      if (configResponse.status === 200) {
        this.timezoneDisplayText = configResponse.data.timezone;
      }
    },
  },
  async mounted() {
    await this.loadTimezone();

    if (this.pageData?.parent) {
      this.setupParentRelationship();

      if (this.pageData.parent.selectedTemplate) {
        this.setScheduleFromTemplate();
      }
    }
  },
};
</script>
<template>
  <v-container v-if="serviceMessage" class="mr-5 justify-center" width="100%">
    <v-alert variant="tonal" density="comfortable" type="warning">
      {{ serviceMessage }}
    </v-alert>
  </v-container>
</template>
<script>
import DataService from '../../_services/DataService';

export default {
  name: 'ServiceMessage',
  props: ['page'],
  data() {
    return {
      serviceMessage: '',
    };
  },
  methods: {
    async getServiceMessage() {
      const response = await DataService.getServiceMessages();
      if (response?.status === 200 && response?.data?.length) {
        const serviceMessages = response.data;
        this.serviceMessage = serviceMessages.find((el) => el.page === this.page || (!el.page && !this.page))?.message;
      }
    },
  },
  async mounted() {
    await this.getServiceMessage();
  },
};
</script>

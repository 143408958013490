<template>
  <v-container class="pa-0">
    <!-- Dental Title -->
    <v-row>
      <v-col class="d-flex justify-center">
        <h2 class="secondary--text text-center">Patient Notice of Privacy Practices (NPP)</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="secondary white--text d-flex justify-center">
        {{ patient.lastName }}, {{ patient.firstName }} ({{patient.birthDate }})
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12">
        <h1 class="text-center">
          Patient Notice of Privacy Practices(NPP)
        </h1>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <h4 class="font-italic">
          As required by the Privacy Regulations created as a result of the Health Insurance Portability Accountability Act of 1996- (HIPAA) Health Information Technology for Economic and Clinical Health Act (HITECH Act), and associated regulations and amendments. This notice describes how health information about you may be used and disclosed, and how you can get access to this information. 
        </h4>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <h4>
          PLEASE REVIEW THIS NOTICE CAREFULLY
        </h4>
        <p>
        If you have any questions about this notice or if you need more information,

        please contact:
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <h3>
          {{ clientName }}
        </h3>
        <p class="font-italic">
          {{ clientPrivacyContact }}<br />
          {{ clientPhone }} or email at {{ clientEmail }}<br />
          {{ clientAddress1 }} {{ clientAddress2 }}<br />
          {{ clientCity }}, {{ clientState }} {{ clientZip }}
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>ABOUT THIS NOTICE:</b> We understand that health information about you is personal and we are committed to protecting your information. We need this record to provide care (treatment), for payment of care provided and to comply with certain legal requirements. This Notice will tell you about the ways in which we may use and disclose health information about you. It also describes your rights and certain obligations we have regarding the use and disclosure of health information. We are required by law to follow the terms of this Notice that is currently in effect.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>WHAT IS PROTECTED HEALTH INFORMATION (PHI)?</b> "Protected Health Information" or "PHI" for short, is information that identifies you and relates to, your past, present or future physical or mental health or condition, the provision of healthcare to you, or past, present, or future payment for the provision of healthcare to you. PHI does not include information about you that is publicly available, or that is in summary form that does not identify who you are.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>HOW WE MAY USE AND DISCLOSE YOUR PHI</b> We may use and disclose your PHI in the following circumstances: We restrict access of your PHI to those employees who need access to provide services to our patients. We have established and maintain appropriate physical, technical, and administrative safeguards to protect your PHI against unauthorized use or disclosure. We have established a training program that our employees and contracted personnel must complete and update we have established a Privacy and Security Officer which have overall responsibility for developing, training, and overseeing the implementation and enforcement of policies and procedures to safeguard your PHI against inappropriate access, use and disclosure.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>TREATMENT:</b> We may use or disclose your PHI to a dentist, orthodontist, periodontist, physician, or other health care provider providing treatment to you. For example, we may use your PHI to other specialists who may provide care to you.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>PAYMENT:</b> We may use or disclose your PHI to obtain payment for services we provide to you. For example, we may need to give your insurance company PHI about a service you received so your plan will pay us or reimburse you for the service. Healthcare Operations: We may use and disclose your PHI in connection with our healthcare operations.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>HEALTHCARE OPERATIONS</b> include quality assessment and improvement activities, reviewing the competence or qualifications of healthcare professionals, evaluation practitioner and provider performance, conducting training programs, accreditation, certification, licensing, or credentialing activities. For example, we may use your PHI to evaluate the performance of our staff in caring for you.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>BUSINESS ASSOCIATES:</b> We may disclose your PHI to Business Associates independent of the practice with whom contract or arrange to provide services on your behalf. However, we will only make such disclosures if we have received satisfactory assurances that the Business Associates will properly safeguard your privacy and the confidentiality of your PHI. For example, we may contract with a company outside of the practice to provide transcription services for the practice or collection of review so we can improve our care or collection of services for past due accounts. This company would be considered our Business Associate.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>APPOINTMENT REMINDERS:</b> We may use and disclose PHI to contact you to remind you that you have an appointment for dental care. Examples include, voicemail messages, postcards, e-mail, or letters.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>AUTHORIZATION:</b> In addition to our use of PHI for treatment, payment, or healthcare operations, you must give us written authorization to use your PHI or to disclose it to anyone for any purpose. If you give us an authorization, you may revoke it in writing at any time. Your revocation will not affect any use or disclosures permitted by your authorization while it was in effect. Unless you give us a written authorization, we cannot use or disclose your PHI for any reason except those described in this Notice.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>MINORS:</b> We may disclose the PHI of minor children to their parents or guardians unless such disclosure is otherwise prohibited by law.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>MARKETING HEALTH-RELATED SERVICES:</b> We will not use your PHI for marketing communications without your written authorization.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>REQUIRED BY LAW:</b> We may use or disclose your PHI when are required to do so by law and for other purposes permitted by applicable law such as, for public health activities, reports to coroners, medical examiners, or funeral directors, to avert a service threat to the health or safety of you or other members of the public or for law enforcement purposes, all as set forth in the Privacy Rules.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>ABUSE OR NEGLECT:</b> We may disclose your PHI to appropriate authorities if we reasonably believe that you are a possible victim of abuse, neglect, or domestic violence or the possible victim of other crimes.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>NATIONAL SECURITY:</b>  We may disclose to military authorities the PHI of Armed Forces personnel under certain circumstances. We may disclose to authorized federal officials PHI required for lawful intelligence, counterintelligence, and other national security activities. We may disclose to correctional institution or law enforcement official having lawful custody of PHI of inmate or patient under certain circumstances.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>Other Situations Where Disclosure May or May Not Require your Consent:</b> We may also use or disclose your PHI as follows:

(1) for worker's compensation or similar programs providing benefits for work-related injuries or illness as authorized by state laws, or

(2) if you are involved in a lawsuit or dispute, we may disclose PHI about you in response to a court administrative order. Except as may be prohibited by law, we may also disclose PHI about you in response to a subpoena, discovery request or other lawful process by someone else involved in the dispute, but only if efforts have been made to tell you or you attorney about the request or to obtain an order protecting the information requested.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>DATA BREACH NOTIFICATION:</b> We may use or disclose your PHI to provide legally required notices of unauthorized access to or disclosure of your health information.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>INDIVIDUALS INVOLVED IN YOUR CARE:</b> Unless you object in writing, we may disclose to a member of your family, a relative, a close friend or any other person you identify, your PHI that directly relates to that person"s involvement in your dental care. If you are unable to agree or object to such a disclosure, we may disclose such information as necessary if we determine that it is in your best interest based on our professional judgement.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>DISASTER RELIEF:</b> We may disclose your PHI to disaster relief organizations that seek your PHI to coordinate your care, or notify family and friends of your location or condition in a disaster. We will provide you with an opportunity to agree or object to such a disclosure whenever we practicably can do so.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>PATIENT RIGHTS ACCESS:</b> You have the right to inspect, look at, or get copies of your PHI, except as prohibited by law. You may request that we provide copies in a format other than photocopies. We will use the format you request unless we cannot practicably do so. You must make a request in writing to obtain access to your PHI. You may obtain a form to request access by using the contact information listed on the end of this Notice. You may also request access during normal business hours by sending us a letter to the address at the end of this Notice. We reserve the right to charge a reasonable administrative fee for copying your PHI. We may deny your request to inspect and copy in certain circumstances. If we deny your request for review or copy of your PHI, we will explain the reasoning in writing.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>AMENDMENT:</b> You have the right to request that we amend your PHI. (Your request must be in writing, and it must explain why the information should be amended). We may deny your request under certain circumstances, including the failure to make such a request in writing or a writing that does not support the request. We will respond to you in writing, either accepting or denying your request. If we deny your request, we will explain why.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>ELECTRONIC NOTICE:</b>  If you receive this Notice on our Website or by electronic (e-mail), you are entitled to receive this Notice in written form.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>QUESTIONS AND COMPLAINTS:</b> If you want more information about our privacy practices or have questions or concerns, please contact us. If you are concerned that we may have violated your privacy rights, or you disagree with a decision we made about access to your PHI or in a response to a request you made to amend or restrict the use or disclosure of your PHI or to have us communicate with you by alternative means at alternative location, you may complain to us using the contact information listed at the beginning and end of this Notice.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b class="font-italic">{{ clientName.toUpperCase() }} ACKNOWLEDGEMENT OF RECEIPT OF PATIENT NOTICE OF PRIVACY PRACTICES</b>
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>{{ clientName.toUpperCase() }}<br />

ACKNOWLEDGEMENT OF RECEIPT OF<br />

PATIENT NOTICE OF PRIVACY PRACTICES</b>
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>1) By signing this form:</b> I acknowledge that I read and/or received a copy of (name of practice)'s Patient Notice of Privacy Practices effective (selected date). 
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b>2) In addition, by signing this form, I further understand:</b><br />
          • that the laws that protect privacy and the confidentiality of medical information also apply to tele-dental, and that no information obtained in the use of tele-dentistry which identifies me will be disclosed to other entities.
          <br />
          • that I have the right to withhold or withdraw my consent to the use of tele-dentistry in the course of my care at any time, without affecting my right to future care or treatment.
          <br />
          • that I have the choice of dental care available to me and that I may request to choose an alternative method of care (pending availability of other healthcare providers).
          <br />
          • that it is my duty to the extent that I am able to inform my healthcare provider of any change in my mental and/or physical health.
          <br />
          • that I may expect the anticipated benefits from dental care, but that no results can be guaranteed or assured.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <p>
          <b class="font-italic">Patient Receipt of Notice of Privacy Practices and Patient Consent to the Use of Teledentistry:</b> Please indicate your understanding of and informed consent to these terms, which will be in effect until the government rescinds its suspension of these HIPAA requirements, and to consent to the use of teledentistry during my diagnosis and treatment.
        </p>
      </v-col>
    </v-row>

    <Disclaimer
      v-if="!formsDisabled"
      ref="disclaimer"
      agreement-text="true" />
  </v-container>
</template>

<script>
import DataService from '../../../_services/DataService';
import Disclaimer from './Disclaimer.vue';
import { jsPDF } from "jspdf";

export default {
  name: 'PrivacyPracticesForm',
  props: ['patient', 'formsDisabled'],
  components: {
    Disclaimer,
  },
  data() {
    return {
      clientName: '',
      clientPrivacyContact: '',
      clientPhone: '',
      clientEmail: '',
      clientAddress1: '',
      clientAddress2: '',
      clientCity: '',
      clientState: '',
      clientZip: '',
      form: {
        id: 6,
      }
    };
  },
  methods: {
    async validate() {
      return this.$refs.disclaimer.validate();
    },
    async generatePDF() {
      let y = 20;
      const doc = new jsPDF('p', 'pt', 'a4');
      doc.setFont(undefined, 'bold').text(10, y, `Privacy Practices Form`).setFont(undefined, 'normal').setFontSize(10);
      doc.text(10, y+=15, ` As required by the Privacy Regulations created as a result of the Health Insurance Portability Accountability Act of 1996- (HIPAA) Health Information Technology for Economic and Clinical Health Act (HITECH Act), and associated regulations and amendments. This notice describes how health information about you may be used and disclosed, and how you can get access to this information.
PLEASE REVIEW THIS NOTICE CAREFULLY

If you have any questions about this notice or if you need more information, please contact:
${this.clientName}

${this.clientPrivacyContact}
${this.clientPhone} or email at ${this.clientEmail}
${this.clientAddress1} ${this.clientAddress2}
${this.clientCity}, ${this.clientState} ${this.clientZip}

ABOUT THIS NOTICE: We understand that health information about you is personal and we are committed to protecting your information. We need this record to provide care (treatment), for payment of care provided and to comply with certain legal requirements. This Notice will tell you about the ways in which we may use and disclose health information about you. It also describes your rights and certain obligations we have regarding the use and disclosure of health information. We are required by law to follow the terms of this Notice that is currently in effect.

WHAT IS PROTECTED HEALTH INFORMATION (PHI)? "Protected Health Information" or "PHI" for short, is information that identifies you and relates to, your past, present or future physical or mental health or condition, the provision of healthcare to you, or past, present, or future payment for the provision of healthcare to you. PHI does not include information about you that is publicly available, or that is in summary form that does not identify who you are.

HOW WE MAY USE AND DISCLOSE YOUR PHI We may use and disclose your PHI in the following circumstances: We restrict access of your PHI to those employees who need access to provide services to our patients. We have established and maintain appropriate physical, technical, and administrative safeguards to protect your PHI against unauthorized use or disclosure. We have established a training program that our employees and contracted personnel must complete and update we have established a Privacy and Security Officer which have overall responsibility for developing, training, and overseeing the implementation and enforcement of policies and procedures to safeguard your PHI against inappropriate access, use and disclosure.

TREATMENT: We may use or disclose your PHI to a dentist, orthodontist, periodontist, physician, or other health care provider providing treatment to you. For example, we may use your PHI to other specialists who may provide care to you.

PAYMENT: We may use or disclose your PHI to obtain payment for services we provide to you. For example, we may need to give your insurance company PHI about a service you received so your plan will pay us or reimburse you for the service. Healthcare Operations: We may use and disclose your PHI in connection with our healthcare operations.

HEALTHCARE OPERATIONS include quality assessment and improvement activities, reviewing the competence or qualifications of healthcare professionals, evaluation practitioner and provider performance, conducting training programs, accreditation, certification, licensing, or credentialing activities. For example, we may use your PHI to evaluate the performance of our staff in caring for you.

BUSINESS ASSOCIATES: We may disclose your PHI to Business Associates independent of the practice with whom contract or arrange to provide services on your behalf. However, we will only make such disclosures if we have received satisfactory assurances that the Business Associates will properly safeguard your privacy and the confidentiality of your PHI. For example, we may contract with a company outside of the practice to provide transcription services for the practice or collection of review so we can improve our care or collection of services for past due accounts. This company would be considered our Business Associate.

APPOINTMENT REMINDERS: We may use and disclose PHI to contact you to remind you that you have an appointment for dental care. Examples include, voicemail messages, postcards, e-mail, or letters.

AUTHORIZATION: In addition to our use of PHI for treatment, payment, or healthcare operations, you must give us written authorization to use your PHI or to disclose it to anyone for any purpose. If you give us an authorization, you may revoke it in writing at any time. Your revocation will not affect any use or disclosures permitted by your authorization while it was in effect. Unless you give us a written authorization, we cannot use or disclose your PHI for any reason except those described in this Notice.

MINORS: We may disclose the PHI of minor children to their parents or guardians unless such disclosure is otherwise prohibited by law.`, { maxWidth: 550 });

doc.addPage();
doc.text(10, y, `MARKETING HEALTH-RELATED SERVICES: We will not use your PHI for marketing communications without your written authorization.

REQUIRED BY LAW: We may use or disclose your PHI when are required to do so by law and for other purposes permitted by applicable law such as, for public health activities, reports to coroners, medical examiners, or funeral directors, to avert a service threat to the health or safety of you or other members of the public or for law enforcement purposes, all as set forth in the Privacy Rules.

ABUSE OR NEGLECT: We may disclose your PHI to appropriate authorities if we reasonably believe that you are a possible victim of abuse, neglect, or domestic violence or the possible victim of other crimes.

NATIONAL SECURITY: We may disclose to military authorities the PHI of Armed Forces personnel under certain circumstances. We may disclose to authorized federal officials PHI required for lawful intelligence, counterintelligence, and other national security activities. We may disclose to correctional institution or law enforcement official having lawful custody of PHI of inmate or patient under certain circumstances.

Other Situations Where Disclosure May or May Not Require your Consent: We may also use or disclose your PHI as follows: (1) for worker's compensation or similar programs providing benefits for work-related injuries or illness as authorized by state laws, or (2) if you are involved in a lawsuit or dispute, we may disclose PHI about you in response to a court administrative order. Except as may be prohibited by law, we may also disclose PHI about you in response to a subpoena, discovery request or other lawful process by someone else involved in the dispute, but only if efforts have been made to tell you or you attorney about the request or to obtain an order protecting the information requested.

DATA BREACH NOTIFICATION: We may use or disclose your PHI to provide legally required notices of unauthorized access to or disclosure of your health information.

INDIVIDUALS INVOLVED IN YOUR CARE: Unless you object in writing, we may disclose to a member of your family, a relative, a close friend or any other person you identify, your PHI that directly relates to that person"s involvement in your dental care. If you are unable to agree or object to such a disclosure, we may disclose such information as necessary if we determine that it is in your best interest based on our professional judgement.

DISASTER RELIEF: We may disclose your PHI to disaster relief organizations that seek your PHI to coordinate your care, or notify family and friends of your location or condition in a disaster. We will provide you with an opportunity to agree or object to such a disclosure whenever we practicably can do so.

PATIENT RIGHTS ACCESS: You have the right to inspect, look at, or get copies of your PHI, except as prohibited by law. You may request that we provide copies in a format other than photocopies. We will use the format you request unless we cannot practicably do so. You must make a request in writing to obtain access to your PHI. You may obtain a form to request access by using the contact information listed on the end of this Notice. You may also request access during normal business hours by sending us a letter to the address at the end of this Notice. We reserve the right to charge a reasonable administrative fee for copying your PHI. We may deny your request to inspect and copy in certain circumstances. If we deny your request for review or copy of your PHI, we will explain the reasoning in writing.

AMENDMENT: You have the right to request that we amend your PHI. (Your request must be in writing, and it must explain why the information should be amended). We may deny your request under certain circumstances, including the failure to make such a request in writing or a writing that does not support the request. We will respond to you in writing, either accepting or denying your request. If we deny your request, we will explain why.

ELECTRONIC NOTICE: If you receive this Notice on our Website or by electronic (e-mail), you are entitled to receive this Notice in written form.

QUESTIONS AND COMPLAINTS: If you want more information about our privacy practices or have questions or concerns, please contact us. If you are concerned that we may have violated your privacy rights, or you disagree with a decision we made about access to your PHI or in a response to a request you made to amend or restrict the use or disclosure of your PHI or to have us communicate with you by alternative means at alternative location, you may complain to us using the contact information listed at the beginning and end of this Notice.

${this.clientName.toUpperCase()} ACKNOWLEDGEMENT OF RECEIPT OF PATIENT NOTICE OF PRIVACY PRACTICES`, {maxWidth: 550});

doc.addPage();
doc.text(10, y, `${this.clientName.toUpperCase()}
ACKNOWLEDGEMENT OF RECEIPT OF
PATIENT NOTICE OF PRIVACY PRACTICES

1) By signing this form: I acknowledge that I read and/or received a copy of (name of practice)'s Patient Notice of Privacy Practices effective (selected date).

2) In addition, by signing this form, I further understand:
• that the laws that protect privacy and the confidentiality of medical information also apply to tele-dental, and that no information obtained in the use of tele-dentistry which identifies me will be disclosed to other entities.
• that I have the right to withhold or withdraw my consent to the use of tele-dentistry in the course of my care at any time, without affecting my right to future care or treatment.
• that I have the choice of dental care available to me and that I may request to choose an alternative method of care (pending availability of other healthcare providers).
• that it is my duty to the extent that I am able to inform my healthcare provider of any change in my mental and/or physical health.
• that I may expect the anticipated benefits from dental care, but that no results can be guaranteed or assured.

Patient Receipt of Notice of Privacy Practices and Patient Consent to the Use of Teledentistry: Please indicate your understanding of and informed consent to these terms, which will be in effect until the government rescinds its suspension of these HIPAA requirements, and to consent to the use of teledentistry during my diagnosis and treatment. `, {maxWidth: 550 });

      doc.addImage(this.$refs.disclaimer.$refs.signaturePad._data.signatureData, 'JPEG', 10, 300, 200, 150, undefined, 'FAST');
      doc.text(10, 310, `Date Signed: ${new Date().toISOString()}`);

      await this.sendForm(doc.output('datauristring').split(',')[1])
    },
    async sendForm(base64PDF) {
      // add pdf of page to form
      this.form.base64PDF = base64PDF;

      const response = await DataService.postNewPatRegistration(this.form, this.patient.id);
      if (response.status === 200) {
        this.$root.$emit('raise_alert', { color: 'success', text: 'Your entry has been recorded.' });
        this.$root.$emit('raise_alert', { color: 'success', text: 'Patient registration is complete. Thank you!' });

        setTimeout(() => { this.$router.push('dashboard'); }, 2000);
      } else {
        this.$emit('submitFailure');
        this.$root.$emit('raise_alert', { color: 'error', text: 'We were unable to save your confirmation, please try again or contact our team if the issue persists' });
      }
    },
  },
  async mounted() {
    const { name, privacyContact, phone, email, address1, address2, city, state, zip } = await DataService.getClientDetails();
    this.clientName = name;
    this.clientPrivacyContact = privacyContact;
    this.clientPhone = phone;
    this.clientEmail = email;
    this.clientAddress1 = address1;
    this.clientAddress2 = address2;
    this.clientCity = city;
    this.clientState = state;
    this.clientZip = zip;
  },
};
</script>
<style scoped>
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 1;
  color: #1182d5;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  background-color: white;
}
.v-sheet.v-list:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%),
    0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
}
</style>
